import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material/";
import { FilterList } from "@mui/icons-material";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { Lookup } from "../../components/Lookup";
import ErrorBox from "../../components/WarnningBox/ErrorBox";
import { Delete } from "../../icons";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import AddEditSubscription from "./AddEditSubscription";
import useStyles from "./Subscriptions.style";

const Subscriptions = (props: any) => {
  const styles = useStyles();
  const { customerId } = props.match.params;
  const [openAddEdit, setOpenAddEdit] = useState<any>(null);
  const [subscriptions, setSubscriptions] = useState<any>({});
  const [deleteId, setDeleteId] = useState<any>(null);
  const [customerSites, setCustomerSites] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any>([]);
  const [lookupAnchor, setAnchor] = useState(null);
  const [clickedColumnName, setClickedColumnName] = useState<any>("");
  const [filters, setFilters] = useState<any>({
    status: [],
    site: []
  });
  const [appliedFilters, setAppliedFilters] = useState<any>({
    status: [],
    site: []
  });

  const selections = useStoreState((state) => state.selections.selections);
  const history = useHistory();
  const getSubscriptions = useStoreActions((actions) => actions.subscriptions.getSubscriptions);
  const deleteSubscription = useStoreActions((actions) => actions.subscriptions.deleteSubscription);
  const getSites = useStoreActions((actions) => actions.customers.getCustomerSites);
  const { dateFormat } = useStoreState((state) => state.users);
  const defaultDateFormat = dateFormat || "DD/MM/YYYY";
  const { siteSubscriptionStatusEnums } = useStoreState((s) => s.types);
  const siteSubscriptionStatusMirror = useStoreState((s) => s.siteSubscriptionStatusMirror);
  const sitePackageMirror = useStoreState((s) => s.sitePackageMirror);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  useEffect(() => {
    if (!customerId && !selections.customerId) {
      return;
    }

    if (!customerId && !!selections.customerId) {
      history.push(`/customers/${selections.customerId}/subscriptions`);
      return;
    }
    getSites(customerId)
      .then((res: any) => {
        setCustomerSites(res);
      });
    getSubscriptions(customerId)
      .then((data: any) => {
        setSubscriptions(data);
      });

  }, [props.match.params]);

  useEffect(() => {
    const filteredSubscriptions = _(subscriptions)
      .filter((subscription) => {
        const status = siteSubscriptionStatusEnums[subscription.status];
        return appliedFilters.status.length
          ? appliedFilters.status.includes(status)
          : true;
      })
      .filter((subscription) => {
        const site = subscription?.site ? customerSites[subscription.site]?.name : "-";
        return appliedFilters.site.length
          ? appliedFilters.site.includes(site)
          : true;
      })
      .value();
    setFilteredData(filteredSubscriptions);

  }, [appliedFilters, subscriptions]);

  useEffect(() => {
    const statusList: any[] = [];
    const siteList: any = [];

    Object.values(subscriptions).map((subscription: any) => {
      const status = subscription.status ? siteSubscriptionStatusEnums[subscription.status] : "";
      const site = subscription.site ? customerSites[subscription.site]?.name : "-";

      if (statusList.indexOf(status) === -1) {
        statusList.push(status);
      }
      if (siteList.indexOf(site) === -1) {
        siteList.push(site);
      }

      return true;

    });
    setFilters({ status: statusList, site: siteList });
  }, [subscriptions, customerSites]);

  const onApply = (selectedFilters: any) => {
    setAppliedFilters({
      ...appliedFilters,
      [clickedColumnName]: selectedFilters
    });
    setClickedColumnName("");
  };
  const handleFilterPopup = (event: any, clickedColumn: string) => {
    setClickedColumnName(clickedColumn);
    setAnchor(event.currentTarget);
  };
  const hasFilters = !!Object.values(appliedFilters).flat().length;

  const changeCustomerSelection = (id: any) => {
    id ? history.push(`/customers/${id}/subscriptions`) : history.push(`/subscriptions`);
    setAppliedFilters({
      status: [],
      site: []
    });
  };

  const getPackagesNames = (packages: any) => {
    return packages.map((packageName: any) => <Typography key={packageName}> {sitePackageMirror[packageName]} </Typography>);
  };

  const handleDelete = () => {
    deleteSubscription(deleteId)
      .then(() => {
        const { [deleteId]: toDelete, ...restOfAdminUsers } = subscriptions;
        setSubscriptions(restOfAdminUsers);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
    setDeleteId(null);
  };

  return (
    <ServiceNavigationBar
      title={t`Subscriptions`}
      filters={<SelectionsMenu hideSiteSelection hideUnitSelection hideSystemSelection onCustomerSelect={changeCustomerSelection} />}
      {...props}
    >
      <div className={styles.addButtonContainer}>
        {customerId && <Button
          className={styles.buttonStyle}
          onClick={() => setOpenAddEdit("new")}
        >{t`Add new site subscription`}</Button>}
      </div>
      {
        !customerId ?
          (<Grid container direction={"column"} className={styles.noContentContainer}>
            <div className={styles.grayOval}>
              <ArrowIcon className={styles.arrowIcon} />
            </div>
            <Typography>
              {t`Please select a customer using the above filters.`}
            </Typography>
          </Grid>) :
          <>
            <Paper elevation={0} className={styles.paperPadding}>
              <TableContainer>
                <Table stickyHeader className="" aria-label="customized table">
                  <TableHead className={styles.tableHead}>
                    <TableRow>
                      <TableCell className={styles.headCells}
                        onClick={(e: any) => handleFilterPopup(e, "status")}
                      >
                        <div className={styles.headContainer}>
                          {t`Status`}
                          <FilterList
                            className={clsx(styles.filterStyle, {
                              [styles.blueFilter]: appliedFilters.status.length
                            })}
                          /></div>
                      </TableCell>
                      <TableCell className={styles.headCells}
                        onClick={(e: any) => handleFilterPopup(e, "site")}
                      >
                        <div className={styles.headContainer}>
                          {t`Site`}
                          <FilterList
                            className={clsx(styles.filterStyle, {
                              [styles.blueFilter]: appliedFilters.site.length
                            })}
                          /></div>
                      </TableCell>
                      <TableCell className={styles.headCells}>{t`Period in month`}</TableCell>
                      <TableCell className={styles.headCells}>{t`Start date`}</TableCell>
                      <TableCell className={styles.headCells}>{t`End date`}</TableCell>
                      <TableCell className={styles.headCells}>{t`# Of units`}</TableCell>
                      <TableCell className={styles.headCells}>{t`Packages`}</TableCell>
                      <TableCell align="center" className={styles.headCells} >{t`Delete`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((subscription: any) => {
                      const { id, status, site, durationInMonth, startTimestamp, endTimestamp, numberOfUnits, packages } = subscription;
                      const isOverdue = status === siteSubscriptionStatusMirror.overdue;
                      const isFrozen = status === siteSubscriptionStatusMirror.frozen;
                      return (
                        <TableRow className={clsx({ [styles.redRow]: isOverdue, [styles.orangeRow]: isFrozen })} hover tabIndex={-1} key={id} onDoubleClick={() => setOpenAddEdit(id)}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {siteSubscriptionStatusEnums[status]}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {customerSites[site]?.name || "-"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={styles.rowCell}
                          >
                            {durationInMonth}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {startTimestamp ? moment(startTimestamp).format(defaultDateFormat) : "-"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {endTimestamp ? moment(endTimestamp).format(defaultDateFormat) : "-"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={styles.rowCell}
                          >
                            {numberOfUnits}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {getPackagesNames(packages)}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={styles.rowCell}
                          >
                            <IconButton
                              onClick={() => setDeleteId(id)}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
      }
      {deleteId && <ErrorBox title={t`Delete Site Subscription`} error={t`Are you sure you want to delete this site subscription ?`} onAccept={() => handleDelete()} onClose={() => setDeleteId(null)} />}
      {openAddEdit &&
        <AddEditSubscription
          isEdit={openAddEdit !== "new"}
          subscription={subscriptions[openAddEdit]}
          setOpenAddEdit={setOpenAddEdit}
          setSubscriptions={setSubscriptions}
          subscriptions={subscriptions}
          customerSites={customerSites}
          customer={customerId}
        />}
      {clickedColumnName && (
        <Lookup
          filtersList={filters[clickedColumnName]}
          appliedFilters={appliedFilters[clickedColumnName]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setClickedColumnName("")}
          clearAllFilters={() => setAppliedFilters({
            status: [],
            site: []
          })}
          hasFilters={hasFilters}
        />
      )}
    </ServiceNavigationBar >
  );
};

export default Subscriptions;
