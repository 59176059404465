import { makeStyles } from "@mui/styles";

const styles: any = makeStyles((theme: any) =>
({
  root: {
    height: "100%"
  },
  nav: {
    color: theme.menu.nav.color,
    backgroundColor: theme.menu.nav.backgroundColor,
    boxSizing: "border-box",
    width: "250px",
    minHeight: "100vh",
    maxHeight: "100vh",
    overflowY: "auto",
    display: "flex",
    flexFlow: "column nowrap"
  },
  listITem: {
    opacity: 0.7,
    display: "inline-flex",
    justifyItems: "center",
    "&:hover": {
      backgroundColor: theme.menu.listItem.backgroundColor,
      opacity: 0.9,
      cursor: "pointer"
    }
  },

  listItemText: {
    paddingLeft: "10px",
    paddingTop: "0px",
    fontSize: "16px",
    cursor: "pointer"
  },
  iconContainer: {
    padding: "0px"
  },
  iconSize: {
    fontSize: "24px",
    marginRight: "9px",
    "& path": { stroke: theme.menu.icon.stroke }
  },
  logoStyle: { width: "180px" },
  logoButton: {
    marginBottom: "20px",
    paddingRight: "52px"
  },
  versionText: {
    alignSelf: "center",
    margin: "auto 0 1rem !important",
    opacity: "0.5"
  }
}));
export default styles;
