import { Admin, Site } from "coolremote-sdk";
import { GlobalAdmin } from "coolremote-sdk";

import {
  Action,
  action,
  ActionOn,
  actionOn,
  Computed,
  computed,
  thunk,
  Thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface ISite {
  id: string;
  name: string;
  description?: string;
  country?: string;
  city?: string;
  state?: string;
  address?: string;
  postalCode?: string;
  timezone?: string;
  devices: string[];
  zones: string[];
  triggers: string[];
  customer: string;
  unitSupportedOperationModes: number[];
  unitSupportedFanModes: number[];
  unitSupportedSwingModes: number[];
  unitTemperatureLimits?: any;
  shouldAutoLimit?: boolean;
  role: any;
  ignoreStatus?: any;
  managementStatus?: number;
  serviceStatus?: number;
}

export interface ISitesModel {
  allSites: ISiteMap;
  setAllSites: Action<ISitesModel, any>;
  deleteSite: Thunk<ISitesModel, string>;
  createSite: Thunk<ISitesModel, any>;
  getSite: Thunk<ISitesModel, string>;
  getAllSites: Thunk<ISitesModel, string | undefined>;
  updateSite: Thunk<ISitesModel, { id: string; data: any }>;
  updateSitePackages: Thunk<ISitesModel, { id: string; data: any }>;
  getSiteName: Computed<ISitesModel, (id?: string) => string>;
  getSiteFilteredUnits: Computed<ISitesModel, (site?: any, allUnits?: any, type?: any) => any[]>;
  getSiteCustomer: Computed<ISitesModel, (id?: string) => string>;
  initialize: Action<ISitesModel, any>;
  onInitialized: ActionOn<ISitesModel, IRootStoreModel>;
  getSiteUnits: Thunk<ISitesModel, (id?: any) => any[]>;
  getSiteDevices: Thunk<ISitesModel, string>;
  getSiteAccessibleUsers: Thunk<ISitesModel, string>;
  getSiteAudits: Thunk<ISitesModel, any>;
  getSiteSystems: Thunk<ISitesModel, any>;
}
export interface ISiteMap {
  [key: string]: ISite;
}

export const SitesModel: ISitesModel = {
  allSites: {},
  setAllSites: action((state, payload) => {
    state.allSites = payload;
  }),
  initialize: action((state, payload) => {
    const newSites: ISiteMap = _(Object.values(payload))
      .map((site: any) => {
        const devices = site.devices ? Object.keys(site.devices) : [];
        const zones = site.zones ? Object.keys(site.zones) : [];
        const triggers = site.triggers ? Object.keys(site.triggers) : [];

        const newSite: ISite = { ...site, devices, zones, triggers };

        return newSite;
      })
      .keyBy("id")
      .value();
    state.allSites = newSites;
  }),

  onInitialized: actionOn((actions) => [actions.initialize], () => { }),

  deleteSite: thunk((actions, payload) => {
    return GlobalAdmin.deleteSite(payload);
  }),
  createSite: thunk(async (actions, payload) => {
    return GlobalAdmin.createSite(payload);
  }),
  getSite: thunk((actions, payload) => {
    return GlobalAdmin.getSiteById(payload);
  }),
  getAllSites: thunk((actions, payload) => {
    return GlobalAdmin.getAllSites(payload);
  }),
  updateSite: thunk((actions, payload) => {
    return GlobalAdmin.updateSite(payload.id, payload.data);
  }),
  updateSitePackages: thunk((actions, payload) => {
    return Site.updateSitePackages(payload.id, payload.data);
  }),
  getSiteName: computed([(state) => state.allSites], (allSites) => (id) => {
    if (id && allSites[id]) {
      return allSites[id].name;
    } else {
      return "-";
    }
  }),
  getSiteUnits: thunk(async (actions, payload) => {

    return Site.getUnits(payload);
  }),
  getSiteFilteredUnits: computed([], () => (site, allUnits, type) => {
    let units: any = [];
    if (site) {
      for (let device of site.devices) {
        const filteredUnits = _.filter(Object.values(allUnits), (unit: any) => unit.type === type && unit.device == device);
        units = units.concat(filteredUnits);
      }
    }
    return units;
  }),
  // getSites: thunk(async (actions, payload) => {
  //   return GlobalAdmin.getSites();

  // }),
  getSiteCustomer: computed([(state) => state.allSites], (allSites) => (id) => {
    if (id && allSites[id]) {
      return allSites[id].customer;
    } else {
      return "-";
    }
  }),
  getSiteAccessibleUsers: thunk(async (actions, payload) => {
    const data = await Admin.getSiteUsers(payload);
    return data;
  }),
  getSiteAudits: thunk(async (actions, payload) => {
    return GlobalAdmin.getSiteAudits(payload);
  }),
  getSiteSystems: thunk(async (actions, payload) => {
    return GlobalAdmin.getSiteSystems(payload);
  }),

  getSiteDevices: thunk(async (actions, payload) => {
    return GlobalAdmin.getSiteDevices(payload);
  }),
};
