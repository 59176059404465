import { saveAs } from "file-saver";
const ExcelJS = require('exceljs');

interface IProps {
    columns: { header: string, key: string, width?: number, wrapText?: boolean }[];
    data: any[];
    fileName: string;
    defaultWidth?: number;
}

const exportDataToExcel = ({ columns, data = [], fileName = "data", defaultWidth = 27 }: IProps) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet');

    const headerRow = worksheet.getRow(1);
    columns.forEach((column, columnIndex) => {
        headerRow.getCell(columnIndex + 1).value = column.header;
        headerRow.getCell(columnIndex + 1).font = { bold: true };
    });

    data.forEach((row: any) => {
        const rowData = columns.map((column: { key: string }) => row[column.key]);
        worksheet.addRow(rowData);
    });
    worksheet.columns.forEach((column: any, columnIndex: number) => {
        column.width = columns[columnIndex]?.width || defaultWidth
        column.alignment = { wrapText: !!columns[columnIndex]?.wrapText };
    })

    workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
        saveAs(
            new Blob([buffer], { type: "application/xlsx" }),
            `${fileName}.xlsx`
        );
    });
};

export default exportDataToExcel;