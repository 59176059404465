import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    height: "100%",
    // padding: '0 1rem 2rem',
    background: theme.tables.background,
    display: "flex",
    borderRadius: "4px"
  },
  paper: {
    width: "100%",
    // height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem 2rem"
  },
  paginationHeader: {
    overflow: "hidden"
  },
  tableMenu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableHeadCell: {
    borderBottom: "none"
  }
}));

export default useStyles;
