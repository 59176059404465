import { createStyles } from "@mui/styles";

export const messageStyles = (theme: any) =>
  createStyles({
    dialogStyle: {
      padding: "30px 26px 27px 26px",
      minWidth: "400px",
      height: "auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    contentStyle: {
      paddingBottom: "22px",
      color: "#545964",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: "18px",
      fontFamily: "Roboto",
      textAlign: "center",
      maxWidth: "100%",
      wordBreak: "break-word"
    },
    textNotCentered: {
      textAlign: "unset"
    },
    saveButtonStyle: {
      padding: "0 !important",
      margin: "0 !important",
      backgroundColor: "#331e38 !important",
      maxWidth: "180px !important",
      minWidth: "160px !important"
    }
  });
