import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Input
} from "@mui/material/";
import { Close, FilterList, Search } from "@mui/icons-material";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Lookup } from "../../components/Lookup";
import { DeleteIcon } from "../../iconImages";
import { useStoreActions } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./DeviceFamily.style";
import { GlobalAdmin } from "coolremote-sdk";
import ErrorBox from "../../components/WarnningBox/ErrorBox";


const DeviceFamily = (props: any) => {
    const styles: any = useStyles();
    const [families, setFamilies] = useState<any>({});
    const [clickedColumnName, setClickedColumnName] = useState<any>("");
    const [lookupAnchor, setAnchor] = useState(null);
    const [filteredFamilies, setFilteredFamilies] = useState<any>([]);
    const { addMessage } = useStoreActions((action) => action.errorMessage);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [isEdit, setisEdit] = useState<boolean>(false);
    const [editFamily, setEditFamily] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [refresh, setRefresh] = useState<any>(false);
    const [selectedFamilyToDelete, setSelectedFamilyToDelete] = useState<any>({});
    const [searchValue, setSearchValue] = useState<any>("");

    useEffect(() => {
        const filteredData = Object.values(families).filter((family: any) => {
            return (family?.name?.toUpperCase()?.indexOf(searchValue.toUpperCase()) >= 0);
        });
        setFilteredFamilies(filteredData);
    }, [searchValue, families]);

    const [filters, setFilters] = useState<any>({
        names: [],
        startSerials: [],
        endSerials: [],
    });

    const [appliedFilters, setAppliedFilters] = useState<any>({
        names: [],
        startSerials: [],
        endSerials: [],
    });

    const onApply = (selectedFilters: any) => {
        setAppliedFilters({
            ...appliedFilters,
            [clickedColumnName]: selectedFilters
        });
        setClickedColumnName("");
    };

    useEffect(() => {
        GlobalAdmin.getDevicefamilies().then((res: any) => {
            setFamilies(res)
        }).catch((err: any) => addMessage({ message: err.message }))
    }, [refresh]);


    useEffect(() => {
        if (_.isEmpty(appliedFilters)) {
            setFilteredFamilies(families);
            return;
        }
        const filteredfamilies = _(families)
            .filter((family) => {
                return appliedFilters.names.length
                    ? appliedFilters.names.includes(family.name)
                    : true;
            })
            .filter((family) => {
                return appliedFilters.startSerials.length
                    ? appliedFilters.deviceSerialList.includes(family.startSerial)
                    : true;
            })
            .filter((family) => {
                return appliedFilters.endSerials.length
                    ? appliedFilters.siteNames.includes(family.endSerial)
                    : true;
            }).value();

        setFilteredFamilies(filteredfamilies);
    }, [appliedFilters, families]);

    useEffect(() => {
        if (_.isEmpty(families)) {
            setFilters({
                names: [],
                startSerials: [],
                endSerials: [],
            });
            return;
        }
        const names: any[] = [];
        const startSerials: any = [];
        const endSerials: any = [];
        Object.values(families).map((family: any) => {
            const { name, startSerial, endSerial } = family
            if (name && name !== "-") {
                names.push(name);
            }
            if (startSerial && startSerial !== "-") {
                startSerials.push(startSerial);
            }
            if (endSerial && endSerial !== "-") {
                endSerials.push(endSerial);
            }
            return true;
        });
        setFilters({ names, startSerials, endSerials });
    }, [filteredFamilies, appliedFilters]);


    const handleFilterPopup = (event: any, clickedColumn: string) => {
        setClickedColumnName(clickedColumn);
        setAnchor(event.currentTarget);
    };

    const deleteDeviceFamily = () => {
        const { id } = selectedFamilyToDelete;
        GlobalAdmin.deleteDevicefamily(id)
            .then(() => {
                setRefresh(!refresh)
            })
            .catch((err: any) => addMessage({ message: err.message }))
            .finally(handleClose)

    };

    const handleChange = (event: any) => {
        let data: any = {};
        data[event.target.id] = event.target.value;
        setEditFamily({ ...editFamily, ...data })
    }

    const handleClose = () => {
        setOpenDialog(false);
        setisEdit(false)
        setEditFamily({});
        setSelectedFamilyToDelete({})
        setErrors({});
    }


    const HandelSubmit = () => {
        if (!editFamily.name) {
            return setErrors({ name: t`Name is required!` })
        }
        if (!editFamily.startSerial) {
            return setErrors({ startSerial: t`Start serial is required!` })
        }
        if (!editFamily.endSerial) {
            return setErrors({ endSerial: t`End serial is required!` })
        }

        if (isEdit) {
            const { id, name } = editFamily;
            GlobalAdmin.updateDevicefamily(id, { name })
                .then((res: any) => {
                    setRefresh(!refresh)
                })
                .catch((err: any) => addMessage({ message: err.message }))
                .finally(handleClose)
        }
        else {
            GlobalAdmin.createDevicefamily(editFamily)
                .then((res: any) => {
                    setRefresh(!refresh)
                })
                .catch((err: any) => addMessage({ message: err.message }))
                .finally(handleClose)
        }
    }


    return (
        <div className={styles.view}>
            <ServiceNavigationBar
                title={t`Device Family`}
                searchComponent={
                    <Input
                        placeholder={t`Search`}
                        value={searchValue}
                        onChange={(event: any) => setSearchValue(event.target.value)}
                        disableUnderline={true}
                        classes={{ root: styles.inputRoot }}
                        startAdornment={
                            <InputAdornment position="start" className={styles.searchAdorment}>
                                <Search className={styles.searchIcon} />
                            </InputAdornment>
                        }
                        endAdornment={
                            searchValue && (
                                <IconButton
                                    onClick={() => setSearchValue("")}
                                    className={styles.closeIconStyle}
                                >
                                    <Close />
                                </IconButton>
                            )
                        }
                    />
                }
                {...props}
            >
                <div className={styles.addButtonContainer}>
                    <Button
                        className={styles.buttonStyle}
                        onClick={() => setOpenDialog(true)}
                    >{t`ADD NEW FAMILY`}</Button>
                </div>
                <div className={styles.contentArea}>
                    <Grid container className={styles.content}>
                        <Grid item xs={12}>
                            <TableContainer >
                                <Table stickyHeader className="" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className={styles.headCells}
                                                onClick={(e: any) => handleFilterPopup(e, "names")}
                                            >
                                                <div className={styles.headContainer}>
                                                    {t`NAME`}
                                                    <FilterList
                                                        className={clsx(styles.filterStyle, {
                                                            [styles.blueFilter]: appliedFilters.names.length
                                                        })}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className={styles.headCells}
                                                onClick={(e: any) => handleFilterPopup(e, "startSerials")}
                                            >
                                                <div className={styles.headContainer}>
                                                    {t`Start Serial`}
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className={styles.headCells}
                                                onClick={(e: any) => handleFilterPopup(e, "endSerials")}
                                            >
                                                <div className={styles.headContainer}>
                                                    {t`End Serial`}
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className={styles.headCells}
                                            >
                                                {t`DELETE`}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(filteredFamilies).map((family: any) => {
                                            const { id, name, startSerial, endSerial } = family
                                            return (
                                                <TableRow hover tabIndex={-1} key={id} onDoubleClick={() => { setEditFamily(family); setisEdit(true); setOpenDialog(true) }} >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        className={styles.rowCell}
                                                    >
                                                        {name}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        className={styles.rowCell}
                                                    >
                                                        {startSerial}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        className={styles.rowCell}
                                                    >
                                                        {endSerial}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                        className={styles.rowCell}
                                                    >
                                                        <IconButton
                                                            onClick={() => {
                                                                setSelectedFamilyToDelete(family);
                                                            }}
                                                        >
                                                            <img src={DeleteIcon} alt={t`Delete`} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </div>
                {clickedColumnName && (
                    <Lookup
                        filtersList={filters[clickedColumnName]}
                        appliedFilters={appliedFilters[clickedColumnName]}
                        onApply={onApply}
                        lookupAnchor={lookupAnchor}
                        onClose={() => setClickedColumnName("")}
                        clearAllFilters={() => setAppliedFilters({
                            names: [],
                            startSerials: [],
                            endSerials: [],
                        })}
                        hasFilters={!!Object.values(appliedFilters).flat().length}
                    />
                )}

                <Dialog
                    onClose={() => handleClose()}
                    open={openDialog}
                    classes={{ paper: styles.dialogPaper }}
                >
                    <DialogTitle
                        id="simple-dialog-title">
                        {t`New Device Family`}
                    </DialogTitle>
                    <DialogContent className={styles.content}>
                        <TextField
                            label={t`Name`}
                            id={"name"}
                            helperText={errors.name}
                            className={styles.inputField}
                            variant={'standard'}
                            onChange={handleChange}
                            value={editFamily.name || ""}
                            error={errors.name}
                        />

                        <TextField
                            disabled={isEdit}
                            label={t`Start serial`}
                            id={"startSerial"}
                            helperText={errors.startSerial}
                            className={styles.inputField}
                            variant={'standard'}
                            onChange={handleChange}
                            value={editFamily.startSerial || ""}
                            error={errors.startSerial}
                        />

                        <TextField
                            disabled={isEdit}
                            label={t`End serial`}
                            id={"endSerial"}
                            helperText={errors.endSerial}
                            className={styles.inputField}
                            variant={'standard'}
                            onChange={handleChange}
                            value={editFamily.endSerial || ""}
                            error={errors.endSerial}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            className={styles.sharedButtonStyle}
                            onClick={() => handleClose()} >
                            {t`Cancel`}
                        </Button>
                        <Button
                            onClick={() => HandelSubmit()}
                            className={clsx(
                                styles.sharedButtonStyle,
                                styles.submitButton
                            )}
                        >
                            {isEdit ? t`Update` : t`Add`}
                        </Button>
                    </DialogActions>
                </Dialog>


                {!_.isEmpty(selectedFamilyToDelete) && <ErrorBox
                    title={t`Delete device family`}
                    error={t`Are you sure you want to delete device family ${selectedFamilyToDelete.name}?`}
                    onAccept={() => deleteDeviceFamily()}
                    onClose={() => setSelectedFamilyToDelete({})}
                />}

            </ServiceNavigationBar>
        </div>
    );
};

export default DeviceFamily;
