import { SiteSubscriptions, GlobalAdmin } from "coolremote-sdk";
import { Thunk, thunk, } from "easy-peasy";
import _ from "lodash";

export interface ISubscription {
  id: string;
  packages: any;
  status: number;
  site: string;
  durationInMonth: number;
  numberOfUnits: number;
  endTimestamp?: number;
  startTimestamp?: number;
  customer: string;
}

export interface ISubscriptionMap {
  [key: string]: ISubscription;
}

export interface ISubscriptionsModel {
  getSubscriptions: Thunk<ISubscriptionsModel, string>;
  getSubscriptionsBySite: Thunk<ISubscriptionsModel, string>;
  getSubscriptionById: Thunk<ISubscriptionsModel, string>;
  activateSiteSubscription: Thunk<ISubscriptionsModel, { id: string, data: any }>;

  getSubscriptionsCustomersStatus: Thunk<ISubscriptionsModel>;
  getSubscriptionsCustomerSitesStatus: Thunk<ISubscriptionsModel, string>;
  getSubscriptionsSiteStatus: Thunk<ISubscriptionsModel, string>;

  deleteSubscription: Thunk<ISubscriptionsModel, string>;
  createSubscription: Thunk<ISubscriptionsModel, any>;
  updateSubscription: Thunk<ISubscriptionsModel, { id: string, data: any }>;
  assignSiteToSubscription: Thunk<ISubscriptionsModel, { id: string, data: any }>;
}

export const subscriptionsModel: ISubscriptionsModel = {
  getSubscriptions: thunk((actions, customerId) => {
    return GlobalAdmin.getCustomerSitesSubscriptions(customerId);
  }),
  getSubscriptionsBySite: thunk((actions, siteId) => {
    return GlobalAdmin.getSiteSubscriptions(siteId);
  }),
  getSubscriptionById: thunk((actions, siteId) => {
    return GlobalAdmin.getSubscriptionById(siteId);
  }),
  activateSiteSubscription: thunk(async (actions, payload) => {
    const data = await GlobalAdmin.activateSiteSubscription(payload.id, payload.data);
    return data;
  }),

  getSubscriptionsCustomersStatus: thunk(() => {
    return SiteSubscriptions.getSubscriptionsCustomersStatus();
  }),
  getSubscriptionsCustomerSitesStatus: thunk((_, customerId) => {
    return SiteSubscriptions.getSubscriptionsCustomerSitesStatus(customerId);
  }),
  getSubscriptionsSiteStatus: thunk((_, siteId) => {
    return SiteSubscriptions.getSubscriptionsSiteStatus(siteId);
  }),

  deleteSubscription: thunk(async (actions, payload) => {
    return GlobalAdmin.deleteSubscription(payload);
  }),
  createSubscription: thunk(async (actions, payload) => {
    return GlobalAdmin.createSiteSubscription(payload);
  }),
  updateSubscription: thunk(async (actions, payload) => {
    const data = await GlobalAdmin.updateSiteSubscription(payload.id, payload.data);
    return data;
  }),
  assignSiteToSubscription: thunk(async (actions, payload) => {
    return GlobalAdmin.assignSiteToSubscription(payload.id, payload.data);
  })
};
