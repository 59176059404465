import React, { useEffect, useState } from 'react';
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import {
  Paper,
  Stepper,
  StepLabel,
  Step,
} from "@mui/material";
import { t } from "ttag";
import useStyles from "./DeviceMigration.style";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import _ from "lodash";
import CustomerDetection from './CustomerDetection';
import UserDataExport from './UserDataExport';
import SiteDetection from './SiteDetection';
import UserDetection from './UserDetection';
import DataIndicator from './DataIndicator';
import UserDataImport from './UserDataImport';

const DeviceMigration = (props: any) => {
  const styles: any = useStyles();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [sites, setSites] = useState<any>([]);
  const [customerSites, setCustomerSites] = useState<any>([]);
  const getCustomerSites = useStoreActions((actions) => actions.customers.getCustomerSites);
  const convertUnitNames = useStoreActions((actions) => actions.devices.convertUnitNames);
  const convertZonesToGroups = useStoreActions((actions) => actions.devices.convertZonesToGroups);
  const customersBasicList = useStoreState((state) => state.customers.customersBasicList);
  const allUsers = useStoreState((state) => state.users.otherUsers);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const steps = [
    selectedCustomer && customersBasicList[selectedCustomer] ? `Customer ${customersBasicList[selectedCustomer]?.name}` : 'Select/Create Customer',
    selectedSite && customerSites[selectedSite] ? `Site ${customerSites[selectedSite]?.name}` : 'Select/Create Site',
    selectedUser && allUsers[selectedUser] ? `User ${allUsers[selectedUser]?.username}` : 'Create User',
    'Copy Unit Names',
    'Convert Zones To Groups'
  ];

  const goNext = () => {
    setActiveStep(activeStep + 1)
  }

  const goBack = () => {
    if (activeStep === 1) {
      //from site to customer
      setSelectedCustomer("")
    }
    if (activeStep === 2) {
      //from user to site
      setSelectedSite("")
    }
    setActiveStep(activeStep - 1)

  }

  const fromCustomerToSite = (customerId: any) => {
    setSelectedCustomer(customerId)
    setActiveStep(activeStep + 1)
  }

  const fromSiteToUser = (siteId: any) => {
    setSelectedSite(siteId)
    setActiveStep(activeStep + 1)
  }

  const fromUserToUnitNames = (userId?: any) => {
    if (userId) {
      setSelectedUser(userId)
    }
    setActiveStep(activeStep + 1)
  }

  useEffect(() => {
    if (!selectedCustomer) {
      return;
    }

    getCustomerSites(selectedCustomer).then((data: any) => {
      const menuOptions: any[] = [];
      setCustomerSites(data);
      _.map(data, (customer) => {
        menuOptions.push({ value: customer.id, label: customer.name });
      });

      const options = _.sortBy(menuOptions, [(option) => option.label?.toUpperCase()], ["asc"]);
      setSites(options)
    });

  }, [selectedCustomer]);

  const handleLastStep = () => {
    setActiveStep(0)
    addMessage({ message: "Done Successfully" });

  }
  function _renderStepContent(step: any) {
    switch (step) {
      case 0:
        return <CustomerDetection nextStep={fromCustomerToSite} />;
      case 1:
        return <SiteDetection sites={sites} nextStep={fromSiteToUser} goBack={goBack} customerId={selectedCustomer} />;
      case 2:
        return <UserDetection nextStep={fromUserToUnitNames} customers={customersBasicList} customerId={selectedCustomer} goBack={goBack} />;
      case 3:
        return <DataIndicator handleAPI={convertUnitNames} activeStep={activeStep} title={steps[3]} nextStep={goNext} goBack={goBack} siteId={selectedSite} siteName={customerSites[selectedSite]?.name || ""} />;
      case 4:
        return <DataIndicator handleLastStep={handleLastStep} handleAPI={convertZonesToGroups} activeStep={activeStep} title={steps[4]} nextStep={goNext} goBack={goBack} siteId={selectedSite} siteName={customerSites[selectedSite]?.name || ""} />;
      default:
        return <CustomerDetection nextStep={fromCustomerToSite} />;
    }
  }
  return (

    <ServiceNavigationBar title={t`Admin Users Management`} {...props} >
      <Paper elevation={0} className={styles.paperPadding}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={styles.content}>
          {_renderStepContent(activeStep)}
        </div>
      </Paper>

      <Paper elevation={1} className={styles.paperPadding}>
        <div className={styles.content}>
          <UserDataExport />
        </div>
      </Paper>

      <Paper elevation={2} className={styles.paperPadding}>
        <div className={styles.content}>
          <UserDataImport />
        </div>
      </Paper>

    </ServiceNavigationBar >

  );
};

export default DeviceMigration;