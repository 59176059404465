import { Button, Popover, TextField, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IDateRange } from "../../models/Selections";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import { Arrow as SvgArrow, Calendar } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import UnitsMenu from "../Menu/UnitsMenu";
import MenuDropDown from "../MenuDropDown/MenuDropDown";
import useStyles from "./SelectionsMenu.style";
import moment from "moment-timezone";
import CoolDateRangePicker from "../CoolDateRangePicker/CoolDateRangePicker";

export interface ISelectionsMenuProps {
  onCustomerSelect?: (id: any) => void;
  onSiteSelect?: (id: string) => void;
  onSystemSelect?: (id: string) => void;
  onUnitSelect?: (id: string) => void;
  hideCustomerSelection?: boolean;
  hideSiteSelection?: boolean;
  hideSystemSelection?: boolean;
  hideUnitSelection?: boolean;
  onDateSelect?: (clearFilter: boolean) => void;
  showDateRangePicker?: boolean;
  showOneDatePicker?: boolean;
  disableOneDatePicker?: boolean;
  minDate?: any;
  noRangeLimit?: boolean;
  skipGetSites?: boolean;
}

const SelectionsMenu = ({
  onCustomerSelect,
  onSiteSelect,
  onSystemSelect,
  onUnitSelect,
  onDateSelect,
  hideCustomerSelection = false,
  hideSiteSelection = false,
  hideSystemSelection = false,
  hideUnitSelection = false,
  showDateRangePicker = false,
  showOneDatePicker = false,
  minDate = new Date("1900-01-01"),
  noRangeLimit = false,
  skipGetSites = false
}: ISelectionsMenuProps) => {
  const classes: any = useStyles();
  const selections = useStoreState((s) => s.selections.selections);
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const customers = useStoreState((s) => s.selections.getCustomersBySelection);
  const customersBasicList = useStoreState((s) => s.customers.customersBasicList);
  const sortedCustomers = _.orderBy(customers, [(customer: any) => customer?.name?.toLowerCase()], ["asc"]);
  const [customerSites, setCustomerSites] = React.useState<any>([])
  const [siteSystems, setSiteSystems] = React.useState<any>([])
  const [systemUnitsObj, setSystemUnitsObj] = React.useState<any>({})
  const [anchorEl, setAnchorEl] = React.useState({
    unitsMenu: null,
    dateRangePicker: null
  });
  const unitsMenuOpen = Boolean(anchorEl.unitsMenu);
  const dateRangePickerOpen = Boolean(anchorEl.dateRangePicker);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getCustomerSites = useStoreActions((actions) => actions.customers.getCustomerSites);
  const getSiteSystems = useStoreActions((actions) => actions.sites.getSiteSystems);
  const getSystemUnits = useStoreActions((actions) => actions.systems.getSystemUnits);

  const [customerInputValue, setCustomerInputValue] = useState('');


  const setUnit = (id: string) => {

    setSelections({ customerId: selections.customerId, siteId: selections.siteId, systemId: selections.systemId, unitId: id });
    onUnitSelect && onUnitSelect(id);
    handleClose();
  };

  // Selections state management
  const setCustomer = (id: string | null) => {
    setSelections({
      customerId: id,
      siteId: null,
      systemId: null,
      unitId: null
    });

    onCustomerSelect && onCustomerSelect(id);
  };

  const setSite = (id: string) => {
    if (!id) {
      setSelections({ customerId: selections.customerId, siteId: null, systemId: null, unitId: null });
      onSiteSelect && onSiteSelect(id);
      return;
    }

    setSelections({
      customerId: selections.customerId,
      siteId: id,
      systemId: null,
      unitId: null
    });
    onSiteSelect && onSiteSelect(id);
  };

  const setSystem = (id: string) => {
    if (!id) {
      setSelections({
        customerId: selections.customerId,
        siteId: selections.siteId, systemId: null, unitId: null
      });
      onSiteSelect && onSiteSelect(id);
      return;
    }


    setSelections({ customerId: selections.customerId, siteId: selections.siteId, systemId: id, unitId: null });
    onSystemSelect && onSystemSelect(id);
  };


  useEffect(() => {
    if (skipGetSites)
      return;

    if (!selections.customerId) {
      setCustomerSites([])
      return
    }

    getCustomerSites(selections.customerId)
      .then((res: any) => {
        setCustomerSites([{ id: "", name: "All Sites" }, ...Object.values(res)])
      })
      .catch((err: any) => addMessage({ message: err.message }));

  }, [selections.customerId])

  useEffect(() => {
    if (!selections.siteId) {
      setSiteSystems([])
      return
    }
    getSiteSystems(selections.siteId)
      .then((res: any) => {
        setSiteSystems([{ id: "", name: "All Systems" }, ...Object.values(res)])
      })
      .catch((err: any) => addMessage({ message: err.message }));

  }, [selections.siteId])

  useEffect(() => {
    if (!selections.systemId) {
      setSystemUnitsObj({})
      return
    }
    getSystemUnits(selections.systemId)
      .then((res: any) => {
        setSystemUnitsObj(res)
      })
      .catch((err: any) => addMessage({ message: err.message }));

  }, [selections.systemId])
  // Builds options for selection dropdown
  const getOptions = useCallback(
    (itemType: "customer" | "site" | "system", items: any[]) => {
      if (itemType === "customer" && items.length === 1) {
        return [
          {
            name: `${items[0].name}`,
            value: items[0].id,
            key: items[0].id,
            type: items[0].type
          }
        ];
      } else {
        const options = items.map((item: any) => ({
          name: `${item.name}`,
          value: item.id,
          key: item.id,
          type: item.type
        }));
        return [
          {
            name: options.length ? `All ${itemType}s` : `No ${itemType}s`,
            value: "",
            key: itemType,
            type: 0
          },
          ...options
        ];
      }
    },
    []
  );

  // Click handler for dropdown button which assigns correct anchor and opens Popover
  type IAnchor = "unitsMenu" | "dateRangePicker";
  const handleClick = useCallback(
    (anchorName: IAnchor) => (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl({ ...anchorEl, [anchorName]: event.currentTarget });
    },
    [anchorEl]
  );
  const handleClose = () => {
    setAnchorEl({ unitsMenu: null, dateRangePicker: null });
  };

  const customerObj = customersBasicList[selections.customerId || ""];
  const isNoCustomer = _.isEmpty(customersBasicList);
  const selectedCustomer: any = hideCustomerSelection || selections.customerId === null ? null : { name: customerObj?.name || (isNoCustomer ? `No customers` : `All customers`), value: selections.customerId };

  const handleNewDateRange = (range: IDateRange | null) => {
    // If null - user chose Cancel, so we don't change current selection
    if (range) {
      setSelections({ dateRange: range });
    }
    setAnchorEl({ unitsMenu: null, dateRangePicker: null });
  };

  const handleDateChange = (date: any | null) => {
    onDateSelect && onDateSelect(false);
    if (date) {
      const now = new Date();
      const newDate = new Date(date?.getTime());
      const startDate = new Date(newDate.setHours(0, 0, 0));
      let endDate = new Date(newDate.setHours(23, 59, 59));

      if (endDate > now) {
        endDate = now;
      }
      const range = { startDate, endDate };
      setSelections({ dateRange: range });
    }
    setAnchorEl({ unitsMenu: null, dateRangePicker: null });
  };
  const printDateRange = (range: IDateRange | null) => {
    if (_.isNil(range)) {
      return "no selection";
    }
    return `${moment(range.startDate).format("ll")} - ${moment(range.endDate).format("ll")}`;
  };

  return (
    <div className={classes.selectionsContainer}>
      {!hideCustomerSelection && (
        <Autocomplete
          isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
          options={getOptions("customer", sortedCustomers)}
          getOptionLabel={(option) => `${option.name}-${option.value}`}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          inputValue={customerInputValue}
          value={selectedCustomer}
          onInputChange={(event: any, value: string, reason: string) => {
            if (reason === "clear") {
              setCustomer(null);
              setCustomerInputValue("");
              return;
            }
            const valueName = value.split('-')[0];
            setCustomerInputValue(valueName);
          }}
          onChange={(event: any, newValue: any) => {
            const value = newValue ? newValue?.value : null;
            setCustomer(value);
          }}
          renderInput={(params) => <TextField {...params} placeholder={t`Search...`} InputProps={{ classes: { root: classes.inputRoot }, ...params.InputProps }} />}
          classes={{
            clearIndicator: classes.searchIcon,
            popupIndicator: classes.searchIcon,
            popper: classes.autoCompletePoper,
            paper: classes.autoCompletePaper,
            listbox: classes.autoCompleteItem,
            noOptions: classes.autoCompleteItem,
            groupLabel: classes.autoCompleteGroup,
            groupUl: classes.autoCompleteGroupUl,
            endAdornment: classes.endAdornment

          }}
        />
      )}
      {!hideSiteSelection && (
        <MenuDropDown
          onChange={setSite}
          value={selections.siteId || ""}
          options={customerSites}
          placeholder={"No Sites"}
          enableSearch
          disabled={!selections.customerId}
        />
      )}

      {!hideSystemSelection && (
        <MenuDropDown
          onChange={setSystem}
          value={selections.systemId || ""}
          options={siteSystems}
          placeholder={"No Systems"}
          disabled={!selections.siteId}
        />
      )}
      {!hideUnitSelection && (
        <React.Fragment>
          <Button
            className={classes.selectUnitButton}
            size="large"
            onClick={handleClick("unitsMenu")}
            endIcon={<ArrowDropDownIcon />}
            disabled={!selections.systemId}
            classes={{
              disabled: classes.disabled
            }}
          >
            <div className={classes.unitName}>{!selections.unitId ? "Units" : systemUnitsObj[selections.unitId]?.name}</div>
            <SvgArrow className={classes.selectUnitButton__icon} />
          </Button>
          <Popover
            open={unitsMenuOpen}
            anchorEl={anchorEl.unitsMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <UnitsMenu setUnit={setUnit} systemUnits={systemUnitsObj} />
          </Popover>
        </React.Fragment>
      )}
      {showOneDatePicker && (
        <React.Fragment>
          <DatePicker
            value={selections.dateRange?.endDate}
            onChange={handleDateChange}
            maxDate={new Date()}
            minDate={minDate}
            renderInput={(params) => {
              if (params?.inputProps?.value) {
                params.inputProps.value = moment(params.inputProps.value).format("ll");
              }
              return <TextField {...params} disabled variant="standard" className={classes.oneDatePicker} />
            }}
          />
        </React.Fragment>
      )}
      {showDateRangePicker && (
        <React.Fragment>
          <Button
            disableRipple
            className={classes.selectDateRangeButton}
            classes={{
              iconSizeLarge: classes.selectDateRangeButton__icon,
              endIcon: classes.Arrow
            }}
            size="large"
            onMouseUp={handleClick("dateRangePicker")}
            endIcon={<SvgArrow />}
            startIcon={<Calendar style={{ marginLeft: "4px" }} />}
          >
            <Typography className={classes.selectDateRangeButton__text}>
              {printDateRange(selections.dateRange)}
            </Typography>
          </Button>

          <Popover
            open={dateRangePickerOpen}
            anchorEl={anchorEl.dateRangePicker}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "left"
            }}
          >
            <CoolDateRangePicker
              handleSubmit={handleNewDateRange}
              initialRange={selections.dateRange}
              minDate={minDate}
              noRangeLimit={noRangeLimit}
            />
          </Popover>
        </React.Fragment>
      )}
    </div>
  );
};

export default SelectionsMenu;
