import { CircularProgress, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import CoolTable, { ICoolTablePage, IHeadCell } from "../../components/CoolTable/CoolTable";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import Tooltip from "../../components/Tooltip/LightTooltip";
import {
  AppLogic,
  AppUser as UserIcon,
  HomeAuto as HomeIcon,
  HVACIcon,
  Timer as ScheduleIcon
} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import { showAuditAction } from "./AuditsActionMap";
import AuditsDataPopUp from "./AuditsDataPopUp";
import useStyle from "./AuditsList.style";
import ArrowIcon from "../../icons/ArrowLong";


type Order = "asc" | "desc";

const AuditsList: React.FC = (props: any) => {
  const classes = useStyle();

  const getSiteAudits = useStoreActions((actions) => actions.sites.getSiteAudits);
  const selections = useStoreState((state) => state.selections.selections);
  const types = useStoreState((s) => s.types);
  const [audits, setAudits] = useState<any[]>([]);
  const [filteredAudits, setFilteredAudits] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedAudit, setSelectedAudit] = React.useState<any>(null);
  const [systemUnits, setSystemUnits] = React.useState<any>({})

  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const { actionSources } = types;
  setUnitUpdateStatus({ status: "" });
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const getSystemUnits = useStoreActions((actions) => actions.systems.getSystemUnits);


  useEffect(() => {
    if (!!selections?.dateRange?.endDate && !!selections?.dateRange?.startDate) {
      return;
    }

    const range = {
      startDate: new Date(new Date().setHours(0, 0, 0) - 2 * 24 * 60 * 60 * 1000),
      endDate: new Date()
    }

    setSelections({ dateRange: range });

  }, []);

  useEffect(() => {
    if (!selections.siteId || !selections?.dateRange?.startDate || !selections?.dateRange?.endDate) {
      return
    }

    setLoading(true);

    // Get and parse audits from API into our current state
    let startTime = new Date(selections.dateRange.startDate).getTime();
    let endTime = new Date(selections.dateRange.endDate).getTime();

    getSiteAudits({
      customerId: selections.customerId,
      siteId: selections.siteId,
      startTime: startTime,
      endTime: endTime
    })
      .then((res: any) => {
        setAudits(res);
        const auditsArr: any = Object.values(res)
        const sorted = _.orderBy(auditsArr, (a: any) => moment(a.timestamp).unix(), 'desc')
        setFilteredAudits(sorted)
        setLoading(false);
      })

  }, [selections.siteId, selections.dateRange]);

  useEffect(() => {
    if (!selections.unitId && !selections.systemId) {
      const sorted = _.orderBy(Object.values(audits), (a: any) => moment(a.timestamp).unix(), 'desc')
      setFilteredAudits(sorted)
      return;
    }

    if (selections.systemId) {
      getSystemUnits(selections.systemId)
        .then((res: any) => {
          setSystemUnits(res)
        })
    }
    const type = !!selections.unitId ? "unit" : "system"
    const id = !!selections.unitId ? selections.unitId : selections.systemId
    if (type === "system") {
      const correctData = Object.values(audits).filter((audit: any) => audit?.system?.id === id)
      const sorted = _.orderBy(correctData, (a: any) => moment(a.timestamp).unix(), 'desc')
      setFilteredAudits(sorted)
    }
    else {
      const data = Object.values(audits).filter((audit: any) => {
        return id ? (audit?.unit?.id === id || (audit?.unit?.id ? audit?.unit?.id === systemUnits[id].controlUnit : false)) : true;
      })
      const sorted = _.orderBy(data, (a: any) => moment(a.timestamp).unix(), 'desc')
      setFilteredAudits(sorted)
    }

  }, [selections.systemId, selections.unitId, audits]);


  // Get one page out of audits array
  const getAuditsPage = ({ page, rowsPerPage, order, orderBy }: ICoolTablePage<any>) => {
    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
      const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }
    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
      if (b[orderBy] < a[orderBy]) return -1;

      if (b[orderBy] > a[orderBy]) return 1;

      return 0;
    }
    function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
      return order === "desc"
        ? (a, b) => descendingComparator<T>(a, b, orderBy)
        : (a, b) => -descendingComparator<T>(a, b, orderBy);
    }
    return stableSort<any>(
      filteredAudits,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  interface IAuditTableColumn extends IHeadCell<any> {
    id: any;
    title: string | undefined;
    tableCellClassName?: string | undefined;
  }

  const tableColumns: IAuditTableColumn[] = [
    {
      id: "source",
      title: t`Source`,
      disableSort: true,
      width: "15ch"
    },
    {
      id: "unitId",
      title: "Unit",
      width: "15ch",
      disableSort: true,
    },
    {
      id: "siteId",
      title: "Site",
      width: "15ch",
      disableSort: true,
    },
    {
      id: "action",
      title: "Action",
      width: "20ch",
      disableSort: true,
      tableCellClassName: classes.tableCell__actionOnSubject
    },
    {
      id: "actorId",
      title: "User",
      width: "20ch",
      disableSort: true
    },
    {
      id: "timestamp",
      title: "Date/Time",
      width: "30ch",
      disablePadding: true,
      disableSort: true,
      tableCellClassName: classes.tableCell__date
    }
  ];


  // Returns JSX formatted cell value for MUI Table
  const formatAuditRowField = (audit: any, id: any) => {
    const value = audit[id];

    switch (id) {
      case "source":
        if (audit.source === +actionSources.homeAutomation) {
          return (
            <Tooltip title={audit.sourceType ? audit.sourceType : t`Home automation / BMS`}>
              <div className={classes.iconWrapper}>
                <HomeIcon className={classes.homeIcon} />
              </div>
            </Tooltip>
          );
        }
        if (audit.source === +actionSources.schedule) {
          return (
            <Tooltip title={t`Scheduled Operation`}>
              <div className={classes.iconWrapper}>
                <ScheduleIcon className={classes.scheduleIcon} />
              </div>
            </Tooltip>
          );
        }
        if (audit.source === +actionSources.user) {
          return (
            <Tooltip title={audit.sourceType ? audit.sourceType : t`Application User`}>
              <div className={classes.iconWrapper}>
                <UserIcon className={classes.userIcon} />
              </div>
            </Tooltip>
          );
        }

        if (audit.source === +actionSources.hvac) {
          return (
            <Tooltip title={t`HVAC`}>
              <div className={classes.iconWrapper}>
                <HVACIcon className={classes.hvacIcon} />
              </div>
            </Tooltip>
          );
        }

        if (audit.source === +actionSources.applicationLogic) {
          return (
            <Tooltip title={audit.sourceType ? audit.sourceType : t`Application logic`}>
              <div className={classes.iconWrapper}>
                <AppLogic width="27" height="27" />
              </div>
            </Tooltip>
          );
        }

        return <div className={classes.noIcon}>-</div>;

      case "unitId": return audit?.unit?.name;
      case "siteId": return audit?.site?.name;
      case "action": return showAuditAction(audit);
      case "actorId": return audit?.actor?.username;
      case "timestamp": return moment(value).format("llll");

      default:
        return value;
    }
  };

  return (
    <ServiceNavigationBar
      {...props}
      filters={<SelectionsMenu
        showDateRangePicker
      />}
    >
      {(!selections.siteId || !selections?.dateRange) ?
        (<Grid container direction={"column"} className={classes.noContentContainer}>
          <div className={classes.grayOval}>
            <ArrowIcon className={classes.arrowIcon} />
          </div>
          <Typography>
            {t`Please select site and date range using the above filters.`}
          </Typography>
        </Grid>) :
        <>
          <div className={classes.contentArea}>
            <div className={classes.headerContainer} >
              <Typography variant="h5">{t`Audit Reports`}</Typography>
            </div>
            {loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
                <Typography variant="h5">{t`Loading Audit Reports...`}</Typography>
              </div>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                className={classes.wrapper}
              >
                <Grid item
                  className={classes.tableWrapper}
                >
                  <CoolTable
                    headCells={tableColumns}
                    onRowDoubleClick={(audit: any) => setSelectedAudit(audit)}
                    rows={filteredAudits}
                    getPageRows={(page, rowsPerPage, order, orderBy) => getAuditsPage({ page, rowsPerPage, order, orderBy })}
                    renderCell={formatAuditRowField}
                    defaultOrderBy={"date"}
                    options={{
                      classNameForTableContainer: classes.tableContainer,
                      rowsPerPage: 10,
                      rowsPerPageOptions: [10, 30, 50, 100]
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </div>
          {selectedAudit && <div className={classes.itemButton} >
            <AuditsDataPopUp audit={selectedAudit} isAdminAudit setIsSeen={setSelectedAudit} />
          </div>}
        </>}
    </ServiceNavigationBar>
  );
};

export default AuditsList;
