import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { t } from "ttag";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./ServiceParams.style";
import { Lookup } from "../../components/Lookup";

import { ServiceParameters as sdkServiceParameters } from "coolremote-sdk";
import { EditIcon } from "../../iconImages";
import AddEditEnumDialog from "./AddEditEnum";

export default function CustomerManagement(props: any) {
  const styles: any = useStyles();

  const [enums, setEnums] = useState<any>({});
  const [filteredEnums, setFilteredEnums] = useState<any>([]);
  const [selectedEnum, setSelectedEnum] = useState<any>(null);
  const [lookupAnchor, setLookupAnchor] = useState<any>(null);
  const [enabledFilters, setEnabledFilters] = useState<any>({
    name: []
  });
  const [filters, setFilters] = useState<any>({
    name: []
  });

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    const names: any = Object.values(enums).map(({ name }: any) => name);
    setFilters({ name: names });
  }, [enums]);

  useEffect(() => {
    const filteredEnums = _(enums)
      .filter(({ name }) => enabledFilters.name.length ? enabledFilters.name.includes(name) : true)
      .value()

    setFilteredEnums(filteredEnums);
  }, [enabledFilters, enums]);

  const fetchData = async () => {
    const enums: any = await sdkServiceParameters.getEnums();
    setEnums(enums || {});
  }

  const handleLookupPopup = useCallback((event: any, column: string) => {
    setLookupAnchor(column ? { anchor: event.currentTarget, columnName: column } : null);
  }, []);

  const onApply = (selectedFilters: any) => {
    setEnabledFilters({
      ...enabledFilters,
      [lookupAnchor.columnName]: selectedFilters
    });
    setLookupAnchor(null);
  };

  const addEnum = (data: any) => {
    sdkServiceParameters.createEnums(data)
      .then((enumResp: any) => setEnums({ ...enums, [enumResp.id]: enumResp }))
      .catch((e: any) => console.log("error", e))
  }

  const updateEnum = (id: any, { name, ...data }: any) => {
    sdkServiceParameters.updateEnums(id, data)
      .then((enumResp: any) => setEnums({ ...enums, [id]: enumResp }))
      .catch((e: any) => console.log("error", e))
  }

  return (
    <>
      <ServiceNavigationBar title={t`Service Paramters`}  {...props}>

        <div className={styles.addButtonContainer}>
          <Button
            className={styles.buttonStyle}
            onClick={() => setSelectedEnum("addNewEnum")}
          >{t`Add New Enums`}</Button>
        </div>
        <Paper elevation={0} className={styles.paperPadding}>
          <TableContainer>
            <Table stickyHeader className="" aria-label="customized table">
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell
                    align="center"
                    className={styles.headCells}
                    onClick={(e: any) => handleLookupPopup(e, "name")}
                  >
                    <div className={styles.headContainer}>
                      {t`Name`}
                      <FilterList
                        className={clsx(styles.filterStyle, {
                          [styles.blueFilter]: enabledFilters.name.length
                        })}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={styles.headCells}
                    style={{ width: "100px" }}
                  >
                    {t`Edit`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEnums.map((enums: any) => {
                  const { id, name } = enums;
                  return (
                    <TableRow hover tabIndex={-1} key={id}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={styles.rowCell}
                      >
                        {name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >
                        <IconButton title="edit" onClick={() => setSelectedEnum(enums)}>
                          <img src={EditIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ServiceNavigationBar>
      {lookupAnchor && (
        <Lookup
          filtersList={filters[lookupAnchor.columnName]}
          appliedFilters={enabledFilters[lookupAnchor.columnName]}
          onApply={onApply}
          lookupAnchor={lookupAnchor.anchor}
          onClose={() => setLookupAnchor(null)}
          clearAllFilters={() => setEnabledFilters({ name: [] })}
          hasFilters={!!Object.values(enabledFilters).flat().length}
        />
      )
      }

      {!!selectedEnum &&
        <AddEditEnumDialog
          close={() => setSelectedEnum(null)}
          enums={selectedEnum === "addNewEnum" ? null : selectedEnum}
          addEnum={addEnum}
          updateEnum={updateEnum}
        />

      }
    </>
  );
}
