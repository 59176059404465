import {  makeStyles } from "@mui/styles";
import {Theme} from  "@mui/material/styles";

export const useStyles: any = makeStyles((theme: Theme) => ({
  view: {
    width: "100%",
    height: "100%"
  },

  main: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1.5em",
    alignItems: "center",
    height: "auto",
    width: "200px",
    padding: "0 10px 0 10px",
    flex: 1,
    background: theme.palette.primary.dark,
    "&>div>header": {
      backgroundColor: "#556972",
      borderTop: "none",
      color: "white",
      justifyContent: "center"
    },
    "&>div>header:hover": {
      backgroundColor: "#556972",
      borderTop: "none",
      color: "white"
    },
    "&>div>header>span": {
      padding: "0.2em"
    }
  },

  modal: {
    "&>div": {
      top: "10%"
    },
    "&>div>div": {
      top: "0",
      width: "100%"
    }
  },

  select: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    color: "white",
    marginBottom: "10px",
    "&>div": {
      padding: "0",
      height: "auto",
      width: "initial",
      margin: "0.5rem"
    },
    "&>div>div>select": {
      width: "100%"
    },
    "&>div>div>select>option": {
      whiteSpace: "normal",
      maxWidth: "50px"
    },
    "&>div>div>select:disabled": {
      display: "none"
    }
  },

  Dropdown: {
    background: "rgba(255, 255, 255, 0.15)",
    margin: "0 0 10px",
    borderRadius: "6px",
    "& label": {
      color: "red",
      background: "red"
    }
  },

  submitButton: {
    backgroundColor: "#556972",
    color: "white",
    borderRadius: "0",
    margin: "0"
  },
  titleContainer: {
    display: "inline"
  },
  indoor: {
    fontSize: "1.5rem",
    width: "200px",
    paddingBottom: "0.5rem"
  },
  outdoor: {
    width: "200px",
    paddingBottom: "0.5rem"
  },
  hidden: {
    display: "none"
  },
  outdoorChild: {
    marginLeft: "1rem"
  },
  "@media (min-width: 800px)": {
    "& modal > div": {
      left: "20%"
    }
  }
}));
