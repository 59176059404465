import { t } from "ttag";
import { ISite } from "../models/SitesModel";

function _fahrenheitToCelsius(fahrenheit: number) {
  return Math.round(((fahrenheit - 32) * 5) / 9);
}

function _celsiusToFahrenheit(celsius: number) {
  return Math.round((celsius * 9) / 5 + 32);
}

// Will convert value to Fahrenheit if both:
// a. Given measurementUnits is '°C' or 'celsius' (any case for 'celsius').
// b. The logged-in user works with Fahrenheit.
function _conditionalConvertValueToFahrenheit(
  value: any,
  measurementUnits: string
) {
  // if F return _celsiusToFahrenheit(value);

  return value;
}

function _conditionalConvertThresholdToFahrenheit(
  value: any,
  measurementUnits: string
) {
  return (
    _conditionalConvertValueToFahrenheit(value, measurementUnits) -
    _conditionalConvertValueToFahrenheit(0, measurementUnits)
  );
}

function _getTemperatureScaleDisplay() {
  // if F return '°F';

  return "°C";
}

const CommonUnits = {
  // If no system filter is selected - Will return true.
  // If a system filter is selected - Will return true if the selected system contains a unit with the given internal ID.
  // Otherwise - false.
  isUnitInSelectedSystem(unitInternalId: string) {
    //if selected return true

    return false;
  },

  isUnitInSelectedUnit(unitInternalId: string) {
    //if selected return true

    return false;
  },

  // getAlertParsedData(alert: any) {
  //   let alertParsedData = {
  //     isError: false,
  //     typeText: "",
  //     description: ""
  //   };

  //   if (alert.data.trigger.template.indexOf("UNIT_ERROR") !== -1) {
  //     alertParsedData = {
  //       isError: true,
  //       typeText: t`Unit error`,
  //       description: t`Alert cause: ${alert.data.eventData.error.trim()} Error status`
  //     };
  //   } else if (alert.data.trigger.template.indexOf("UNIT_LIMITS") !== -1) {
  //     alertParsedData = {
  //       isError: false,
  //       typeText: t`Safety limits exceeded`,
  //       description: t`Current Temp: ${_conditionalConvertValueToFahrenheit(
  //         alert.data.metaData.ambientTemperature,
  //         alert.data.metaData.temperatureScale
  //       )}${_getTemperatureScaleDisplay()}, Setpoint: ${_conditionalConvertValueToFahrenheit(
  //         alert.data.metaData.setpoint,
  //         alert.data.metaData.temperatureScale
  //       )}${_getTemperatureScaleDisplay()}`
  //     };
  //   } else if (alert.data.trigger.template.indexOf("UNIT_EXCEEDED") !== -1) {
  //     alertParsedData = {
  //       isError: false,
  //       typeText: t`Threshold exceeded`,
  //       description: t`Current Temp: ${_conditionalConvertValueToFahrenheit(
  //         alert.data.metaData.ambientTemperature,
  //         alert.data.metaData.temperatureScale
  //       )}${_getTemperatureScaleDisplay()}, Setpoint: ${_conditionalConvertValueToFahrenheit(
  //         alert.data.metaData.setpoint,
  //         alert.data.metaData.temperatureScale
  //       )}${_getTemperatureScaleDisplay()}, Alert threshold: +-${_conditionalConvertThresholdToFahrenheit(
  //         alert.data.metaData.setThreshold,
  //         alert.data.metaData.temperatureScale
  //       )}${_getTemperatureScaleDisplay()}`
  //     };
  //   } else if (alert.data.trigger.template.indexOf("SCHEDULED_EVENT") !== -1) {
  //     alertParsedData = {
  //       isError: false,
  //       typeText: t`Scheduled maintenance`,
  //       description: t`Your scheduled maintenance arrived`
  //     };
  //   }

  //   return alertParsedData;
  // },

  fahrenheitToCelsius(fahrenheit: number) {
    return _fahrenheitToCelsius(fahrenheit);
  },

  celsiusToFahrenheit(celsius: number) {
    return _celsiusToFahrenheit(celsius);
  },

  conditionalConvertValueToFahrenheit(value: any, measurementUnits: string) {
    return _conditionalConvertValueToFahrenheit(value, measurementUnits);
  }
};

export default CommonUnits;

export function makeFullAddressForSite(site: ISite) {
  if (!site) {
    return "";
  }
  let address = "";
  if (!site.country) return "";
  // US version !
  if (site.address) address += site.address;
  if (site.city) address += `, ${site.city}`;
  if (site.state) address += `, ${site.state}`;
  if (site.postalCode) address += `, ${site.postalCode}`;
  address += `, ${site.country}`;
  return address;
}

export function makeFullName({
  firstName,
  lastName
}: {
  firstName: string;
  lastName: string;
  [key: string]: any;
}) {
  if (!firstName) {
    if (!lastName) return "-";
    else return lastName;
  } else {
    return firstName + " " + lastName;
  }
}
