import React, { useEffect, useState } from 'react';
import useStyles from "./DeviceMigration.style";
import {
  Button,
  Typography,
  CircularProgress
} from "@mui/material";
import { t } from "ttag";

const DataIndicator = (props: any) => {
  const { handleAPI, title, nextStep, goBack, siteName, siteId, handleLastStep } = props;
  const styles: any = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setError("")
  }, [title])

  const submit = () => {
    setIsLoading(true);

    handleAPI({ siteId: siteId })
      .then((res: any) => {
        nextStep()
        handleLastStep && handleLastStep()
      })
      .catch((err: any) => {
        setError(err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className={styles.dataIndicatorContainer}>
      <Typography>
        {`Handle ${title} for site ${siteName}, this might take a while`}
      </Typography>
      <div className={styles.loaderContainer}>
        {isLoading && <CircularProgress className={styles.loader} />}
        {error && <Typography className={styles.errorMsg}>{error || ""}</Typography>}
      </div>
      <div className={styles.actionsContainer}>
        <Button
          variant="contained"
          color="primary"
          className={styles.backBtn}
          onClick={() => goBack()}
        >
          {t`Back`}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={styles.nextBtn}
          onClick={() => submit()}
          disabled={isLoading}
        >
          {t`Next`}
        </Button>
      </div>
    </div>
  );
};

export default DataIndicator;