import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  paperPadding: {
    padding: "20px 21px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "200px"
  },
  tableHead: {
    height: "45px",
    backgroundColor: "rgb(239, 239, 242, 0.48)"
  },
  headCells: {
    border: "none",
    borderBottom: "none",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    fontFamily: theme.tables.headerCell.fontFamily,
    textTransform: theme.tables.headerCell.textTransform,
    height: 45,
    fontSize: 13
  },
  rowCell: {
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    fontFamily: theme.tables.tableCell.fontFamily,
    padding: theme.tables.tableCell.padding
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  buttonStyle: {
    background: theme.gradiants.button,
    color: "#fdfdfe",
    "&:hover": {
      opacity: 0.8
    }
  }
}));
export default useStyles;
