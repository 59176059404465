import {
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  TextField,
  Typography
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { t } from "ttag";
import Checkbox from "../cool_widgets/CoolCheckbox";
import useStyles, { paperStyle } from "./lookup.style";

export default function Lookup(props: any) {
  const classes: any = useStyles();
  const {
    onApply,
    lookupAnchor,
    filtersList = [],
    onClose,
    appliedFilters = [],
    replacementContent = false,
    clearAllFilters,
    hasFilters
  } = props;

  const { data, property } = replacementContent;
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>(
    appliedFilters
  );
  const [filteredFilters, setFilteredFilters] = React.useState<string[]>(
    filtersList
  );
  const [searchValue, handleSearchValue] = React.useState<string>("");
  const [displayAllOptions, setDisplayAllOptions] = React.useState<boolean>(filteredFilters.length < 50);
  const [selectAll, setSelectAll] = React.useState<boolean>(selectedFilters.length === 0 || appliedFilters.length === filtersList.length);

  // reset values on kill component
  useEffect(() => {
    return () => {
      setFilteredFilters([]);
      setSelectedFilters([]);
      handleSearchValue("");
    };
  }, []);

  const handleFilters = (value: string) => {
    if (selectedFilters.length === 0 && selectAll) {
      const list = [...filtersList];
      const index = list.indexOf(value);
      list.splice(index, 1);
      setSelectedFilters([...list]);
      setSelectAll(false);
      return;
    }
    if (selectedFilters.includes(value)) {
      const index = selectedFilters.indexOf(value);
      selectedFilters.splice(index, 1);
      setSelectedFilters([...selectedFilters]);
    } else {
      setSelectedFilters([...selectedFilters, value]);
    }
    if (filtersList.length === selectedFilters.length) {
      setSelectAll(true);
      setSelectedFilters([]);
    } else {
      setSelectAll(false);
    }
  };


  const apply = () => {
    onApply &&
      onApply(searchValue?.length ? (filteredFilters) : (selectAll || filtersList.length === selectedFilters.length ? [] : selectedFilters));
  };
  const filterBySearchValue = (value: string) => {
    const filteredItems = filtersList.filter((item: any) => {
      if (!item) {
        return null;
      }
      const stringSearchingValue = value.toLowerCase();

      if (item.toLowerCase().indexOf(stringSearchingValue) >= 0) {
        return item;
      }
      return null;
    });
    setFilteredFilters(filteredItems);
    setDisplayAllOptions(filteredItems.length < 50)
  };

  const handleSearchValueChange = (event: any) => {
    const {
      target: { value }
    } = event;
    handleSearchValue(value);
  };

  useEffect(() => {
    if (!searchValue) {
      setFilteredFilters(filtersList);
      return;
    }

    filterBySearchValue(searchValue);
  }, [filtersList, searchValue]);

  const handleAllFilters = (checked: boolean) => {
    setSelectAll(checked);
    setSelectedFilters([]);
  };

  const clearFilters = () => {
    clearAllFilters();
    onClose();
  };
  const stopPropagation = (e: any) => {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      default:
        e.stopPropagation();
    }
  };

  return (
    <Menu
      id="filters--menu"
      anchorEl={lookupAnchor}
      keepMounted
      open={true}
      onClose={onClose}
      className={classes.menuStyle}
      PaperProps={{
        style: paperStyle
      }}
    >
      <div className={classes.menuHeader}>
        <Typography>{t`Filters`}</Typography>
        <Clear onClick={onClose} />
      </div>
      <TextField
        placeholder={t`Search`}
        variant="outlined"
        value={searchValue}
        onChange={handleSearchValueChange}
        onKeyDown={stopPropagation}
        className={classes.textField}
      />
      <List disablePadding={true} className={classes.optionsContainer}>
        {_.isEmpty(filteredFilters) && (
          <ListItem dense autoFocus divider key={"no data"}>
            <ListItemText id={"no-data"} primary={t`No data to display`} />
          </ListItem>
        )}
        {!_.isEmpty(filteredFilters) && filteredFilters.length > 1 && (
          <ListItem dense autoFocus divider key={"all"}>
            <ListItemText className={classes.optionTextStyle} id={"all"} primary={t` All`} />
            <ListItemSecondaryAction>
              <Checkbox
                color="default"
                edge="end"
                onChange={(event: any) => handleAllFilters(event.target.checked)}
                checked={selectAll}
                indeterminate={!selectAll && selectedFilters.length !== 0}
                inputProps={{ "aria-labelledby": `toggle all` }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {(displayAllOptions ? filteredFilters : filteredFilters.slice(0, 50)).map((option: string, index: number) => (
          <ListItem
            dense
            autoFocus
            divider
            key={index}
            className={classes.optionStyle}
          >
            <ListItemText
              className={classes.optionTextStyle}
              id={`${index}`}
              primary={
                replacementContent
                  ? (data[option] && data[option][property]) || ""
                  : option || t`BLANK`
              }
            />
            <ListItemSecondaryAction>
              <Checkbox
                color="default"
                edge="end"
                onClick={() => handleFilters(option)}
                checked={selectedFilters.includes(option) || selectAll}
                inputProps={{ "aria-labelledby": option }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {
          !displayAllOptions && (
            <ListItem
              dense
              autoFocus
              divider
              key={"all-options-button"}
              className={classes.optionStyle}
            >
              <ListItemText
                className={classes.optionTextStyle}
                id={`${"all-options-button"}`}
              />
              <ListItemSecondaryAction>
                <Button
                  onClick={() => setDisplayAllOptions(true)}
                  variant="text"
                  className={classes.displayAllOptions}
                >
                  Display all options
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          )
        }
      </List>
      <Button
        className={classes.applyBtn}
        variant="contained"
        color="primary"
        onClick={apply}
        disabled={!filtersList.length || filtersList.length === 1}
      >{t`Apply`}</Button>

      {/* <Button
        variant="text"
        onClick={clearFilters}
        disabled={!hasFilters}
      >{t`Reset All Filters`}</Button> */}
    </Menu>
  );
}
