import {
  Action,
  action,
  computed,
  Computed,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface ISelections {
  customerId: string | null;
  siteId: string | null;
  systemId: string | null;
  unitId: string | null;
  lastSelectedUnitId: string | null;
  dateRange: IDateRange | null; // null - no date range selected
  isFromMap?: boolean;
}
export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

export interface ISelectionsModel {
  selections: ISelections;
  setSelections: Action<ISelectionsModel, Partial<ISelections>>;
  getCustomersBySelection: Computed<ISelectionsModel, any, IRootStoreModel>;
  filtersTree: any;

}

export const selectionsModel: ISelectionsModel = {
  selections: {
    customerId: "",
    siteId: null,
    systemId: null,
    unitId: null,
    lastSelectedUnitId: null,
    dateRange: null
  },
  setSelections: action((state, payload) => {
    _.assign(state.selections, payload);
  }),

  getCustomersBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.customers.customersBasicList
    ],
    (selections, allCustomers) => {
      return Object.values(allCustomers);
    }
  ),
  filtersTree: {}
};
