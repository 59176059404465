import { GlobalAdmin } from "coolremote-sdk";
import { Thunk, thunk } from "easy-peasy";

export interface IAdminUsersModel {
  getAllAdminUsers: Thunk<IAdminUsersModel>;
  updateUserAdmin: Thunk<IAdminUsersModel, { id: string; data: any }>;
  deleteUserAdmin: Thunk<IAdminUsersModel, string>;
  createAdminUser: Thunk<IAdminUsersModel, any>;
  getDeviceLocationsByUsername: Thunk<IAdminUsersModel, string>;
  createSites: Thunk<IAdminUsersModel, any>;
}

export const adminUsersModel: IAdminUsersModel = {
  getAllAdminUsers: thunk(async (actions, payload) => {
    return GlobalAdmin.getAdminUsers();
  }),
  updateUserAdmin: thunk(async (actions, payload) => {
    return GlobalAdmin.updateAdminUser(payload.id, payload.data);
  }),
  deleteUserAdmin: thunk(async (actions, payload) => {
    return GlobalAdmin.deleteAdminUser(payload);
  }),
  createAdminUser: thunk(async (actions, payload) => {
    return GlobalAdmin.createAdminUser(payload);
  }),
  getDeviceLocationsByUsername: thunk(async (actions, payload) => {
    return GlobalAdmin.getDeviceLocationsByUsername(payload);
  }),
  createSites: thunk(async (actions, payload) => {
    return GlobalAdmin.createSites(payload);
  }),
};
