let token: string;

function fetchWrapper(method: string, url: string, attr: any) {
    return fetch(url, {
        method,
        headers: new Headers({
            "Content-type": "application/json; charset=UTF-8",
            "x-access-token": token
        }),
        body: attr ? JSON.stringify(attr) : undefined
    });
}

export const localXhr = {
    setToken(newToken: string) {
        localStorage.removeItem("token");
        localStorage.setItem("token", newToken);
        token = newToken;
    },
    removeToken() {
        localStorage.removeItem("token");
        token = "";
    },

    getToken() {
        return token;
    },

    hasToken() {
        return token && token.length > 0;
    },

    loadToken(){
        const localToken = localStorage.getItem("token");
        if (localToken){
            token = localToken;
        }
    },

    get(url: string) {
        return fetchWrapper("get", url, undefined);
    },

    post(attr: any, url: string) {
        return fetchWrapper("post", url, attr);
    },

    put(attr: any, url: string) {
        return fetchWrapper("put", url, attr);
    },

    delete(url: string) {
        return fetchWrapper("delete", url, undefined);
    }

};
