import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles((theme: any) => ({
  dialog: {
    width: "600px"
  },
  submitButton: {
    backgroundImage: theme.gradiants.button || theme.gradiants.button || "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    color: "#fdfdfe"
  },
  disabledButton: {
    color: theme.palette.common.white + "!important",
    opacity: "0.6"
  },
  sharedButtonStyle: {
    width: "104px",
    height: "40px",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: 500,
    marginLeft: 10,
    marginBottom: 10,
    padding: "0"
  },
  actionsContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end"
  },
  filterPopupList: {
    width: "calc(100% - 10px)",
    height: "300px",
    overflowY: "auto",
    paddingLeft: 10
  },
  fieldLabel: {
    width: "100%",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.dark,
    marginBottom: "5px"
  },
  optionsHolder: {
    display: "flex",
    marginTop: "20px",
    textTransform: "capitalize",
    width: "100%",
    flexDirection: "column"
  },
  fieldsContainer: {
    display: "flex",
    width: "100%",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden"
  },
  inputField: {
    width: "100% !important",
    marginTop: "0",
    marginRight: "0 !important"
  },
  dialogTitle: {
    color: theme.palette.primary.dark
  },
  grayCheckbox: {
    marginRight: "10px",
    "&$checked": {
      color: "#736973"
    }
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "right"
  },
  errorMsg: {
    color: "red !important",
    textAlign: "left",
    marginTop: "5px",
    width: "100%"
  },
  datesContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
    width: "98%",
    flexWrap: "wrap"
  },
  datePicker: {
    width: "48%"
  },
  tertiaryButtonStyle: {
    height: "fit-content",
    alignSelf: "center",
    textTransform: "unset",
    "&:hover": {
      background: "unset",
      textDecorationLine: "underline",
      textShadow: "0 0 black"
    }
  },
  textOR: {
    width: 104,
    marginLeft: "auto",
    textAlign: "center",
    lineHeight: 1,
    fontSize: 14
  },
  loader: {
    width: 100,
    height: 100,
    margin: "20vh 40%"
  },
  loaderContainer: {
    width: "100%",
    zIndex: 9,
    position: "absolute"
  }
}));
export default useStyles;
