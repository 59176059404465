import React, { useState, useRef } from 'react';
import {
    Button,
    TextField,
    Typography,
} from "@mui/material";
import { t } from "ttag";
import useStyles from "./DeviceMigration.style";

import { saveAs } from "file-saver";
import { useStoreActions } from '../../models/RootStore';

const ExcelJS = require('exceljs');


const exportUserDataToExcel = (username: string, result: any[]) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet');
    worksheet.columns = [
        { header: 'location name', key: 'name', width: 30 },
        { header: 'location timezone', key: 'timezone', width: 30 },
        { header: 'location country', key: 'country', width: 30 },
        { header: 'location city', key: 'city', width: 30 },
        { header: 'location street', key: 'street', width: 30 },
        { header: 'location  postal code', key: 'postalCode', width: 30 },
        { header: 'location region', key: 'region', width: 30 },
        { header: 'device serial', key: 'serial', width: 30 },
    ];
    result.forEach((device: any) => {
        worksheet.addRow(device)
    });
    workbook.xlsx.writeBuffer().then(function (buffer: any) {
        saveAs(
            new Blob([buffer], { type: "applicationi/xlsx" }),
            `${username}.xlsx`
        );
    });
}


const UserDataExport = (props: any) => {
    const styles: any = useStyles();
    const [error, setError] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);
    const getDeviceLocationsByUsername = useStoreActions((action) => action.adminUsers.getDeviceLocationsByUsername);


    const exportUserData = () => {
        const username = inputRef?.current?.value;
        if (!username) {
            setError("Please enter a valid username")
            return;
        }
        setError("")
        getDeviceLocationsByUsername(username)
            .then((result: any) => {
                exportUserDataToExcel(username, result);
            })
            .catch((err: any) => {
                setError(err.message)
            })
    }

    return (
        <div className={styles.customerPageContainer}>
            <Typography className={styles.errorMsg}>{error || ""}</Typography>
            <Typography className={styles.headerTitle}>{t`Export CR data by user`}</Typography>
            <div className={styles.existingCustomerContainer}>
                <TextField
                    className={styles.autocomplete}
                    id="filled-basic"
                    type="search"
                    placeholder={"username"}
                    variant="filled"
                    inputRef={inputRef}
                />
                <Button
                    title={t`submit`}
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={styles.nextBtn}
                    onClick={exportUserData}
                >
                    {t`submit`}
                </Button>
            </div>
        </div>
    );
};

export default UserDataExport;