import React from "react";
import { t } from "ttag";
import styles from "./NoAccessScreen.module.css";

class NoAccessScreen extends React.Component {
  public render() {
    return (
      <h1 className={styles.main}>
        {t`You do not have access to this page!`}
      </h1>
    );
  }
}

export default NoAccessScreen;
