import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  selectionsContainer: {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center"
  },
  selectUnitButton: {
    overflow: "hidden",
    width: "10.4rem",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    color: theme.palette.common.white,
    background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    },
    fontSize: "0.8125rem !important",
    fontWeight: "normal !important"
  },
  selectUnitButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectUnitButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  selectDateRangeButton: {
    width: "30ch",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    color: theme.palette.common.white,
    background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  oneDatePicker: {
    height: 30,
    justifyContent: "space-between",
    margin: "0 .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    color: theme.palette.common.white,
    background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    },
    "& input": {
      color: "white",
      "-webkit-text-fill-color": "white !important",
      pointerEvents: "none"
    },
    "& svg": {
      fill: "white"
    },
    "& div:before": {
      border: 0
    }
  },
  selectDateRangeButton__text: {
    fontSize: "0.8125rem !important",
    fontWeight: "normal !important"
  },
  selectDateRangeButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  searchIcon: {
    color: theme.header.searchComponent.color
  },
  autoCompletePoper: {
    backgroundColor: theme.header.searchComponent.backgroundColor
  },
  autoCompleteItem: {
    backgroundColor: "rgba(0,0,0,0)",
    color: theme.header.searchComponent.color,
    "& li": {
      margin: "5px 10px"
    }
  },
  autoCompletePaper: {
    backgroundColor: "rgba(0,0,0,0)"
  },
  autoCompleteGroup: {
    backgroundColor: theme.header.searchComponent.backgroundColor,
    color: theme.header.searchComponent.color,
    fontWeight: theme.header.searchComponent.fontWeight,
    fontSize: theme.header.searchComponent.fontSize
  },
  autoCompleteGroupUl: {
    backgroundColor: theme.header.searchComponent.autoCompleteGroupUl
  },
  inputRoot: {
    color: theme.header.searchComponent.inputRoot.color,
    fontFamily: theme.header.searchComponent.inputRoot.fontFamily,
    fontSize: theme.header.searchComponent.inputRoot.fontSize,
    backgroundColor: theme.header.searchComponent.inputRoot.backgroundColor,
    width: "220px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 0 0 10px !important",
    marginRight: "8px",
    marginLeft: "10px"
  },
  datePicker: {
    input: {
      color: "white"
    }
  },
  endAdornment: {
    right: "4px !important"
  },
  Arrow: {
    marginLeft: "0px",
    "&>svg": {
      fontSize: "36px !important"
    }
  }, disabled: {
    color: "gray !important"
  },
  unitName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }

}));
export default useStyles;
