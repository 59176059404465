import {
  MenuItem,
  Typography,
  Menu,
  CircularProgress
} from '@mui/material';
import clsx from "clsx";
import React, { useState } from "react";
import styles from "./asyncSelect.module.css";
import { Arrow as SvgArrow } from "../../icons/";

const AsyncSelect = ({ options = [], value, update }: any) => {
  const [loading, setLoading] = useState<any>("")
  const [open, setOpen] = React.useState(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleChange = async (value: any) => {
    setLoading(value);
    await update(value);
    setTimeout(() => {
      setAnchor(null);
      setLoading("")
    }, 0);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  return (
    <>
      <div
        className={styles.select}
        onClick={(event: any) => setAnchor(event.currentTarget)}
      >
        <Typography className={styles.selectText}>{value}</Typography>
        <SvgArrow className={styles.arrowIcon} />
      </div>
      <Menu
        onClick={(event: any) => event.stopPropagation()}
        classes={{ paper: styles.menu }}
        id="subType-menu"
        anchorEl={anchor}
        open={!!anchor}
        onClose={() => {
          if (loading) {
            return;
          }
          setAnchor(null);
        }}
      >
        {options.map((option: any) => (
          <MenuItem
            key={option.value}
            onClick={() => handleChange(option.value)}
            className={clsx(styles.menuItem, loading ? ((loading === option.value) ? styles.selected : "") : (value === option.label ? styles.selected : ""))}
          >
            {option.label} {(loading === option.value) ? <CircularProgress size={14} /> : ""}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
};

export default AsyncSelect;
