import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  InputAdornment,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import ErrorBox from "../../components/WarnningBox/ErrorBox";
import { EditIcon } from "../../iconImages";
import { Delete} from "../../icons";
import { useStoreActions } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import AddEditAdminUser from "./AddEditAdminUser";
import useStyles from "./AdminUsersList.style";
import { Close, Search } from "@mui/icons-material";

const AdminUsersList = (props: any) => {
  const styles: any = useStyles();
  const [AdminUsers, setAdminUsers] = useState<any>({});
  const [openAddEdit, setOpenAddEdit] = useState<any>(null);
  const [deleteId, setDeleteId] = useState<any>(null);
  const getAllAdminUsers = useStoreActions((action) => action.adminUsers.getAllAdminUsers);
  const deleteUserAdmin = useStoreActions((action) => action.adminUsers.deleteUserAdmin);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const [searchValue, setSearchValue] = useState<any>("");
  const [filteredAdminUsers, setFilteredAdminUsers] = useState<any>(AdminUsers);

  useEffect(() => {
    getAllAdminUsers()
      .then((res: any) => {
        setAdminUsers(res);
      });

  }, []);



  useEffect(() => {
    filterAdminUser();
  }, [searchValue, AdminUsers]);


  const filterAdminUser = () => {

    const filteredData = Object.values(AdminUsers).filter((AdminUser: any) => {
      const name = AdminUser?.username?.toUpperCase();
      const firstName = AdminUser?.firstName?.toUpperCase();
      const lastName =  AdminUser?.lastName?.toUpperCase();
      const email=  AdminUser?.email?.toUpperCase();
      const search = searchValue.toUpperCase();
      return (name?.indexOf(search) >= 0 ||firstName?.indexOf(search) >= 0 || lastName?.indexOf(search) >= 0 || email?.indexOf(search)>= 0);
    });
    setFilteredAdminUsers(filteredData);
  };

  const handleDelete = () => {
    deleteUserAdmin(deleteId)
      .then(() => {
        const { [deleteId]: toDelete, ...restOfAdminUsers } = AdminUsers;
        setAdminUsers(restOfAdminUsers);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
    setDeleteId(null);
  };

  return (
    <>
      <ServiceNavigationBar
        title={t`Admin Users Management`}
        searchComponent={
          <Input
            placeholder={t`Search`}
            value={searchValue}
            onChange={(event: any) => setSearchValue(event.target.value)}
            disableUnderline={true}
            classes={{ root: styles.inputRoot }}
            startAdornment={
              <InputAdornment position="start" className={styles.searchAdorment}>
                <Search className={styles.searchIcon} />
              </InputAdornment>
            }
            endAdornment={
              searchValue && (
                <IconButton
                  onClick={() => setSearchValue("")}
                  className={styles.closeIconStyle}
                >
                  <Close />
                </IconButton>
              )
            }
          />
        }
        {...props}
      >
        <div className={styles.addButtonContainer}>
          <Button
            className={styles.buttonStyle}
            onClick={() => setOpenAddEdit("new")}
          >{t`ADD NEW User`}</Button>
        </div>
        {
          <>
            <Paper elevation={0} className={styles.paperPadding}>
              <TableContainer>
                <Table stickyHeader className="" aria-label="customized table">
                  <TableHead className={styles.tableHead}>
                    <TableRow>
                      <TableCell className={styles.headCells}>{t`USERNAME`}</TableCell>
                      <TableCell className={styles.headCells}>{t`FIRST NAME`}</TableCell>
                      <TableCell className={styles.headCells}>{t`LAST NAME`}</TableCell>
                      <TableCell className={styles.headCells}>{t`EMAIL`}</TableCell>
                      <TableCell align="center" className={styles.headCells} >{t`DELETE`}</TableCell>
                      <TableCell align="center" className={styles.headCells} >{t`EDIT`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.orderBy(filteredAdminUsers, [(user) => user.username.toLowerCase()], ["asc"]).map((user: any) => {
                      return (
                        <TableRow hover tabIndex={-1} key={user.id} >
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {user.username}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {user.firstName}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {user.lastName}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {user.email || ""}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={styles.rowCell}
                          >
                            <IconButton
                              onClick={() => { setDeleteId(user.id); }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={styles.rowCell}
                          >
                            <IconButton onClick={() => { setOpenAddEdit(user.id); }}>
                              <img src={EditIcon} alt="edit" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {deleteId && <ErrorBox title={t`Delete Admin user`} error={t`Are you sure you want to delete ${AdminUsers[deleteId]?.username}`} onAccept={() => handleDelete()} onClose={() => setDeleteId(null)} />}
            {openAddEdit &&
              <AddEditAdminUser
                isEdit={openAddEdit !== "new"}
                adminUser={AdminUsers[openAddEdit]}
                setOpenAddEdit={setOpenAddEdit}
                setAdminUsers={setAdminUsers}
                AdminUsers={AdminUsers}
              />}
          </>
        }
      </ServiceNavigationBar >
    </>

  );
};

export default AdminUsersList;
