import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import styles from "./SimpleModal.module.css";

interface IProps {
  showModal: boolean;
  title: string;
  contentText?: string;
  actionName?: string;
  onAction?: () => void;
  closeModal?: () => void;
  children?: any | any[];
  hideBtns?: boolean;
  fullWidth?: boolean;
}

export default function SimpleModal(props: IProps) {
  if (!props.showModal) {
    return <div />;
  }

  return (
    <Dialog onClose={props.closeModal} aria-labelledby="simple-dialog-title" open={true} fullWidth={props.fullWidth}>
      <DialogTitle style={{
        borderBottom: "1px solid #d5d2d5",
        backgroundColor: "#f6f6f7"
      }} id="simple-dialog-title">{props.title}</DialogTitle>
      <DialogContent className={styles.content}>
        {props.contentText && <Typography>{props.contentText}</Typography>}
        {props.children}
      </DialogContent>
      {!props.hideBtns &&
        <DialogActions>
          <Button onClick={props.closeModal} >
            {t`Cancel`}
          </Button>
          <Button onClick={props.onAction} >
            {props.actionName}
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}
