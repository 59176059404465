import { MenuItem, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import * as Yup from "yup";
import { useStoreState } from "../../models/RootStore";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import useStyles from "./ServiceParams.style";


////
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from "formik";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField";
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default function OneCustomer(props: any) {
  const styles: any = useStyles();
  const { close, enums = null, addEnum, updateEnum } = props;
  const isEdit = !!enums;
  const [name, setName] = useState<any>(enums?.name || "");
  const [text, setText] = useState<any>(JSON.stringify(enums?.data || {}, undefined, 2));
  const [nameErrorMsg, setNameErrorMessage] = useState<any>("");
  const [jsonErrorMsg, setJsonErrorMessage] = useState<any>("");

  const submit = () => {
    setNameErrorMessage("");
    setJsonErrorMessage("");

    let jsonError = "";

    try {
      JSON.parse(text);
    } catch (e) {
      const error: any = e;
      jsonError = error.message;
    }

    if (!name) {
      setNameErrorMessage("required");
    }

    if (jsonError) {
      setJsonErrorMessage(jsonError);
    }

    if (!name || jsonError) {
      return;
    }

    const data = {
      name,
      data: JSON.parse(text)
    }

    if (isEdit) {
      updateEnum(enums.id, data)
    } else {
      addEnum(data)
    }

    close();
  }

  const delayedCall = useCallback(_.debounce((value: any) => {
    if (value) {
      try {
        JSON.parse(value);
      } catch (e) {
        const error: any = e;
        setJsonErrorMessage(error.message)
      }
    }
  }, 3000), []);

  return (

    <Dialog
      open={true}
      onClose={close}
      classes={{ paper: styles.dialogPaper }}
    >
      <DialogTitle className={styles.dialogHeader}>{isEdit ? "Edit " : "Add "}Enum</DialogTitle>
      <div style={{ display: "flex", flexFlow: "column nowrap", padding: "20px" }}>
        <TextField
          disabled={isEdit}
          style={{ width: 300, marginBottom: 20 }}
          label={"Name"}
          value={name}
          onChange={({ target: { value } }: any) => setName(value)}
          // className={styles.selectField}
          // InputProps={{
          //   disableUnderline: true,
          //   classes: {
          //     formControl: styles.inputRoot,
          //     root: styles.inputRoot
          //   }
          // }}
          // InputLabelProps={{ shrink: false }}
          margin="none"
        />
        <p style={{ color: "red", marginBottom: "10px", marginTop: "-5px" }}>{nameErrorMsg}</p>
        <TextareaAutosize
          name="test"
          minRows={26}
          value={text}
          onChange={({ target: { value } }: any) => {
            setText(value);
            delayedCall(value);
          }}
          placeholder="write json object data"
          style={{ width: 702 }}
          {...props}
        />
        <p style={{ color: "red", marginTop: "10px", marginBottom: "10px" }}>{jsonErrorMsg}</p>
      </div>
      <DialogActions className={styles.actionsHolder}>
        <Button className={styles.cancelStyle} onClick={close}>Cancel</Button>
        <Button className={styles.buttonStyle} onClick={submit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
