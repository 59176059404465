import { Clear } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { EditIcon } from "../../iconImages";
import { ArrowDownO, CheckboxChecked, IndeterminateCheckbox } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField"
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import Site from "../SiteManagement/Site";
import style from "./EditCustomer.module.css";

const EditCustomer = (props: any) => {
  const {
    history,
    match: {
      params: { customerId }
    },
    viewAsComponent = false,
    submit
  } = props;

  const reducedCustomers = useStoreState((state) => state.customers.reducedCustomers);
  const [customer, setCustomer] = useState<any>(reducedCustomers?.[customerId]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [enablePowerDistribution, setEnablePowerDistribution] = useState<boolean>(false);
  const [enableMgmtAppAccess, setEnableMgmtAppAccess] = useState<boolean>(false);
  const [enableServiceAppAccess, setEnableServiceAppAccess] = useState<boolean>(false);
  const [enableCommercialAppAccess, setEnableCommercialAppAccess] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(!customerId ? true : false);
  const types = useStoreState((s) => s.types);
  const { sitePackagesNamesEnums, sitePackagesFlagsEnums, regionTypes } = types;
  const packagesMirrore: { [key: string]: string } = Object.keys(sitePackagesFlagsEnums || {}).reduce((acc: any, key: string) => {
    acc[sitePackagesFlagsEnums?.[+key] || ""] = sitePackagesNamesEnums[+key]
    return acc;
  }, {})
  const [error, setError] = useState<string>("");
  const [updatedPackageData, setUpdatedPackageData] = useState<any>({});
  const [initPackages, setInitPackages] = useState<any>({});

  const createCustomer = useStoreActions((actions) => actions.customers.createCustomer);
  const getCustomerById = useStoreActions((actions) => actions.customers.getCustomerById);
  const getReducedCustomers = useStoreActions((actions) => actions.customers.getReducedCustomers);
  const customers = useStoreState((state) => state.customers.customersBasicList);
  const updateCustomer = useStoreActions((actions) => actions.customers.updateCustomer);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getCustomerCategories = useStoreActions((actions) => actions.getCustomerCategories);
  const customerCategoriesOptions = useStoreState((state) => state.customerCategoriesOptions);
  const customerCategories = useStoreState((state) => state.customerCategories);
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const { mySupportedPages = "all" } = useStoreState((s) => s.users);
  const showAllPages = mySupportedPages === "all";
  const getSubscriptions = useStoreActions((actions) => actions.subscriptions.getSubscriptions);
  const [subscriptions, setSubscriptions] = useState<any>({});
  const getAllAdminUsers = useStoreActions((action) => action.adminUsers.getAllAdminUsers);
  const [adminOptions, setAdminOptions] = useState<any>([]);

  useEffect(() => {
    getAllAdminUsers()
      .then((res: any) => {
        setAdminOptions(Object.entries(res).map(([userId, { firstName = "", lastName = "" }]: any) => ({
          value: userId,
          label: `${firstName} ${lastName}`
        })))
      });

  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required(t`Name is required`),
    discription: Yup.string(),
    region: Yup.number().notOneOf([regionTypes["unknown"]], "Region is required")
  });
  useEffect(() => {
    (async () => {
      if (!customerId) {
        return;
      }
      Promise.all([getCustomerById(customerId), getSubscriptions(customerId)]).then((response: any) => {
        const { packages, ...customerInfo } = response[0];
        setInitPackages(packages);
        setUpdatedPackageData(packages)
        setEnablePowerDistribution(!!customerInfo.enablePowerDistribution);
        setEnableMgmtAppAccess(!!customerInfo.enableMgmtAppAccess);
        setEnableServiceAppAccess(!!customerInfo.enableServiceAppAccess);
        setEnableCommercialAppAccess(!!customerInfo.enableCommercialAppAccess);
        setCustomer({ ...customer, ...customerInfo });

        setSubscriptions(response[1]);
      }).catch(({ message }: any) => {
        addMessage({ message });
      });

      if (_.isEmpty(reducedCustomers?.[customerId]))
        getReducedCustomers().then((customers: any) => setCustomer({ ...customer, ...customers?.[customerId] }))

    })();
  }, [customerId, customers, getCustomerById]);

  useEffect(() => {
    if (!customerCategoriesOptions.length) { getCustomerCategories(); }
  }, [])

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const saveCustomer = (values: any) => {
    // delete values.ownerCS
    // delete values.ownerCA
    setError("");
    if (Object.values(updatedPackageData || {}).filter(Boolean).length === 0) {
      setError("at least one package needs to be enabled");
      return;
    }
    setSubmitting(true);
    values.enablePowerDistribution = enablePowerDistribution;
    values.enableMgmtAppAccess = enableMgmtAppAccess;
    values.enableServiceAppAccess = enableServiceAppAccess;
    values.enableCommercialAppAccess = enableCommercialAppAccess;
    values.packages = updatedPackageData;

    updateCustomer({ id: customerId, data: values })
      .then(() => {
        setEditable(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };


  const onCreateCustomer = async (values: any) => {
    // delete values.ownerCS
    // delete values.ownerCA
    setSubmitting(true);
    values.enablePowerDistribution = enablePowerDistribution;
    values.enableMgmtAppAccess = enableMgmtAppAccess;
    values.enableServiceAppAccess = enableServiceAppAccess;
    values.enableCommercialAppAccess = enableCommercialAppAccess;

    createCustomer({ data: values })
      .then((res: any) => {
        setSubmitting(false);
        !viewAsComponent && history.push(`/customers`);
        submit && submit(res?.id)
      })
      .catch((err: any) => {
        setSubmitting(false);
        addMessage({ message: err.message });
      })

  };


  const regionOptions: any = Object.keys(regionTypes).map((region: any) => {
    return {
      value: types.regionTypes[region],
      label: region,
      disabled: region === "unknown"
    };
  });

  const Checkbox1 = (props: any) => {
    return <Checkbox
      color="default"
      edge="end"
      variant="outlined"
      disabled={!editable}
      onClick={(event: any) => event.stopPropagation()}
      checkedIcon={<CheckboxChecked />}
      indeterminateIcon={<IndeterminateCheckbox />}
      className={style.smallCheckbox}
      {...props}
    />;
  };

  const handlePackageChange = (checked: boolean, type: string, packageFlag: string) => {

    if (type === "control") {
      if (checked) {
        setUpdatedPackageData({ ...updatedPackageData, [packageFlag]: false });
      } else {
        setUpdatedPackageData(Object.keys(updatedPackageData).reduce((acc: any, cur: string) => {
          if (cur === "hvacControl") {
            acc[cur] = true;
          }
          else {
            acc[cur] = false;
          }
          return acc;
        }, {}))
      }
    }
    if (type === "professional") {
      if (checked) {
        setUpdatedPackageData({ ...updatedPackageData, [packageFlag]: false });
      } else {
        setUpdatedPackageData({ ...updatedPackageData, [packageFlag]: true, "hvacControl": false });
      }
    }

  };

  const resetPackages = () => {
    setUpdatedPackageData(initPackages)
  };

  return (

    <ServiceNavigationBar hideHeader={viewAsComponent} title={t`Customers ` + ` ${customer?.name ? `> ${customer.name}` : ""}`} {...props}>
      <div className={clsx(style.editCustomerPageContainer, { [style.pageContainerAsComponent]: viewAsComponent })}>
        <div className={clsx(style.editCustomerPaper, { [style.fullWidthPaper]: viewAsComponent })}>
          <Formik
            initialValues={{
              description: customer ? customer.description : "",
              name: customer ? customer.name : "",
              region: customer?.region || regionTypes["unknown"],
              contactPerson: customer ? customer.contactPerson : "",
              category: customer ? customer.category : customerCategories.residential,
              enableAPI: customer ? customer.enableAPI : false,
              CRMReferenceID: customer ? customer.CRMReferenceID : "",
              ownerCA: customer ? customer.ownerCA : "",
              ownerCS: customer ? customer.ownerCS : "",
            }}
            enableReinitialize={true}
            onSubmit={!customerId ? onCreateCustomer : saveCustomer}
            validationSchema={validationSchema}
          >
            {(formProps) => {
              const {
                handleSubmit,
                handleReset
              } = formProps;
              return (
                <form onSubmit={handleSubmit} style={{ backgroundColor: "white" }}>
                  {!viewAsComponent && <div className={style.customerDetailsHeader}>
                    <Typography className={style.editCustomerTitle}>
                      {t`Customer Details`}
                    </Typography>
                    <Typography className={style.errorMessage}>{error}</Typography>
                    {isSubmitting && (
                      <CircularProgress className={style.loader} />
                    )}
                    {customerId && !editable && (
                      <IconButton onClick={() => setEditable(true)}>
                        <img src={EditIcon} alt="edit" />
                      </IconButton>
                    )}
                    {customerId && editable && (
                      <div style={{ display: "flex" }}>
                        <IconButton type="submit" disabled={isSubmitting}>
                          <Check className={style.customerDetailsIcons} />
                        </IconButton>
                        <IconButton
                          disabled={isSubmitting}
                          onClick={() => {
                            handleReset();
                            resetPackages();
                            setEditable(false);
                          }}
                        >
                          <Close className={style.customerDetailsIcons} />
                        </IconButton>
                      </div>
                    )}
                  </div>}
                  <div className={style.customerDetailsFieldsContainer}>
                    <AdvancedInputField
                      name="name"
                      label={t`Name`}
                      editable={editable}
                      {...formProps}
                    />

                    {customerId && <div>
                      <Typography className={style.rowItem} onClick={() =>
                        props.history.push(`/customers/${customer.id}/sites`)
                      } >
                        {t`Sites`} (<span className={style.rowDetails}>{customer?.numOfSites}</span>)
                      </Typography>

                      <Typography className={style.rowItem} onClick={() => {
                        if (showAllPages || mySupportedPages["devices"]) {
                          props.history.push(`/devices`)
                          setSelections({ customerId: customer.id, siteId: null, systemId: null, unitId: null });
                        }
                      }}>
                        {t`Devices`} (<span className={style.rowDetails}>{customer?.numOfDevices}</span>)
                      </Typography>

                      <Typography className={style.rowItem} onClick={() => {
                        if (showAllPages || mySupportedPages["users2"]) {
                          props.history.push(`/users2`)
                          setSelections({ customerId: customer.id, siteId: null, systemId: null, unitId: null });
                        }
                      }}>
                        {t`Users`} (<span className={style.rowDetails}>{customer?.numOfUsers}</span>)
                      </Typography>

                      <Typography className={style.rowItem} onClick={() => {
                        if (showAllPages || mySupportedPages["subscriptions-site"]) {
                          props.history.push(`/subscriptions-site`)
                          setSelections({ customerId: customer.id, siteId: null, systemId: null, unitId: null });
                        }
                      }}>
                        {t`Subscriptions`} (<span className={style.rowDetails}>{Object.keys(subscriptions).length}</span>)
                      </Typography>
                    </div>}

                    <AdvancedInputField
                      name="description"
                      label={t`Description`}
                      editable={editable}
                      {...formProps}
                    />
                    <AdvancedInputField
                      name="contactPerson"
                      label={t`Internal Sales Contact person`}
                      editable={editable}
                      {...formProps}
                    />
                    <AdvancedInputField
                      name="CRMReferenceID"
                      label={t`CRM Reference`}
                      editable={editable}
                      {...formProps}
                    />
                    <AdvancedInputField
                      select
                      name="region"
                      options={regionOptions}
                      label={t`Region`}
                      editable={editable}
                      {...formProps}
                    />
                    <AdvancedInputField
                      select
                      name="category"
                      options={customerCategoriesOptions}
                      label={t`Category`}
                      editable={editable}
                      {...formProps}
                    />
                    <AdvancedInputField
                      toggle
                      name="enableAPI"
                      label={t`Enable API`}
                      editable={editable}
                      {...formProps}
                    />
                    <AdvancedInputField
                      select
                      name="ownerCA"
                      label={t`Owner CA`}
                      options={adminOptions}
                      editable={editable}
                      {...formProps}
                    />
                    <AdvancedInputField
                      select
                      name="ownerCS"
                      label={t`Owner CS`}
                      options={adminOptions}
                      editable={editable}
                      {...formProps}
                    />
                    {customerId && <TreeView
                      disableSelection={!editable}
                      defaultExpanded={["Professional"]}
                      defaultCollapseIcon={<><div className={style.arrowContainer} /> <ArrowDownO className={style.arrow} /> </>}
                      defaultExpandIcon={<><div className={style.arrowContainer} /> <ArrowDownO className={clsx(style.arrow, style.rotateArrow)} /></>}
                      defaultEndIcon={<div className={style.arrowContainer} />}
                    >
                      <TreeItem nodeId="Control"
                        label={
                          <div className={style.labelRoot}>
                            <Checkbox1
                              checked={updatedPackageData["hvacControl"]}
                              onChange={() => handlePackageChange(updatedPackageData["hvacControl"], "control", "hvacControl")}
                            />
                            <Typography variant="body2" className={style.labelText}>
                              {packagesMirrore["hvacControl"]}
                            </Typography>
                          </div>
                        } />
                      <TreeItem nodeId="Professional"
                        label={
                          <div className={style.labelRoot}>
                            <Typography variant="body2" className={style.labelText}>
                              {t`Professional Packages`}
                            </Typography>
                          </div>
                        } >
                        {
                          Object.entries(packagesMirrore).filter(([key, name]: [string, string]) => key !== "hvacControl").map(([key, name]: [string, string]) => {
                            return <TreeItem nodeId={key}
                              label={
                                <div className={style.labelRoot}>
                                  <Checkbox1
                                    checked={updatedPackageData[key]}
                                    onChange={() => handlePackageChange(updatedPackageData[key], "professional", key)}
                                  />
                                  <Typography variant="body2" className={style.labelText}>
                                    {name}
                                  </Typography>
                                </div>
                              } />
                          })
                        }
                      </TreeItem>
                    </TreeView>}

                    {customerId ? "" :
                      <div className={style.saveBtn}>
                        <Button
                          title={t`save customer`}
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ width: "7rem" }}
                          disabled={isSubmitting}
                        >
                          {viewAsComponent ? t`Next` : t`Save`}
                        </Button>
                      </div>
                    }
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>

        <Dialog
          open={isOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title" className={style.title}>
            <div className={style.titleContent}>
              <Typography variant="h6">{t`Add site`}</Typography>
              <IconButton onClick={handleCloseDialog}>
                <Clear />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className={style.dialogContent}>
            <Site
              handleCloseDialog={handleCloseDialog}
              {...props}
            />
          </DialogContent>
        </Dialog>
      </div>
    </ServiceNavigationBar >
  );
};

export default EditCustomer;
