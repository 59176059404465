import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { TextField } from "@mui/material";
import { GlobalAdmin as sdkGlobalAdmin } from "coolremote-sdk";
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import CoolButton from "../../cool_widgets/Button";
import { Arrow as SvgArrow, Close } from "../../icons";
import { IBasicCustomerMap } from "../../models/CustomerModel";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { UserSchema } from "../../models/UsersModel";
import ErrorBox from "../../widgets/ErrorBox/ErrorBox";
import useStyles from "./AddEditUser.style";
import { GlobalAdmin as SdkGlobalAdmin } from "coolremote-sdk"
import { Switch as CoolSwitch } from "../../cool_widgets/Switch";
import { TabContext, TabPanel } from "@mui/lab";
import { PermissionsTree } from "../../components/PermissionsTree";

interface IProps {
  onSave?: () => void;
  onUpdate?: (data: any, id: string) => void;
  onClose: () => void;
  user?: any;
  mainButtonLabel: string;
  withPasswordField?: boolean;
  customers: IBasicCustomerMap;
  customerIdLockedto?: any;
  errorText?: string;
  selectedCustomer?: string;
  viewAsComponent?: boolean;
  setChoseUser?: any;
  showPermissions?: boolean;
}

const timeFormatI = [
  {
    name: "iso8601_24Hours",
    text: "24 hours",
    value: 0
  },
  {
    name: "standard12Hours",
    text: "12 hours",
    value: 1
  }

];
const dateFormatI = [
  {
    name: "startWithDay",
    text: "DD/MM/YY",
    value: 0
  },
  {
    name: "startWithMonth",
    text: "MM/DD/YY",
    value: 1
  }
];

const UserSchemaNew = Yup.object().shape({
  firstName: Yup.string().required(t`Required`).max(20, t`Too long!`),
  lastName: Yup.string().required(t`Required`).max(20, t`Too long!`),
  email: Yup.string().email(t`Invalid email`).required(t`Required`),
  phone: Yup.string()
    .test(
      "phone validation",
      t`Phone number should be 10 chars minimum.`,
      function (value) {
        if (!value) {
          return true;
        }

        let newValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
        newValue = newValue.replace(/\s/g, "");

        if (newValue.length > 9 || newValue.length === 0) {
          return true;
        }
        return false;
      }
    ),
  password: Yup.string()
    .matches(
      /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      t`must be at least 8+ characters, 1 capital, 1 number`
    )
    .required(t`Required`),
  timeFormat: Yup.number(),
  dateFormat: Yup.number(),
})

const PasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t`must be at least 8 chars and contain 1 capital letter and 1 digit`)
    .notRequired()
    .when("password", {
      is: (val: any) => val !== undefined,
      then: Yup.string().required(t`Required`)
    }),
  repeatPassword: Yup.string()
    .when("newPassword", {
      is: (val: any) => val !== undefined && val !== "",
      then: Yup.string().required(t`Required`)
        .oneOf([Yup.ref("newPassword"), null], t`Passwords must match`)
    })
});

export default function AddEditUser(props: IProps) {
  const {
    user,
    mainButtonLabel,
    withPasswordField,
    onClose,
    onSave,
    onUpdate,
    errorText,
    selectedCustomer = "",
    viewAsComponent,
    setChoseUser,
    showPermissions = true
  } = props;

  const {
    id,
    firstName = "",
    lastName = "",
    username = "",
    email = "",
    phone = "",
    timeFormat = 0,
    dateFormat = 0,
    temperatureScale: tempScale = 1,
    measurementUnits: MUnits = 1,
  } = user || {};



  const styles: any = useStyles();
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [measurementUnits, setMeasurementUnits] = useState(MUnits);
  const [temperatureScale, setTemperatureScale] = useState(tempScale);
  const [limitFreeSites, setLimitFreeSites] = useState<boolean>(user?.limitFreeSites || false);
  const [numOfFreeSites, setNumOfFreeSites] = useState<number>(user?.numOfFreeSites ?? 2);
  const [permCustomer, setPermCustomer] = useState<any>("");
  const [selectedTab, setSelectedTab] = useState<'profile' | 'permissions'>('profile');
  const [userCustomers, setUserCustomers] = useState<any>([]);
  const [userCustomerPolicies, setUserCustomerPolicies] = useState<any>({});
  const [userPolsOrg, setUserPolsOrg] = useState<any>([]);
  const [customerAdminRole, setCustomerAdminRole] = useState<any>("");
  const updatePassword = useStoreActions((action) => action.users.updatePassword);
  const allCustomers = useStoreState((state) => state.customers.customersBasicList);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const [is2FA, setIs2FA] = useState<boolean>(user?.is2FA || false);
  const isEdit = !!user;

  const passwordBox = passwordError ? (
    <ErrorBox error={passwordError} onClose={() => setPasswordError("")} />
  ) : null;


  useEffect(() => {
    if (!user?.policies?.length || _.isEmpty(allCustomers)) {
      return;
    }

    const customers = user?.policies.reduce((data: any, policy: any) => {
      if (data.indexOf(policy.entityId) > -1) {
        return data;
      }
      data.push(policy.customer)
      return data;
    }, [])

    setUserCustomers(customers)

  }, [user, allCustomers])

  useEffect(() => {
    SdkGlobalAdmin.getRoles()
      .then((resp: any) => {
        const filtered: any = Object.values(resp || {}).filter((role: any) => role.name === "Customer Admin")
        if (filtered?.length) {
          setCustomerAdminRole(filtered[0]?.id)
        }
      })
  }, []);


  useEffect(() => {
    if (!permCustomer) {
      return;
    }

    SdkGlobalAdmin.getUserFullPolicies(user.id, permCustomer)
      .then((resp: any) => {
        const pols: any = resp?.policies?.reduce((data: any, item: any) => {
          const { entityId, level, application, role, expiryTimestamp } = item;
          if (data[entityId]) {
            data[entityId][application] = { entityId, level, application, role, expiryTimestamp };
            return data;
          }
          data[entityId] = {
            [application]: { entityId, level, application, role, expiryTimestamp }
          };
          return data;
        }, {});
        setUserCustomerPolicies(pols);
        setUserPolsOrg(resp.policies)
      })

  }, [permCustomer])

  const onPasswordChangeSubmit = (values: any) => {
    if (values.newPassword && values.repeatPassword) {
      const data = { password: values.newPassword };
      updatePassword({ userId: user.id, data })
        .then(() => closePasswordChange())
        .catch((e: any) => setPasswordError(e.message));
    }
  };

  const closePasswordChange = () => {
    setPasswordOpen(false);
  };

  const onSubmit = (values: any) => {

    const { ...userInfo } = values;

    const user = { ...userInfo, username: userInfo.email, temperatureScale, measurementUnits, limitFreeSites, numOfFreeSites };
    if (isEdit) {
      delete user.password
      user.is2FA = is2FA;
      onUpdate && onUpdate(id, user)
      return;
    } else {
      SdkGlobalAdmin.createAppUser(user)
        .then((userResp: any) => {
          const policies = []
          const policy = {
            role: customerAdminRole,//"630b515742f21d28af8143cd", //Customer Admin
            level: 1,
            entityId: selectedCustomer,
            application: "control"
          }

          policies.push({ ...policy })
          policies.push({ ...policy, application: "professional" })

          return SdkGlobalAdmin.createPolicies({ user: userResp.id, policies, customer: selectedCustomer })
        })
        .then(() => {
          onClose();
          onSave && onSave();
        })
        .catch((err: any) => addMessage({ message: err.message }));
    }
  };

  const updatePolicies = () => {
    const policiesToDel = userPolsOrg.map((p: any) => p.id || p._id);

    const arrPolicies: any = Object.values(userCustomerPolicies).reduce((data: any, entity: any) => {
      const apps: any = Object.values(entity);

      if (apps !== -1) {
        data.push(...apps);
      }
      return data;
    }, []);

    if (!arrPolicies?.length) {
      addMessage({ message: t`Please add policies to the user before you update` });
      return;
    }

    const data = {
      customer: permCustomer,
      user: user.id,
      policies: arrPolicies
    };

    if (!arrPolicies?.length) {
      addMessage({ message: t`Please add policies to the user` });
      return;
    }

    policiesToDel?.length && sdkGlobalAdmin.adminDeleteMultiPolicy(policiesToDel)
      .then(() => {
        return sdkGlobalAdmin.createPolicies(data);

      })
      .finally(async () => {
        onClose();
      });
  }

  return (
    <>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={(e, val) => setSelectedTab(val)} variant="fullWidth">
            <Tab label={t`Profile`} value="profile" />
            {showPermissions && <Tab label={t`Permissions`} value="permissions" />}
          </Tabs>
        </Box>
        <TabPanel value={'profile'}>
          <Formik
            initialValues={{
              firstName,
              lastName,
              email,
              username,
              phone,
              password: "",
              timeFormat,
              dateFormat,
            }}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={user ? UserSchema : UserSchemaNew}
            autoComplete="off"
          >
            {(props) => {
              const { values, touched, errors, handleChange, handleSubmit, setFieldValue }: any = props;
              const { policies } = user || { policies: [{}] };
              const { role, application } = policies[0] || {};
              //only if the user has a single policy that is customer admin for control app
              const enableProButton = user?.policies?.length === 1 && role === "630b515742f21d28af8143cd" && application === "control"
              return (
                <form className={styles.fieldsContainer} onSubmit={handleSubmit}>
                  <div className={styles.twoFieldsRow}>

                    <TextField
                      label={t`First name`}
                      name="firstName"
                      helperText={errors.firstName || t`up to 20 characters`}
                      className={styles.smallField}
                      variant={'standard'}
                      onChange={handleChange}
                      value={values.firstName || ""}
                      error={errors.firstName}
                      {...props}
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                        maxLength: 20
                      }}
                    />
                    <TextField
                      className={styles.smallField}
                      label={t`Last name`}
                      name="lastName"
                      helperText={errors.lastName || t`up to 20 characters`}
                      onChange={handleChange}
                      variant={'standard'}
                      value={values?.lastName || ""}
                      error={errors?.lastName}
                      {...props}
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                        maxLength: 20
                      }}
                    />
                  </div>
                  <TextField
                    label={user ? t`Email Address` : t`Username/Email`}
                    name="email"
                    helperText={errors.email || t`valid email address`}
                    onChange={handleChange}
                    variant={'standard'}
                    value={values.email || ""}
                    error={errors.email}
                    className={styles.newBlock}
                    {...props}
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      }
                    }}
                  />
                  {user && <TextField
                    label={t`Username`}
                    name="username"
                    id={"test"}
                    helperText={errors.username || t`6-20 characters, must be unique`}
                    onChange={handleChange}
                    variant={'standard'}
                    value={values.username || ""}
                    error={errors.username}
                    className={styles.newBlock}
                    {...props}
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      }
                    }}
                  />}


                  {withPasswordField && (
                    <TextField
                      label={t`Password`}
                      name="password"
                      helperText={errors.password || t`8+ characters, 1 capital, 1 number`}
                      error={errors.password}
                      value={values.password || ""}
                      variant={'standard'}
                      onChange={handleChange}
                      className={styles.newBlock}
                      type="password" //to prevent auto fill
                      autoComplete="off"
                      {...props}
                    />
                  )}

                  <InputLabel className={clsx(styles.fieldLabel, styles.newBlock)}>
                    <MuiPhoneNumber
                      value={values.phone || ""}
                      label={t`Phone number`}
                      name="phone"
                      inputClass={clsx(styles.fieldStyle, styles.phoneStyle)}
                      error={errors.phone}
                      defaultCountry={"us"}
                      onChange={(e: any) => setFieldValue("phone", e)}
                      InputProps={{
                        disableUnderline: true,
                        classes: { underline: styles.error }
                      }}
                    />
                    <Typography
                      className={
                        errors.phone && touched.phone ? styles.error : styles.helper
                      }
                    >
                      {errors.phone && touched.phone
                        ? errors.phone
                        : t`International format telephone number`
                      }
                    </Typography>
                  </InputLabel>
                  <div className={styles.twoFieldsRow}>
                    <InputLabel className={clsx(styles.fieldLabel, styles.tempField)} >
                      {t`Temperature scale`}
                      <div>
                        ℃
                        <Switch
                          color="default"
                          onChange={() => {
                            setTemperatureScale(temperatureScale !== 2 ? 2 : 1);
                          }}
                          checked={temperatureScale === 2}
                          name="temperatureScale"
                        />
                        ℉
                      </div>
                    </InputLabel>
                    <InputLabel className={clsx(styles.fieldLabel, styles.tempField)} >
                      {t`Measurement Units`}
                      <div>
                        {t`kg/cm2`}

                        <Switch
                          color="default"
                          onChange={() => {
                            setMeasurementUnits(measurementUnits !== 2 ? 2 : 1);
                          }}
                          checked={measurementUnits === 2}
                          name="measurementUnits"
                        />
                        {t`PSI`}

                      </div>
                    </InputLabel>
                  </div>

                  <div className={styles.twoFieldsRow}>
                    <InputLabel className={clsx(styles.fieldLabel, styles.smallField)} >
                      {t`Time Format`}
                      <Select
                        displayEmpty
                        disabled={false}
                        value={values.timeFormat ? values.timeFormat : 0}

                        onChange={(event: any) => {
                          setFieldValue("timeFormat", event.target.value);
                        }}
                        variant="outlined"
                        className={styles.formats}
                      >
                        {<MenuItem value={""} disabled style={{ display: "none" }}>{t`12 hours`}</MenuItem>}
                        {timeFormatI.map((formatObj: any) => {
                          return <MenuItem key={formatObj.value} value={formatObj.value} >{formatObj.text}</MenuItem>;
                        }
                        )}
                      </Select >
                    </InputLabel>
                    <InputLabel className={clsx(styles.fieldLabel, styles.smallField)} >
                      {t`Date Format`}
                      <Select
                        displayEmpty
                        disabled={false}
                        value={values.dateFormat ? values.dateFormat : 0}
                        onChange={(event: any) => {
                          setFieldValue("dateFormat", event.target.value);
                        }}
                        variant="outlined"
                        className={styles.formats}
                      >
                        {<MenuItem value={""} disabled style={{ display: "none" }}>DD/MM/YY</MenuItem>}
                        {dateFormatI.map((formatObj: any) => {
                          return <MenuItem key={formatObj.value} value={formatObj.value} >{formatObj.text}</MenuItem>;
                        }
                        )}

                      </Select >
                    </InputLabel>
                  </div>
                  {enableProButton && <div className={styles.newBlock}>
                    <div className={styles.thirdButton} onClick={() => {
                      const policies = [{
                        role: "630b515742f21d28af8143cd", //Customer Admin
                        level: 1,
                        entityId: selectedCustomer,
                        application: "professional"
                      }]

                      SdkGlobalAdmin.createPolicies({ user: user.id, policies, customer: selectedCustomer }).then((aaa: any) => {
                        user.policies.push(policies[0])
                        setChoseUser({ ...user })
                      })
                        .catch((err: any) => addMessage({ message: err.message }));
                    }}>
                      <Typography className={styles.thirdButtonTitle}>{t`Open professional app for user`}</Typography>
                      <SvgArrow />
                    </div>
                  </div>}


                  {user && <div className={styles.newBlock}>
                    <InputLabel className={clsx(styles.fieldLabel, styles.authField)} >
                      {t`Enable email 2-factor-authentication`}
                      <div>
                        {t`Disable`}
                        <CoolSwitch
                          color="default"
                          onChange={() => {
                            setIs2FA(!is2FA);
                          }}
                          checked={is2FA}
                          name="2FA"
                        />
                        {t`Enable`}
                      </div>
                    </InputLabel>
                  </div>}

                  {user && <div className={styles.newBlock}>
                    <div className={styles.thirdButton} onClick={() => setPasswordOpen(!passwordOpen)}>
                      <Typography className={styles.thirdButtonTitle}>{t`Change Password`}</Typography>
                      <SvgArrow />
                    </div>
                  </div>}

                  {!!errorText && (
                    <Typography className={styles.error}>{errorText}</Typography>
                  )}
                  <div className={styles.actionsContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onClose}
                      className={styles.cancelBtn}
                    >
                      {viewAsComponent ? t`Back` : t`Cancel`}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={styles.submitButton}
                    >
                      {mainButtonLabel}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </TabPanel>
        <TabPanel
          value={'permissions'}
          style={{
            display: selectedTab == 'permissions' ? 'flex' : "none",
            flexDirection: 'column',
            minHeight: '700px'
          }}
        >
          <Select
            value={permCustomer}
            onChange={(e: any) => setPermCustomer(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">{t`Select a customer`}</MenuItem>
            {
              userCustomers?.map((id: any) => {
                return <MenuItem value={id} key={`option-${id}`}>{allCustomers[id]?.name}</MenuItem>
              })
            }

          </Select>
          {permCustomer &&
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                overflow: 'hidden'
              }}
            >
              <PermissionsTree
                policies={userCustomerPolicies}
                setPolicies={setUserCustomerPolicies}
                customer={permCustomer}
              />
              <div className={styles.actionsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.submitButton}
                  onClick={updatePolicies}
                >
                  {t`Update permissions`}
                </Button>
              </div>
            </div>
          }
        </TabPanel>
      </TabContext>
      {
        passwordOpen && <Dialog
          open={true}
          onClose={closePasswordChange}
          maxWidth="xs"
          fullWidth
        >
          <div className={styles.dialogHeader}>
            <Typography className={styles.headerTitle}>{t`Change Password`}</Typography>
            <IconButton disableRipple className={styles.iconBtnStyle} onClick={closePasswordChange}>
              <Close color="#7f7692" />
            </IconButton>
          </div>
          <Formik
            initialValues={{ newPassword: "", repeatPassword: "" }}
            onSubmit={onPasswordChangeSubmit}
            enableReinitialize={true}
            validationSchema={PasswordSchema}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {(props) => {
              const { errors, handleSubmit, handleChange }: any = props;
              return (
                <form onSubmit={handleSubmit} className={styles.dialogContent}>
                  <div className={styles.PasswordChangeContent}>

                    <TextField
                      label={t`New Password`}
                      name="newPassword"
                      error={errors.newPassword}
                      helperText={
                        errors.newPassword
                          ? errors.newPassword
                          : t`8+ characters, 1 capital, 1 number`
                      }
                      variant='standard'
                      fullWidth
                      type="password"
                      onChange={handleChange}
                      {...props}
                    />
                    <TextField
                      label={t`Confirm New Password`}
                      name="repeatPassword"
                      error={errors.repeatPassword}
                      helperText={
                        errors.repeatPassword
                          ? errors.repeatPassword
                          : t`8+ characters, 1 capital, 1 number`
                      }
                      variant="standard"
                      type="password"
                      fullWidth
                      onChange={handleChange}
                      {...props}
                    />

                    <div className={styles.actionsContainer}>
                      {passwordBox}
                      <CoolButton
                        onClick={closePasswordChange}
                        width={150}
                        white
                        marginRight
                      >
                        {t`Cancel`}
                      </CoolButton>
                      <CoolButton
                        type="submit"
                        width={150}
                      // disabled={!dirty}

                      >
                        {t`Save Password`}
                      </CoolButton>
                    </div>
                  </div>

                </form>
              );
            }}
          </Formik>

        </Dialog>
      }
    </>
  );
}
