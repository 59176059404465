import {
    Button,
    TextField
} from "@mui/material";
import { Formik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import useStyles from "./Device.style";
import { GlobalAdmin as sdkGlobalAdmin } from "coolremote-sdk";

const DeviceSchema = Yup.object().shape({
    startSerial: Yup.string().required(t`Required`),
    numOfDevices: Yup.string().required(t`Required`),
    deviceFWversion: Yup.string().required(t`Required`),
    devicebootloaderVersion: Yup.string().required(t`Required`),
    devicelines: Yup.string().required(t`Required`),
    devicesecurity: Yup.string().required(t`Required`),
    devicePCBversion: Yup.string().required(t`Required`),
    deviceCable: Yup.string().required(t`Required`),

    // numOfDevices: Yup.number().required(t`Required`),
    // deviceFamily: Yup.string().required(t`Required`),
    // customerName: Yup.string().required(t`Required`),
    // customerComments: Yup.string(),
    // customerCompanyName: Yup.string(),
    // customerLocation: Yup.string(),
    // customerCountry: Yup.string(),
    // shipmentDate: Yup.string(),
    // shipmentType: Yup.string(),
    // shipmentTrackingNumber: Yup.string(),
    // devicePSU: Yup.string(),
    // coolRemoteId: Yup.string(),
    // customerEmail: Yup.string().email(),

})

export default function ProductionData(props: any) {
    const { onCancel, onApply } = props;
    const styles: any = useStyles();
    const [error, setError] = useState<any>(null);

    const onSubmit = (values: any) => {
        sdkGlobalAdmin.updateDeviceRecords(values)
            .catch((err: any) => { setError(err) })
            .finally(onApply)
    }

    return (
        <div>
            <SimpleModal
                showModal={!!error}
                title={t`Error had happened`}
                contentText={error ? error : ""}
                actionName={t`OK`}
                onAction={() => {
                    setError(null);
                }}
                closeModal={() => {
                    setError(null);
                }}
            />

            <Formik
                initialValues={{
                    numOfDevices: 1
                }}
                enableReinitialize={true}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={DeviceSchema}
                autoComplete="off"
            >
                {(props) => {
                    const { values, errors, handleChange, handleSubmit }: any = props;
                    return (
                        <form className={styles.fieldsContainer} onSubmit={handleSubmit}>
                            <div className={styles.twoFieldsRow}>
                                <TextField
                                    label={t`start Serial`}
                                    id="startSerial"
                                    helperText={errors.startSerial}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.startSerial || ""}
                                    error={errors.startSerial}
                                    {...props}
                                />
                                <TextField
                                    label={t`num Of Devices`}
                                    id="numOfDevices"
                                    helperText={errors.numOfDevices}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.numOfDevices || ""}
                                    error={errors.numOfDevices}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    {...props}
                                />
                            </div>

                            <div className={styles.twoFieldsRow}>
                                <TextField
                                    label={t`Device FW version`}
                                    id="deviceFWversion"
                                    helperText={errors.deviceFWversion}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.deviceFWversion || ""}
                                    error={errors.deviceFWversion}
                                    {...props}
                                />
                                <TextField
                                    label={t`Device boot loader Version`}
                                    id="devicebootloaderVersion"
                                    helperText={errors.devicebootloaderVersion}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.devicebootloaderVersion || ""}
                                    error={errors.devicebootloaderVersion}
                                    {...props}
                                />
                            </div>

                            <div className={styles.twoFieldsRow}>
                                <TextField
                                    label={t`Device Lines`}
                                    id="devicelines"
                                    helperText={errors.devicelines}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.devicelines || ""}
                                    error={errors.devicelines}
                                    {...props}
                                />
                                <TextField
                                    label={t`Device Security`}
                                    id="devicesecurity"
                                    helperText={errors.devicesecurity}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.devicesecurity || ""}
                                    error={errors.devicesecurity}
                                    {...props}
                                />
                            </div>

                            <div className={styles.twoFieldsRow}>
                                <TextField
                                    label={t`Device Cable`}
                                    id="deviceCable"
                                    helperText={errors.deviceCable}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.deviceCable || ""}
                                    error={errors.deviceCable}
                                    {...props}
                                />
                                <TextField
                                    label={t`Device PCB Version`}
                                    id="devicePCBversion"
                                    helperText={errors.devicePCBversion}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.devicePCBversion || ""}
                                    error={errors.devicePCBversion}
                                    {...props}
                                />
                            </div>

                            <div className={styles.actionsContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onCancel}
                                    className={styles.cancelBtn}
                                >
                                    {t`Cancel`}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={styles.submitButton}
                                >
                                    {t`Production data`}
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Formik>

        </div>
    );
}
