import { Account as AccountSdk } from "coolremote-sdk";
import { Thunk, thunk } from "easy-peasy";
import { GlobalAdmin } from "coolremote-sdk";

export interface IAccountsModel {
  getAccounts: Thunk<IAccountsModel>;
  getPoolSubscriptions: Thunk<IAccountsModel>;
  createPoolSubscriptions: Thunk<IAccountsModel, { data: any }>;
  updatePoolSubscriptions: Thunk<IAccountsModel, { id: string, data: any }>;
  deletePoolSubscriptions: Thunk<IAccountsModel, string>;
}

export const AccountsModel: IAccountsModel = {
  getAccounts: thunk(async (actions) => {
    return GlobalAdmin.getAccounts();
  }),
  getPoolSubscriptions: thunk(async (actions) => {
    return AccountSdk.getPoolSubscriptions();
  }),
  createPoolSubscriptions: thunk((actions, payload) => {
    return AccountSdk.createPoolSubscriptions(payload.data);
  }),
  updatePoolSubscriptions: thunk((actions, payload) => {
    return AccountSdk.updatePoolSubscriptions(payload.id, payload.data);
  }),
  deletePoolSubscriptions: thunk((actions, payload) => {
    return AccountSdk.deletePoolSubscriptions(payload);
  })
};
