import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { Close as CloseIcon, Delete } from "../../icons";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUserModel } from "../../models/UsersModel";
import ErrorBox from "../../widgets/ErrorBox/ErrorBox";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import AddEditUser from "./AddEditUser";
import useStyles from "./UserManagement.style";
import { GlobalAdmin as SdkGlobalAdmin } from "coolremote-sdk"

export default function UserManagement(props: any) {
  const styles: any = useStyles();
  const selections = useStoreState((state) => state.selections.selections);
  const customersBasicList = useStoreState((state) => state.customers.customersBasicList);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [chosenUser, setChoseUser] = useState<IUserModel | null>(null);
  const [delModal, setDelModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showErr, setShowErr] = useState<string>("");
  const [showModal, toggleModal] = useState(false);
  const [errorOnCreate, setErrorOnCreate] = useState<string>("");
  const [users, setUsers] = useState<any>({});
  const [refresh, setRefresh] = useState<number>(0);
  const [profImpersonatePopup, handleProfImpersonatePopup] = useState<string>("");
  const [allRoles, setAllRoles] = useState<any>({});
  const [entLevels, setEntLevels] = useState<any>({});
  const { customerId } = props.match.params;

  const getSites = useStoreActions((actions) => actions.customers.getCustomerSites);
  const setAllSites = useStoreActions((actions) => actions.sites.setAllSites);
  const getSiteName = useStoreState((state) => state.sites.getSiteName);
  const getCustomerName = useStoreState((state) => state.customers.getCustomerName);
  const [unitsNames, setUnitsNames] = useState<any>({});
  const [groupsNames, setGroupsNames] = useState<any>({});

  useEffect(() => {
    SdkGlobalAdmin.getRoles()
      .then((resp: any) => {
        setAllRoles(resp);
      })
    SdkGlobalAdmin.getEntityLevels()
      .then((resp: any) => {
        setEntLevels(resp);
      })
  }, []);

  useEffect(() => {
    if (!selections?.customerId) {
      setUsers({});
      return;
    }

    SdkGlobalAdmin.getCustomerUsers(selections.customerId)
      .then((resp: any) => {
        setUsers(resp);
      })

    getSites(selections.customerId).then((data: any) => {
      setAllSites(data);
    });

    SdkGlobalAdmin.getCustomerUnitsNames(selections.customerId)
      .then((resp: any) => {
        setUnitsNames(resp);
      })

    SdkGlobalAdmin.getCustomerGroupsNames(selections.customerId)
      .then((resp: any) => {
        setGroupsNames(resp);
      })
  }, [selections?.customerId, refresh])

  const onUpdateUser = async (id: string, data: any) => {
    SdkGlobalAdmin.updateUser(id, data)
      .then(() => {
        setChoseUser(null);
        setEditModal(false);
        setErrorOnCreate("");
        setShowErr("");
        setUsers({ ...users, [id]: { ...users[id], ...data, customer: selections?.customerId } });
      })
      .catch((err: any) => {
        if (err.message === "username already exists" || err.message === "\"email\" must be a valid email") {
          setErrorOnCreate(err.message);
          setShowErr(err.message);
          return;
        }

      })
  };

  const onCreateUser = async () => {
    setRefresh(refresh + 1)
  };

  const onDelete = async () => {
    if (!chosenUser) {
      return onCloseDelModal();
    }

    SdkGlobalAdmin.deleteUserForCustomer(selections?.customerId, chosenUser.id)
      .then(() => {
        const { [chosenUser.id]: toDelete, ...restUsers } = users;
        setUsers(restUsers);
      })
      .catch((err: { message: string; }) => {
        setShowErr(err.message || "something went wrong while deleting User");
      })
      .finally(onCloseDelModal)

  };

  const onCloseDelModal = () => {
    setDelModal(false);
    setChoseUser(null);
  };

  const doImpersonate = (username: any, application: string, allPackages: boolean = false) => {
    profImpersonatePopup && handleProfImpersonatePopup("");
    let hostURLs: any = {};
    const urlsFile = `${process.env.PUBLIC_URL}/assets/${window.location.hostname}/impersonate.config.json`;
    fetch(urlsFile)
      .then((res) => res.json())
      .then((res) => {
        hostURLs = res;
        let env = "";
        if (window.location.origin.indexOf("-dev") !== -1 || window.location.origin.indexOf("localhost") !== -1) {
          env = "dev";
        } else if (window.location.origin.indexOf("-stg") !== -1) {
          env = "stg";
        } else {
          env = "prod";
        }

        const hosturl = hostURLs[env][application].url;

        let appId;
        if (application === "service") {
          appId = "CoolService";
        }
        if (application === "management") {
          appId = "CoolManagement";
        }
        if (application === "control") {
          appId = "coolAutomationControl";
          // maybe will be CoolAutomationControl
        }
        if (application === "professional") {
          appId = "CoolCommercial";
          // maybe will change it to "CoolProfessional"
        }

        SdkGlobalAdmin.getUserToken(username, appId, allPackages)
          .then(({ token }: any) => {
            const url = `https://${hosturl}/impersonate/${token}`;
            const win = window.open(url, "_blank");
            win?.focus();
          });
      })
      .catch((err) => {
        addMessage({ message: err.message });
      });

  };

  const onDelModal = () => {
    const name = chosenUser ? chosenUser.username : "";
    const bodyText = t`are you sure you want to delete this user ` + `${name}`;
    return (
      <SimpleModal
        showModal={delModal}
        title={t`Delete User`}
        contentText={bodyText}
        actionName={t`Delete`}
        onAction={onDelete}
        closeModal={onCloseDelModal}
      ></SimpleModal>
    );
  };

  const onErrorModal = () => {
    if (!showErr.length) {
      return <div />;
    }

    return (
      <ErrorBox
        error={showErr}
        onClose={() => {
          onCloseDelModal();
          setShowErr("");
        }}
      />
    );
  };

  const createUserClose = () => {
    toggleModal(false);
    setErrorOnCreate("");
    setShowErr("");
  }

  const updateUserClose = () => {
    setEditModal(false);
    setChoseUser(null);
    setErrorOnCreate("");
    setShowErr("");
  }

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip placement="top-start" {...props} classes={{ popper: className }} />
  ))(({ theme: any }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      minWidth: 600,
      fontSize: 12,
      border: '1px solid #dadde9'
    },
  }));

  const getGroupName = (entityLevel: any, entityId: any) => {
    return (entityLevel == 5) ? groupsNames[entityId] : "-"
  }

  const getUnitName = (entityLevel: any, entityId: any) => {
    return (entityLevel == 6) ? unitsNames[entityId] : "-"
  }


  const isValidUserPolicies = (user: any) => {
    const matchedPolicy = user.policies.find((policy: any) => getCustomerName(policy.customer) && getSiteName(policy.site) && getUnitName(policy.level, policy.entityId) && getGroupName(policy.level, policy.entityId));
    return !!matchedPolicy;
  }

  const isValidPolicy = (policy: any) => {
    return getCustomerName(policy.customer) && getSiteName(policy.site) && getUnitName(policy.level, policy.entityId) && getGroupName(policy.level, policy.entityId)
  }

  return (
    <>
      <ServiceNavigationBar
        title={t`Users Management`}
        filters={<SelectionsMenu hideSiteSelection hideUnitSelection hideSystemSelection />}
        {...props}
      >
        {onErrorModal()}
        {onDelModal()}
        <div className={styles.addButtonContainer}>
          <Button
            className={styles.buttonStyle}
            onClick={() => toggleModal(true)}
            disabled={!selections?.customerId}
          >{t`ADD NEW User`}</Button>
        </div>
        {
          !selections.customerId && !customerId ?
            (<Grid container direction={"column"} className={styles.noContentContainer}>
              <div className={styles.grayOval}>
                <ArrowIcon className={styles.arrowIcon} />
              </div>
              <Typography>
                {t`Please select a customer using the above filters or search for user.`}
              </Typography>
            </Grid>) :
            <>
              <Paper elevation={0} className={styles.paperPadding}>
                <TableContainer>
                  <Table stickyHeader className="" aria-label="customized table">
                    <TableHead className={styles.tableHead}>
                      <TableRow>
                        <TableCell className={styles.headCells}>
                          <div className={styles.headContainer}>
                            {t`USERNAME`}
                          </div>

                        </TableCell>
                        <TableCell className={styles.headCells}>
                          <div className={styles.headContainer}>
                            {t`FIRST NAME`}
                          </div>
                        </TableCell>
                        <TableCell className={styles.headCells}>
                          <div className={styles.headContainer}>
                            {t`LAST NAME`}
                          </div>
                        </TableCell>
                        <TableCell className={styles.headCells}>{t`EMAIL`}</TableCell>

                        <TableCell className={styles.headCells}>
                          <div className={styles.headContainer}>
                            {t`ROLE`}
                          </div>
                        </TableCell>
                        <TableCell
                          className={styles.headCells}
                        >
                          <div className={styles.headContainer}>
                            {t`Impersonate Control`}
                          </div>
                        </TableCell>
                        <TableCell
                          className={styles.headCells}
                        >
                          <div className={styles.headContainer}>
                            {t`Impersonate Professional`}
                          </div>
                        </TableCell>

                        <TableCell
                          align="center"
                          className={styles.headCells}
                        >{t`DELETE`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.orderBy(Object.values(users), [(user: any) => user.username.toLowerCase()], ["asc"]).map((user: any) => {
                        if (isValidUserPolicies(user)) {
                          return (
                            <TableRow hover tabIndex={-1} key={`${user.id}`} onDoubleClick={() => {
                              setEditModal(true);
                              setChoseUser(user);
                            }}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={styles.rowCell}
                              >
                                {user.username}
                              </TableCell>

                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={styles.rowCell}
                              >
                                {user.firstName}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={styles.rowCell}
                              >
                                {user.lastName}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={styles.rowCell}
                              >
                                {user.email || ""}
                              </TableCell>

                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={styles.rowCell}
                              >
                                <HtmlTooltip
                                  title={
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className={styles.tooltipHeadCells}>
                                            {t`Role`}
                                          </TableCell>
                                          <TableCell className={styles.tooltipHeadCells}>
                                            {t`Application`}
                                          </TableCell>
                                          <TableCell className={styles.tooltipHeadCells}>
                                            {t`Customer`}
                                          </TableCell>
                                          <TableCell className={styles.tooltipHeadCells}>
                                            {t`Site`}
                                          </TableCell>
                                          <TableCell className={styles.tooltipHeadCells}>
                                            {t`Group`}
                                          </TableCell>
                                          <TableCell className={styles.tooltipHeadCells}>
                                            {t`Unit`}
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {user.policies.map((policy: any, policyIdx: number) => {
                                          const roleName = allRoles[policy?.role]?.name;
                                          if (isValidPolicy(policy)) {
                                            return (
                                              <TableRow key={`${user.id}-${policy.id}`}>
                                                <TableCell
                                                  scope="row"
                                                  align="left"
                                                  className={styles.tooltipRowCell}
                                                >
                                                  {roleName}
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  align="left"
                                                  className={styles.tooltipRowCell}
                                                >
                                                  {`${policy.application} application`}
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  align="left"
                                                  className={styles.tooltipRowCell}
                                                >
                                                  {getCustomerName(policy.customer)}
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  align="left"
                                                  className={styles.tooltipRowCell}
                                                >
                                                  {getSiteName(policy.site)}
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  align="left"
                                                  className={styles.tooltipRowCell}
                                                >
                                                  {getGroupName(policy.level, policy.entityId)}
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  align="left"
                                                  className={styles.tooltipRowCell}
                                                >
                                                  {getUnitName(policy.level, policy.entityId)}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          }
                                        })}
                                      </TableBody>
                                    </Table>
                                  }
                                >
                                  <div>
                                    {Object.keys(_.groupBy(user.policies, 'role')).map((policyG: any, policyIdx: number) => {
                                      const roleGName = allRoles[policyG]?.name;
                                      const isLast = Object.keys(_.groupBy(user.policies, 'role')).length - 1 === policyIdx;
                                      return (
                                        <span key={policyG}> {isLast ? `${roleGName}` : `${roleGName},`} </span>
                                      )
                                    })}
                                  </div>
                                </HtmlTooltip>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={styles.rowCell}
                              >
                                <Button
                                  className={clsx([styles.buttonStyle, styles.impersonateButton])}
                                  onClick={() => doImpersonate(user.username, "control")}
                                >{t`control`}</Button>

                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={styles.rowCell}
                              >
                                <Button
                                  className={clsx([styles.buttonStyle, styles.impersonateButton])}
                                  onClick={() => handleProfImpersonatePopup(user.username)}
                                >{t`professional`}</Button>

                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                                className={styles.rowCell}
                              >
                                <IconButton
                                  onClick={() => {
                                    setDelModal(true);
                                    setChoseUser(user);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
        }
      </ServiceNavigationBar >
      {showModal && selections?.customerId && (
        <SimpleModal showModal={true} title={t`Create User`} hideBtns closeModal={createUserClose} fullWidth={true}>
          <IconButton disableRipple className={styles.iconBtnStyle} onClick={createUserClose}>
            <Close />
          </IconButton>
          <AddEditUser
            withPasswordField
            onSave={onCreateUser}
            onClose={createUserClose}
            mainButtonLabel={t`Create`}
            customers={customersBasicList}
            errorText={errorOnCreate}
            selectedCustomer={selections?.customerId}
            showPermissions={false}
          />
        </SimpleModal>
      )}
      {
        editModal && chosenUser && (
          <SimpleModal showModal={true} title={t`Update User info`} hideBtns closeModal={updateUserClose} fullWidth={true}>
            <IconButton disableRipple className={styles.iconBtnStyle} onClick={updateUserClose}>
              <Close />
            </IconButton>
            <AddEditUser
              user={chosenUser}
              onUpdate={onUpdateUser}
              onClose={updateUserClose}
              mainButtonLabel={t`Update`}
              customers={customersBasicList}
              errorText={errorOnCreate}
              setChoseUser={setChoseUser}
              selectedCustomer={selections?.customerId || ""}
            />
          </SimpleModal>
        )
      }
      {
        !!profImpersonatePopup &&
        <Dialog
          open={true}
          onClose={() => handleProfImpersonatePopup("")}
          classes={{ paper: styles.dialog }}
        >
          <div className={styles.dialogHeader}>
            <Typography className={styles.headerTitle}>{`Impersonate Packages`}</Typography>
            <IconButton className={styles.closeIconBtn} onClick={() => handleProfImpersonatePopup("")}><CloseIcon color="#7f7692" /></IconButton>
          </div>
          <Button
            className={clsx([styles.buttonStyle, styles.impersonateButton, styles.btnMargin])}
            onClick={() => doImpersonate(profImpersonatePopup, "professional")}
          >{t`User packages`}</Button>
          <Button
            className={clsx([styles.buttonStyle, styles.impersonateButton, styles.btnMargin])}
            onClick={() => doImpersonate(profImpersonatePopup, "professional", true)}
          >{t`All packages`}</Button>

        </Dialog>
      }
    </>
  );
}
