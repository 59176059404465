import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { t } from "ttag";
import LogOut from "../LogOut/LogOut";
import style from "./UserMenu.module.css";

export default function UserMenu(props: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userName } = props;
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

  };

  return (
    <div className={style.container}>
      <div onClick={handleClick} className={style.title}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={style.button}
        >
          {t`Hello, ` + `${userName}`}
          <div className={style.icon}>
            <i className={"fas fa-angle-right"} />
          </div>
        </Button>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={style.menu}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem onClick={handleClose} className={style.menuItem}>
          <LogOut {...props} />
        </MenuItem>
      </Menu>
    </div>
  );
}
