import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  Computed,
  computed,
  createTypedHooks,
  Thunk,
  thunk,
} from "easy-peasy";
import { localXhr } from "../services/localXhr";
import { AccountsModel, IAccountsModel } from "./Account";
import { adminUsersModel, IAdminUsersModel } from "./AdminUsers";
import {
  anomalyTemplatesModel,
  IAnomalyTemplatesModel,
} from "./AnomalyTemplates";
import { CustomersModel, ICustomersModel } from "./CustomerModel";
import { DevicesModel, IDevicesModel } from "./DeviceModel";
import { IMessagesModel, messageModel } from "./ErrorMessage";
import { ISelectionsModel, selectionsModel } from "./Selections";
import { ISitesModel, SitesModel } from "./SitesModel";
import { ISubscriptionsModel, subscriptionsModel } from "./Subscriptions";
import { ISystemsModel, systemsModel } from "./Systems";
import { ITrapsModel, trapsModel } from "./traps";
import { ITriggersModel, TriggersModel } from "./TriggerModel";
import { IUnitsModel, unitsModel } from "./UnitModel";
import { IUsersModel, usersModel } from "./UsersModel";
import { IServicesModel, servicesModel } from "./ServicesModel";
import React from "react";
import { GlobalAdmin } from "coolremote-sdk";

export interface IServiceTypes {
  sitePackagesNamesEnums?: any;
  sitePackagesFlagsEnums?: { [index: number]: string };
  temperatureScale?: any;
  operationStatuses?: any;
  operationModes?: any;
  operationModesWithTemperatures?: any;
  fanModes?: any;
  swingModes?: any;
  weekDays?: any;
  roles?: any;
  permissions?: any;
  resources?: any;
  hvacBrands?: any[];
  outdoorUnitTasks?: any;
  systemTypes?: string[];
  alertClasses?: any;
  alertClearTypes?: any;
  proParsingCodes?: any;
  capacityMeasurementUnitTypes?: any;
  unitTypes?: any;
  customerStatuses?: any;
  creationMethods?: any;
  billingModels?: any;
  languages?: any;
  procedureConditions?: any;
  procedureRunningStates?: any;
  procedureStateCommands?: any;
  procedureDeviceCommands?: any;
  procedureStepTypes?: any;
  measurementUnitTypes?: any;
  pressureScale?: any;
  eventClearTypes?: any;
  eventTypes?: any;
  eventStatusTypes?: any;
  applications?: any;
  trapTypes?: any;
  siteStatuses?: any;
  subscriptionTemplatesTypes?: any;
  calculatedMode?: any;
  deviceTypes?: any;
  customerLevelPackagesEnum?: any;
  regionTypes?: any;
  siteSubscriptionErrorCodeEnums?: any;
  sitePackageEnums?: any;
  siteSubscriptionStatusEnums?: any;
  actionSources?: any;
  serviceParamValueTypes?: any;
  invoicingPeriods?: any;
  siteCategories?: any;
  currencyTypes?: any;
  siteOnboardingStatus?: any;
  sitePhases?: any;
}

export interface IRootStoreModel {
  children?: React.ReactNode;
  isLoggedIn: boolean;
  setLoggedIn: Action<IRootStoreModel, boolean>;
  users: IUsersModel;
  subscriptions: ISubscriptionsModel;
  adminUsers: IAdminUsersModel;
  customers: ICustomersModel;
  services: IServicesModel;
  devices: IDevicesModel;
  accounts: IAccountsModel;
  units: IUnitsModel;
  types: IServiceTypes;
  unitTypesMirrror: Computed<IRootStoreModel, any>;
  traps: ITrapsModel;
  anomalyTemplates: IAnomalyTemplatesModel;
  logout: Thunk<IRootStoreModel>;
  triggers: ITriggersModel;
  isLoaded: boolean;
  setIsLoaded: Action<IRootStoreModel, boolean>;
  sites: ISitesModel;
  isInitialized: boolean;
  getUserTree: Thunk<IRootStoreModel>;
  fetchedUserTree: Action<IRootStoreModel>;
  customersTree: any;
  setCustomers: Action<IRootStoreModel, any>;
  selections: ISelectionsModel;
  systems: ISystemsModel;
  theme: any;
  setUnitUpdateStatus: Action<IRootStoreModel, { status: string }>;
  unitUpdateStatus: string;
  setTypes: Action<IRootStoreModel, IServiceTypes>;
  doStatsUpdate: boolean;
  setStatsUpdate: Action<IRootStoreModel, boolean>;
  setServiceParamTypes: Action<IRootStoreModel, any>;
  getServiceTypes: Thunk<IRootStoreModel>;
  getServiceParamTypes: Thunk<IRootStoreModel>;
  deviceBySerial: Thunk<IRootStoreModel, any>;
  usersByDevice: Thunk<IRootStoreModel, any>;
  deviceByUser: Thunk<IRootStoreModel, any>;
  getUserByEmail: Thunk<IRootStoreModel, string>;
  errorMessage: IMessagesModel;
  temperatureScaleMirror: Computed<IRootStoreModel, any>;
  serviceParamTypes: any;
  unitTypesOptions: Computed<IRootStoreModel, any>;
  trapOperatorsOptions: any;
  eventClearTypesMirror: Computed<IRootStoreModel, any>;
  eventTypesMirror: Computed<IRootStoreModel, any>;
  eventStatusTypesMirror: Computed<IRootStoreModel, any>;
  serviceErrorTypes: any;
  setServiceErrorTypes: Action<IRootStoreModel, any>;
  setThemeFile: Action<IRootStoreModel, any>;
  prepareTheme: Thunk<IRootStoreModel>;
  regionTypesMirror: Computed<IRootStoreModel, any>;
  siteSubscriptionErrorsMirror: Computed<IRootStoreModel, any>;
  siteSubscriptionStatusMirror: Computed<IRootStoreModel, any>;
  sitePackageMirror: Computed<IRootStoreModel, any>;
  customerCategories: any;
  customerCategoriesOptions: any;
  customerCategoriesMirror: any;
  setCustomerCategoriesRelatedStates: Action<IRootStoreModel, any>;
  getCustomerCategories: Thunk<IRootStoreModel>;
  copyUnitInfo: Thunk<IRootStoreModel, any>;
}

const storeModel: IRootStoreModel = {
  users: usersModel,
  subscriptions: subscriptionsModel,
  adminUsers: adminUsersModel,
  customersTree: {},
  customers: CustomersModel,
  services: servicesModel,
  triggers: TriggersModel,
  devices: DevicesModel,
  accounts: AccountsModel,
  sites: SitesModel,
  units: unitsModel,
  traps: trapsModel,
  anomalyTemplates: anomalyTemplatesModel,
  types: {},
  serviceErrorTypes: {},
  serviceParamTypes: {},
  customerCategories: {},
  customerCategoriesOptions: [],
  customerCategoriesMirror: {},
  theme: null,
  unitTypesMirrror: computed(state => {
    const { types = {} } = state;
    const { unitTypes } = types;

    if (!unitTypes) {
      return null;
    }

    const keys = Object.keys(unitTypes);
    const object: { [index: string]: any } = {};

    const unitType = keys.reduce((allTypes, key) => {
      const name = unitTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return unitType;
  }),
  selections: selectionsModel,
  systems: systemsModel,
  isLoaded: false,
  isLoggedIn: false,
  isInitialized: false,
  doStatsUpdate: false,
  unitUpdateStatus: "",
  setIsLoaded: action(state => {
    state.isLoaded = true;
  }),

  logout: thunk((actions, payload: any) => {
    localXhr.removeToken();
    actions.setIsLoaded(true);
  }),
  setLoggedIn: action((state, payload) => {
    state.isLoggedIn = payload;
  }),
  setStatsUpdate: action((state, payload) => {
    state.doStatsUpdate = payload;
  }),
  getUserTree: thunk(async actions => {
    return actions.customers.getBasicCustomers();
  }),
  fetchedUserTree: action(state => {
    state.isInitialized = true;
  }),
  setCustomers: action((state, payload) => {
    state.customersTree = { ...payload };
  }),
  setUnitUpdateStatus: action((state, payload) => {
    state.unitUpdateStatus = payload.status;
  }),
  setTypes: action((state, payload) => {
    state.types = payload;
  }),
  setCustomerCategoriesRelatedStates: action((state, payload) => {
    state.customerCategories = payload.customerCategories;
    state.customerCategoriesMirror = payload.customerCategoriesMirror;
    state.customerCategoriesOptions = payload.customerCategoriesOptions;
  }),
  setServiceParamTypes: action((state, payload) => {
    state.serviceParamTypes = payload;
  }),
  setServiceErrorTypes: action((state, payload) => {
    state.serviceErrorTypes = payload;
  }),
  getServiceTypes: thunk(async (actions, payload) => {
    // Service types contain textual description of various params
    const serviceTypes = await coolremoteSDK.GlobalAdmin.getTypes();
    actions.setTypes(serviceTypes);
  }),
  getCustomerCategories: thunk(async (actions, payload) => {
    const customerCategories = await GlobalAdmin.getCustomerCategories();
    const customerCategoriesMirror: any = {};
    const customerCategoriesOptions: any = [];

    Object.keys(customerCategories).forEach(key => {
      const value = customerCategories[key];
      const label = key.charAt(0).toUpperCase() + key.slice(1);

      customerCategoriesMirror[value] = label;
      customerCategoriesOptions.push({ label, value });
    });

    actions.setCustomerCategoriesRelatedStates({
      customerCategories,
      customerCategoriesMirror,
      customerCategoriesOptions,
    });
  }),
  getServiceParamTypes: thunk(async (actions, payload) => {
    const serviceParamTypes =
      await coolremoteSDK.Services.getServiceParamTypes();
    actions.setServiceParamTypes(serviceParamTypes);
  }),
  deviceBySerial: thunk(async (actions, payload) => {
    // Service types contain textual description of various params
    const serviceTypes = await GlobalAdmin.getDeviceBySerial(payload);
    return serviceTypes;
  }),
  eventClearTypesMirror: computed(state => {
    const { types = {} } = state;
    const { eventClearTypes } = types;

    if (!eventClearTypes) {
      return null;
    }

    const keys = Object.keys(eventClearTypes);
    const object: { [index: string]: any } = {};

    const eventClearType = keys.reduce((allTypes, key) => {
      const name = eventClearTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventClearType;
  }),
  eventTypesMirror: computed(state => {
    const { types = {} } = state;
    const { eventTypes } = types;

    if (!eventTypes) {
      return {};
    }

    const keys = Object.keys(eventTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  eventStatusTypesMirror: computed(state => {
    const { types = {} } = state;
    const { eventStatusTypes } = types;

    if (!eventStatusTypes) {
      return {};
    }

    const keys = Object.keys(eventStatusTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventStatusTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  temperatureScaleMirror: computed(state => {
    const { types = {} } = state;
    const { temperatureScale } = types;

    if (!temperatureScale) {
      return null;
    }

    const keys = Object.keys(temperatureScale);
    const object: { [index: string]: any } = {};

    const temperatureScaleType = keys.reduce((types, key) => {
      const name = temperatureScale[key];
      types[name as string] = key;

      return types;
    }, object);

    return temperatureScaleType;
  }),
  regionTypesMirror: computed(state => {
    const { types = {} } = state;
    const { regionTypes } = types;

    if (!regionTypes) {
      return {};
    }

    const keys = Object.keys(regionTypes);
    const object: { [index: string]: any } = {};

    const regionType = keys.reduce((allTypes, key) => {
      const name = regionTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return regionType;
  }),
  siteSubscriptionStatusMirror: computed(state => {
    const { types = {} } = state;
    const { siteSubscriptionStatusEnums } = types;

    if (!siteSubscriptionStatusEnums) {
      return {};
    }

    const keys = Object.keys(siteSubscriptionStatusEnums);
    const object: { [index: string]: any } = {};

    const siteSubscriptionStatus = keys.reduce((allTypes, key) => {
      const status = siteSubscriptionStatusEnums[key];
      allTypes[status as string] = key;

      return allTypes;
    }, object);

    return siteSubscriptionStatus;
  }),
  sitePackageMirror: computed(state => {
    const { types = {} } = state;
    const { sitePackageEnums } = types;

    if (!sitePackageEnums) {
      return {};
    }

    const keys = Object.keys(sitePackageEnums);
    const object: { [index: string]: any } = {};

    const sitePackages = keys.reduce((allTypes, key) => {
      const name = sitePackageEnums[key];
      // mirror the site packages and 'splitThePackageName' into 'split The Package Name'
      allTypes[name as string] =
        key
          .match(/([A-Z]?[^A-Z]*)/g)
          ?.slice(0, -1)
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ") || "";

      return allTypes;
    }, object);

    return sitePackages;
  }),
  usersByDevice: thunk(async (actions, payload) => {
    // Service types contain textual description of various params
    const serviceTypes = await GlobalAdmin.getUsersBySerial(payload);
    return serviceTypes;
  }),

  deviceByUser: thunk(async (actions, payload) => {
    // Service types contain textual description of various params
    const serviceTypes = await GlobalAdmin.getDevicesByUser(payload);
    return serviceTypes;
  }),
  getUserByEmail: thunk(async (actions, payload) => {
    const data = await coolremoteSDK.Admin.userByEmail(payload);
    return data;
  }),
  unitTypesOptions: computed(state => {
    const { types = {} } = state;
    const { unitTypes } = types;

    if (!unitTypes) {
      return null;
    }

    return [
      { value: unitTypes.service, name: "Indoor" },
      { value: unitTypes.outdoor, name: "Outdoor" },
      { value: "mixed", name: "Mixed" },
    ];
  }),
  trapOperatorsOptions: [
    {
      value: ">",
      label: ">",
    },
    {
      value: "<",
      label: "<",
    },
    { value: "=", label: "=" },
    {
      value: "!=",
      label: "≠",
    },
    {
      value: "threshold",
      label: "Difference",
    },
  ],
  errorMessage: messageModel,
  setThemeFile: action((state, payload) => {
    if (!!state.theme) {
      return;
    }
    state.theme = payload;
  }),
  prepareTheme: thunk(actions => {
    const themeUrl = `${process.env.PUBLIC_URL}/assets/${window.location.hostname}/style.config.json`;
    fetch(themeUrl)
      .then(res => res.json())
      .then(res => {
        actions.setThemeFile(res);
      });
    const favIconUrl = `${process.env.PUBLIC_URL}/assets/${window.location.hostname}/favicon.ico`;
    const favIconEl: any = document.getElementById("favIconId");
    favIconEl.href = favIconUrl;
    if (process.env.PUBLIC_URL.indexOf("airwell") > -1) {
      document.title = "Airwell";
    }
  }),
  siteSubscriptionErrorsMirror: computed(state => {
    const { types = {} } = state;
    const { siteSubscriptionErrorCodeEnums } = types;
    if (!siteSubscriptionErrorCodeEnums) {
      return {};
    }

    const keys = Object.keys(siteSubscriptionErrorCodeEnums);

    const siteSubscriptionErrors: { [index: number]: string } = {};
    // mirror the error codes and 'splitTheErrorMessage' into 'split The Error Message'
    Object.entries(siteSubscriptionErrorCodeEnums).map(
      ([key, value]) =>
      (siteSubscriptionErrors[value as number] =
        key
          .match(/([A-Z]?[^A-Z]*)/g)
          ?.slice(0, -1)
          .join(" ") || "")
    );
    return siteSubscriptionErrors;
  }),
  copyUnitInfo: thunk(async (actions, payload) => {
    const res = await GlobalAdmin.copyUnitInfo(payload);
    return res;
  }),
};

const typedHooks = createTypedHooks<IRootStoreModel>();
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export default storeModel;
