import _ from "lodash";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AccountManagement from "../AccountManagement/AccountManagement";
import CustomerManagement from "../CustomerManagment/CustomerManagement";
import DeviceManagement from "../DeviceManagment/DeviceManagement";
import DevicePackageManagement from "../DevicePackageManagement/DevicePackageManagement";
import DirectDevices from "../DirectDevices/DeviceManagement";
import Login from "../Login/Login";
import NoAccessScreen from "../NoAccess/NoAccessScreen";
import SiteDetails from "../SiteManagement/SiteDetails";
import SiteManagement from "../SiteManagement/SiteManagement";
import TriggerManagment from "../TriggerManagement/TriggerManagment";
import UserMgmtNew from "../UserManagementNew/UserManagementNew";
import Authenticated from "./Authenticated";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// Non 'JS Styles' CSS imports.
import { ErrorStoreMessage } from "../../components/ErrorStoreMessage";
import Loading from "../../components/Loading/Loading";
import AdminUsersList from "../AdminUserManagement/AdminUsersList";
import DeviceManager from "../DeviceManagment/EditDevice";
import EditCustomer from "../EditCustomer/EditCustomer";
import Subscriptions from "../Subscriptions/Subscriptions";
import SubscriptionsCustomers from "../Subscriptions/SubscriptionsCustomers";
import SubscriptionsCustomerSites from "../Subscriptions/SubscriptionsCustomerSites";
import SubscriptionsSite from "../Subscriptions/SubscriptionsSite";
import SupportView from "../Support/SupportView";
import { StyledEngineProvider } from '@mui/material/styles';
import DeviceMigration from "../DeviceMigration/DeviceMigration";
import NewDeviceTests from "../NewDeviceTests/NewDeviceTests";
import ServiceParams from "../ServiceParams/ServiceParams";
import ServiceParamsEnums from "../ServiceParamsEnums/ServiceParamsEnums";
import AdminAudits from "../AdminAudits/AdminAudits";
import Dashboard from "../Dashboard/Dashboard";
import DirectUsers from "../DirectUsers/DirectUsers"
import AdvancedSearch from "../AdvancedSearch/AdvancedSearch";
import DeviceFamily from "../DeviceFamily/DeviceFamily";
import DeviceRecord from "../DeviceRecord/DeviceRecord";
import Availability from "../Availability/Availability"
import DierctTests from "../DirectTests/DirectTests";
import Customers from "../Customers/Customers";

const getAllowedRoute = (mySupportedPages: any) => {

  const showAllPages = mySupportedPages === "all";
  if (showAllPages || mySupportedPages["dashboard"]) {
    return "/dashboard";
  }
  if (showAllPages || mySupportedPages["customers"]) {
    return "/customers";
  }
  if (showAllPages || mySupportedPages["sites"]) {
    return "/sites";
  }
  if (showAllPages || mySupportedPages["devices"]) {
    return "/devices";
  }
  if (showAllPages || mySupportedPages["device-tests"]) {
    return "/device-tests";
  }
  if (mySupportedPages["device-packages"]) { //globalAdmins shouldn't see this page, thats why we don't have showAll here
    return "/device-packages";
  }
  if (showAllPages || mySupportedPages["users"]) {
    return "/users";
  }
  if (showAllPages || mySupportedPages["users2"]) {
    return "/users2";
  }
  if (showAllPages || mySupportedPages["availability"]) {
    return "/availability";
  }
  if (showAllPages || mySupportedPages["admin-users"]) {
    return "/admin-users";
  }
  if (showAllPages || mySupportedPages["support"]) {
    return "/SupportView";
  }

  if (showAllPages || mySupportedPages["subscriptions"]) {
    return "/subscriptions";
  }
  if (showAllPages || mySupportedPages["subscriptions-customers"]) {
    return "/subscriptions-customers";
  }
  if (showAllPages || mySupportedPages["subscriptions-customer-sites"]) {
    return "/subscriptions-customer-sites";
  }
  if (showAllPages || mySupportedPages["subscriptions-site"]) {
    return "/subscriptions-site";
  }
  if (showAllPages || mySupportedPages["accounts"]) {
    return "/accounts";
  }
  return "/";
};

export default function App() {
  const themeFile = useStoreState((state) => state.theme);
  const prepareTheme = useStoreActions((actions) => actions.prepareTheme);
  const { mySupportedPages = "all" } = useStoreState((s) => s.users);
  const showAllPages = mySupportedPages === "all";

  const logout = useStoreActions((actions) => actions.users.logout);
  const checkLoggedin = useStoreActions(
    (actions) => actions.users.checkLoggedin
  );
  const getUserTree = useStoreActions((action) => action.getUserTree);
  const setLoggedIn = useStoreActions((action) => action.setLoggedIn);
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const { dataState } = useStoreState((s) => s.users);
  const getserviceTypes = useStoreActions((action) => action.getServiceTypes);
  const messageObj = useStoreState((s) => s.errorMessage.message);
  const customers = useStoreState((s) => s.customers.customersBasicList);

  const initialize = async () => {
    if (isLoggedIn) {
      try {
        await getUserTree()
        await getserviceTypes()
      } catch (error) {
        setLoggedIn(false);
        logout();
      }
    }
  };

  useEffect(() => {
    initialize();
  }, [isLoggedIn]);

  // useEffect(() => {
  //   getserviceTypes();
  // }, [getserviceTypes]);

  useEffect(() => {
    checkLoggedin();
  }, [checkLoggedin]);

  useEffect(() => {
    if (!themeFile) {
      prepareTheme();
    }
  }, [themeFile]);

  if (!themeFile) {
    return <></>;
  }

  if (dataState === "loading") {
    return <Loading />;
  }

  if (isLoggedIn && (window?.location?.href?.includes("-diag") && _.isEmpty(customers))) {
    return <Loading />;
  }

  const theme = createTheme(themeFile);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <div className="App">
          <Router>
            <Switch>
              <Route exact={true} path="/" component={Login} />
              <Route exact={true} path="/no-access" component={NoAccessScreen} />
              <Authenticated exact path="/audits" component={AdminAudits} />
              {(showAllPages || mySupportedPages["sites"]) && <Authenticated exact path="/sites" component={SiteManagement} />}
              {(showAllPages || mySupportedPages["device-family"]) && <Authenticated exact path="/device-family" component={DeviceFamily} />}
              {(showAllPages || mySupportedPages["device-records"]) && <Authenticated exact path="/device-records" component={DeviceRecord} />}
              {showAllPages && <Authenticated exact path="/trigger-management" component={TriggerManagment} />}
              {(showAllPages || mySupportedPages["direct-devices"]) && <Authenticated exact path="/direct-devices" component={DirectDevices} />}
              {(showAllPages || mySupportedPages["device-tests"]) && <Authenticated exact path="/direct-tests" component={DierctTests} />}
              {(showAllPages || mySupportedPages["direct-users"]) && <Authenticated exact path="/direct-users" component={DirectUsers} />}
              {<Authenticated exact path="/availability" component={Availability} />}
              {(showAllPages || mySupportedPages["users2"]) && <Authenticated exact path="/users2" component={UserMgmtNew} />}
              {(showAllPages || mySupportedPages["admin-users"]) && <Authenticated exact path="/admin-users" component={AdminUsersList} />}
              {(showAllPages || mySupportedPages["customers"]) && <Authenticated exact path="/customers/new" component={EditCustomer} />}
              {(showAllPages || mySupportedPages["customers"]) && <Authenticated exact path="/customers/:customerId" component={EditCustomer} />}
              {(showAllPages || mySupportedPages["sites"]) && <Authenticated exact path="/customers/:customerId/sites" component={SiteManagement} />}
              {/* {(showAllPages || mySupportedPages["direct-sites"]) && <Authenticated exact path="/direct-sites" component={DirectSites} />} */}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/customers/:customerId/devices" component={DeviceManagement} />}
              {(showAllPages || mySupportedPages["device-tests"]) && <Authenticated exact path="/device-tests" component={NewDeviceTests} />}
              {/* {(showAllPages || mySupportedPages["admin-audits"]) && <Authenticated exact path="/admin-audits" component={AdminAudits} />} */}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/customers/:customerId/devices/disconnected" component={DeviceManagement} disconnected={true} />}
              {(showAllPages || mySupportedPages["customers"]) && <Authenticated exact path="/customers" component={Customers} />}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/devices" component={DeviceManagement} />}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/devices/registered" component={DeviceManagement} />}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/devices/disconnected" component={DeviceManagement} />}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/devices/disconnected/registered" component={DeviceManagement} />}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/devices/connected/registered" component={DeviceManagement} />}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/devices/connected" component={DeviceManagement} />}
              {false && <Authenticated exact path="/device-packages" component={DevicePackageManagement} />}
              {/* {(showAllPages || mySupportedPages["device-packages"]) && <Authenticated exact path="/device-packages" component={DevicePackageManagement} />} */}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/devices/:deviceId" component={DeviceManager} />}
              {(showAllPages || mySupportedPages["devices"]) && <Authenticated exact path="/site/:siteId/devices" component={DeviceManagement} />}
              {(showAllPages || mySupportedPages["service-parameters"]) && <Authenticated exact path="/service-parameters" component={ServiceParams} />}
              {(showAllPages || mySupportedPages["service-parameters-enums"]) && <Authenticated exact path="/service-parameters-enums" component={ServiceParamsEnums} />}
              {(showAllPages || mySupportedPages["sites"]) && <Authenticated exact path="/site/:siteId" component={SiteDetails} />}
              {(showAllPages || mySupportedPages["support"]) && <Authenticated exact path="/SupportView" component={SupportView} />}
              {(showAllPages || mySupportedPages["accounts"]) && <Authenticated exact path="/accounts" component={AccountManagement} />}
              {(showAllPages || mySupportedPages["subscriptions"]) && <Authenticated exact path="/subscriptions" component={Subscriptions} />}
              {(showAllPages || mySupportedPages["subscriptions"]) && <Authenticated exact path="/customers/:customerId/subscriptions" component={Subscriptions} />}
              {(showAllPages || mySupportedPages["subscriptions-customers"]) && <Authenticated exact path="/subscriptions-customers" component={SubscriptionsCustomers} />}
              {(showAllPages || mySupportedPages["subscriptions-customer-sites"]) && <Authenticated exact path="/subscriptions-customer-sites" component={SubscriptionsCustomerSites} />}
              {(showAllPages || mySupportedPages["subscriptions-site"]) && <Authenticated exact path="/subscriptions-site" component={SubscriptionsSite} />}
              {(showAllPages || mySupportedPages["device-migration"]) && <Authenticated exact path="/device-migration" component={DeviceMigration} />}
              {(showAllPages || mySupportedPages["dashboard"]) && <Authenticated exact path="/dashboard" component={Dashboard} />}
              {(showAllPages || mySupportedPages["search"]) && <Authenticated exact path="/search" component={AdvancedSearch} />}
              <Redirect to={showAllPages || mySupportedPages["dashboard"] ? "/" : getAllowedRoute(mySupportedPages)} />
            </Switch >
          </Router >
          {messageObj.message && (<ErrorStoreMessage message={messageObj} />)}
        </div >
      </StyledEngineProvider >
    </ThemeProvider >
  );
}
