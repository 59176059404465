import {
  Card,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";
import React from "react";
import { t } from "ttag";
import { Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import { auditActionMap } from "./AuditsActionMap";
import useStyles from "./AuditsList.style";

const dataPropertiesReplacement: { [key: string]: any } = {
  isRegistered: "Connected",
  isExpired: "Expired"
}
const notReadable = [
  "name",
  "customer",
  "isAcceptedTOU",
  "_id",
  "__v",
  "user",
  "userLocationsMetadata",
  "password",
  "randId",
  "id",
  "device",
  "admin",
  "lat",
  "lon",
  "fileId",
  "type",
  "site"
];

export default function AuditsDataPopUp(props: any) {
  const { audit } = props;
  const { source, action, date, site, device, unit, line, data, sourceType } = audit;
  const siteName = site?.name
  const deviceName = device?.name;
  const unitName = unit?.name;
  const { operationStatuses, operationModes, fanModes, swingModes, temperatureScale, actionSources } = useStoreState((s) => s.types);
  const classes = useStyles();
  const timeFormat = useStoreState((state) => state.users.timeFormat);

  const valueMapping = (value: number, action: string) => {
    const lowerCaseAction = action.toLowerCase();
    if (lowerCaseAction.includes("setpoint")) { return value; }
    if (lowerCaseAction.includes("operationstatus")) { return operationStatuses[value] || value; }
    if (lowerCaseAction.includes("operationmode")) { return operationModes[value] || value; }
    if (lowerCaseAction.includes("fanmode")) { return fanModes[value] || value; }
    if (lowerCaseAction.includes("swingmode")) { return swingModes[value] || value; }
    return value;
  }
  const valueTitleMapping = (action: string) => {
    const lowerCaseAction = action.toLowerCase();
    if (lowerCaseAction.includes("setpoint")) { return t`Setpoint`; }
    if (lowerCaseAction.includes("operationstatus")) { return t`Operation Status`; }
    if (lowerCaseAction.includes("operationmode")) { return t`Operation Mode`; }
    if (lowerCaseAction.includes("fanmode")) { return t`Fan Mode`; }
    if (lowerCaseAction.includes("swingmode")) { return t`Swing Mode`; }
    return t`value`;
  }
  const replaceTitle = (property: string) => {
    const replacement = dataPropertiesReplacement[property];
    if (replacement) { return replacement; }
    const title = property
      .split(/(?=[A-Z])/)
      .join(" ")
      .trim();
    return title;
  }
  const getActionText = (actionId: string) => {
    const action: any = auditActionMap[actionId]?.name;
    if (action) {
      return action;
    }
    const actionArray = actionId.split(/(?=[A-Z])/);
    if (["update", "create", "accept", "delete", "remove"].includes(actionArray[0])) {
      actionArray[0] = actionArray[0] + "d";
    }
    if (["add"].includes(actionArray[0])) {
      actionArray[0] = actionArray[0] + "ed";
    }

    actionArray[0] = actionArray[0].charAt(0).toUpperCase() + actionArray[0].substring(1);
    return actionArray.join(" ").trim();
  }
  const convertDate = (stringDate: string) => {
    const { timezone } = audit;
    const date = timezone ? moment(stringDate).tz(timezone).format(`ll ${timeFormat}`) : moment(stringDate).format(`ll ${timeFormat}`);
    return date;
  }
  const DataRow = ({ property, content, action, index }: any) => {
    const contentText = typeof content === "boolean" ? (content ? "yes" : "no") : content;
    return (
      <div className={clsx(classes.mainRow, { [classes.grayRow]: index % 2 })}>
        <Typography className={classes.rowTitle}>
          {property === "value" ? valueTitleMapping(action) : replaceTitle(property)}
        </Typography>
        <Typography className={classes.rowData}>
          {property === "value"
            ? `${valueMapping(contentText, action)}`
            : property === "temperatureScale"
              ? `${temperatureScale[contentText]}`
              : `${contentText}`}
        </Typography>
      </div>
    );
  }
  const MainDataRow = ({ title, content, index }: any) => (
    <div className={clsx(classes.mainRow, { [classes.grayRow]: index % 2 })}>
      <Typography className={classes.rowTitle}>{`${title}`}</Typography>
      <Typography className={classes.rowData}>{`${content}`}</Typography>
    </div>
  );
  const getTimeFromMins = (mins: number) => {

    const h = mins / 60 | 0,
      m = mins % 60 | 0;
    return moment.utc().hours(h).minutes(m).format("hh:mm A");
  };
  let countedIndex = 0;
  const auditSource = (source: number) => {
    switch (source) {
      case +actionSources.user: return t`Application User`;
      case +actionSources.schedule: return t`Scheduled Operation`;
      case +actionSources.homeAutomation: return t`Home automation / BMS`;
      case +actionSources.hvac: return t`HVAC`;
      case +actionSources.applicationLogic: return t`Application Logic`;
      default: return t`Unknown source`;
    }
  };

  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <div className={classes.titleContainer}>
          <Typography className={classes.headerTitle}>{t`Audit Data`}</Typography>
          <IconButton disableRipple onClick={() => props.setIsSeen(null)} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Card className={classes.container}>
            <MainDataRow title={t`Source`} content={auditSource(+source)} index={countedIndex++ % 2} />
            <MainDataRow title={t`Source Type`} content={sourceType || "_"} index={countedIndex++ % 2} />
            <MainDataRow title={t`Action`} content={getActionText(action).replace(/Trap/g, "Trigger").replace(/Event/g, "Alert")} index={countedIndex++ % 2} />
            <MainDataRow title={t`Date`} content={convertDate(date)} index={countedIndex++ % 2} />
            {siteName && <MainDataRow title={t`Site`} content={siteName} index={countedIndex++ % 2} />}
            {deviceName && <MainDataRow title={t`Device`} content={deviceName} index={countedIndex++ % 2} />}
            {unitName && <MainDataRow title={t`Unit`} content={unitName} index={countedIndex++ % 2} />}
            {line && <MainDataRow title={t`Line`} content={line} index={countedIndex++ % 2} />}

            {data &&
              Object.keys(data).map((key: any) => {
                let content = data[key];
                if (
                  typeof content !== "string" &&
                  typeof content !== "number" &&
                  typeof content !== "boolean"
                ) {
                  return null;
                }

                if (notReadable.includes(key)) {
                  return null;
                }
                if (typeof content === "number" && (key === "powerOffTime" || key === "powerOnTime")) {
                  content = getTimeFromMins(content);
                }
                return (
                  <DataRow
                    key={`audit-data-${countedIndex}`}
                    property={key}
                    content={content}
                    action={action}
                    index={countedIndex++ % 2}
                  />
                );
              })}
          </Card>
        </div>
      </Dialog>
    </div>
  );
}
