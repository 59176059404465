import { CircularProgress } from '@mui/material';
import React from "react";
import assetsService from "../../services/assetsService";
import useStyles from "./Loading.style";

export default function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <div className={classes.main}>
        <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={400} thickness={1} color="secondary" />
        </div>
        <div className={classes.logos}>
          <img
            alt="logo"
            src={assetsService.getResourcePath("fullLogo.png")}
          />
        </div>
      </div>
    </div>
  );
}
