import { Button, Typography } from "@mui/material";
import {
    ExpandLess,
    ExpandMore,
} from "@mui/icons-material";
import clsx from "clsx";
import React from "react";
import useStyles from "./menuItem.style";

interface IMenuItemProps {
    Icon: any;
    name: string;
    disabled?: boolean;
    open: boolean
    handleClick: any;
}

const SubMenuHeader = ({
    Icon,
    name,
    disabled = false,
    handleClick,
    open
}: IMenuItemProps) => {
    const styles: any = useStyles();
    return (
        <Button
            startIcon={Icon}
            onClick={handleClick}
            className={clsx(styles.buttonStyle, {
                [styles.disabledButton]: disabled
            })}
        >
            <Typography className={styles.buttonText}>{name}</Typography>
            <div style={{ position: "absolute", display: "flex", alignItems: "center", right: "15px" }}>
                {open ? <ExpandLess /> : <ExpandMore />}
            </div>
        </Button>


    );
};

export default SubMenuHeader;
