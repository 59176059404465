import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreActions } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./AccountManagement.style";
import { GlobalAdmin as SdkGlobalAdmin } from "coolremote-sdk"

export default function AccountManagement(props: any) {
  const styles: any = useStyles();
  const [accounts, setAccounts] = useState<any>([]);
  const getAccounts = useStoreActions((actions) => actions.accounts.getAccounts);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  useEffect(() => {
    getAccounts()
      .then((res: any) => {
        setAccounts(res);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
  }, []);

  const doImpersonate = (accountId: string) => {
    let hostURLs: any = {};
    const urlsFile = `${process.env.PUBLIC_URL}/assets/${window.location.hostname}/impersonate.config.json`;
    fetch(urlsFile)
      .then((res) => res.json())
      .then((res) => {
        hostURLs = res;
        let env = "";
        if (window.location.origin.indexOf("-dev") !== -1 || window.location.origin.indexOf("localhost") !== -1) {
          env = "dev";
        } else if (window.location.origin.indexOf("-stg") !== -1) {
          env = "stg";
        } else {
          env = "prod";
        }

        const hosturl = hostURLs[env]["accountAdmin"].url;

        return SdkGlobalAdmin.getAccountAdminToken(accountId)
          .then(({ token }: any) => {
            const url = `https://${hosturl}/impersonate/${token}`;
            const win = window.open(url, "_blank");
            win?.focus();
          });
      })
      .catch((err) => {
        addMessage({ message: err.message });
      });
  }

  return (
    <ServiceNavigationBar title={t`Account Management`} {...props}>
      <Paper elevation={0} className={styles.paperPadding}>
        <TableContainer>
          <Table stickyHeader className="" aria-label="customized table">
            <TableHead className={styles.tableHead}>
              <TableRow>
                <TableCell className={styles.headCells} align="left" >
                  <div className={styles.headContainer}>
                    {t`ACCOUNT NAME`}
                  </div>
                </TableCell>
                <TableCell className={styles.headCells} align="left" >
                  <div className={styles.headContainer}>
                    {t`# OF CUSTOMERS`}
                  </div>
                </TableCell>
                <TableCell className={styles.headCells} align="left" >
                  <div className={styles.headContainer}>
                    {t`# OF SITES`}
                  </div>
                </TableCell>
                <TableCell className={styles.headCells} align="left" >
                  <div className={styles.headContainer}>
                    {t`IMPERSONATE`}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts && Object.entries(accounts).map(([id, account]: any) => {
                const { name, numberOfCustomers, numberOfSites } = account;
                return (
                  <TableRow hover tabIndex={-1} key={id}>
                    <TableCell className={styles.rowCell} component="th" scope="row" align="left" >
                      {name}
                    </TableCell>
                    <TableCell className={styles.rowCell} component="th" scope="row" align="left" >
                      {numberOfCustomers}
                    </TableCell>
                    <TableCell className={styles.rowCell} component="th" scope="row" align="left" >
                      {numberOfSites}
                    </TableCell>
                    <TableCell className={styles.rowCell} component="th" scope="row" align="left" >
                      <Button className={styles.buttonStyle} onClick={() => doImpersonate(id)} >
                        {t`Account Admin`}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </ServiceNavigationBar>
  );
}
