import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  welcomeMessage: {
    color: theme.login.welcomeMessage.color,
    fontSize: "20px",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: "bold",
    paddingBottom: "30px",
    textAlign: "center"
  },
  loginContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    background: theme.login.background,
    width: "100%",
    height: "100%"
  },
  logoSection: {
    height: "35%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderBottomRightRadius: "20px",
    borderBottomLeftRadius: "20px"
  },
  passwordStyle: {
    marginTop: "20px",
    marginBottom: 0
  },
  usernameStyle: {
    marginTop: "20px",
    marginBottom: 0
  },
  inputClass: {
    color: theme.login.input.color,
    background: theme.login.input.background,
    fontSize: "17px",
    paddingLeft: "15px",
    borderRadius: "4px",
    border: "none",
    height: "50px"
  },
  errorStyle: {
    marginTop: "0px",
    fontSize: "14px",
    color: "#f44336",
    padding: "5px 17px",
    fontWeight: 400
  },
  linkStyle: {
    color: "#e8e8ec",
    cursor: "pointer",
    textDecoration: "underline",
    marginBottom: "0px"
  },
  linkStyleLabel: {
    fontSize: "13px"
  },
  inputRootHeight: {
    minHeight: "85%"
  },
  LoginButtonsStyle: {
    display: "flex",
    fontStyle: "normal",
    maxHeight: "55px",
    minHeight: "55px",
    lineHeight: "normal",
    fontStretch: "normal",
    borderRadius: "6px",
    color: "#fdfdfe",
    width: "100%",
    fontSize: "18px",
    letterSpacing: "0.34px"
  },
  submitButton: {
    backgroundColor: theme.login.submitButton.backgroundColor,
    marginTop: "20px",
    "&:hover": {
      backgroundColor: theme.login.submitButton.hover
    }
  },
  forgotPasswordButton: {
    backgroundColor: "transparent",
    textDecoration: "underline",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "none"
    },
    "&>span": {
      width: "100%"
    }
  },
  contentSection: {
    display: "flex",
    flexDirection: "column",
    width: "500px",
    margin: "auto"
  },
  errorMsg: {
    width: "500px",
    color: "rgb(240, 83, 71)",
    height: "44px",
    fontSize: "24px",
    marginTop: "10px",
    lineHeight: "44px"
  }

}));
export default useStyles;
