import { CircularProgress, Grid, List, ListItem, Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./Availability.style";
import moment from "moment";


export default function Availability(props: any) {
  const classes: any = useStyles();
  const selections = useStoreState((state) => state.selections.selections);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getAvailabilities = useStoreActions((actions) => actions.services.getAvailabilities);
  const [zonesStatusesObj, setZonesStatusesObj] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let { endDate, startDate } = selections.dateRange || {}
    if (!startDate || !endDate) {
      setZonesStatusesObj({})
      return;
    }
    const isOnDaySelected = moment(startDate).format("ll") === moment(endDate).format("ll");
    if (!isOnDaySelected) {
      startDate = new Date(new Date(endDate?.getTime()).setHours(0, 0, 0));
    }

    // const startDateUTC = Date.UTC(startDate?.getFullYear(), startDate?.getMonth(), startDate?.getDate(), 0, 0, 0);
    // const endDateUTC = Date.UTC(startDate?.getFullYear(), startDate?.getMonth(), startDate?.getDate(), 23, 59, 59);
    setIsLoading(true)
    getAvailabilities({ startTimeUTC: startDate?.getTime(), endTimeUTC: endDate?.getTime() })
      .then((data: any[]) => {
        const dataList: any = {
          "us-west-1": [],
          "us-west-2": [],
          "us-east-1": [],
          "eu-west-1": [],
          "sa-east-1": [],
          "ap-northeast-1": [],
          "ap-southeast-1": [],
          "ap-southeast-2": [],
          "dates": [],
        };

        data?.forEach((s: any) => {
          dataList["us-west-1"].push({ status: s["us-west-1"], date: s.timestamp })
          dataList["us-west-2"].push({ status: s["us-west-2"], date: s.timestamp })
          dataList["us-east-1"].push({ status: s["us-east-1"], date: s.timestamp })
          dataList["eu-west-1"].push({ status: s["eu-west-1"], date: s.timestamp })
          dataList["sa-east-1"].push({ status: s["sa-east-1"], date: s.timestamp })
          dataList["ap-northeast-1"].push({ status: s["ap-northeast-1"], date: s.timestamp })
          dataList["ap-southeast-1"].push({ status: s["ap-southeast-1"], date: s.timestamp })
          dataList["ap-southeast-2"].push({ status: s["ap-southeast-2"], date: s.timestamp })
          dataList["dates"].push(s.timestamp)
        });
        setZonesStatusesObj(dataList)
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setIsLoading(false))

  }, [selections?.dateRange?.startDate, selections?.dateRange?.endDate, selections, selections?.dateRange])

  const zones = [
    "us-west-1",
    "us-west-2",
    "us-east-1",
    "eu-west-1",
    "sa-east-1",
    "ap-northeast-1",
    "ap-southeast-1",
    "ap-southeast-2"
  ]

  const statusBarColors: any = {
    "ok": "green",
    "error": "red",
    "outdated": "orange"
  }

  const { endDate, startDate } = selections.dateRange || {}
  return (
    <>
      <ServiceNavigationBar
        title={t`Availability`}
        filters={<SelectionsMenu hideSiteSelection hideUnitSelection hideSystemSelection hideCustomerSelection showOneDatePicker />}
        {...props}
      >
        {isLoading && <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} />
        </div>
        }
        {
          !endDate || !startDate ?
            (<Grid container direction={"column"} className={classes.noContentContainer}>
              <div className={classes.grayOval}>
                <ArrowIcon className={classes.arrowIcon} />
              </div>
              <Typography>
                {t`Please select a date using the above filter.`}
              </Typography>
            </Grid>) :
            <div>
              <List>
                {zones.map((zone) => <ListItem className={classes.listItem} dense autoFocus divider key={zone}>
                  <span className={classes.zoneName}>{zone}</span>
                  <div className={classes.statusesBarContainer}>
                    {!isLoading && zonesStatusesObj[zone]?.map((zoneStatus: any, i: number) => (
                      <Tooltip key={i} arrow placement="top-start" title={<div><p>{`Status: ${zoneStatus.status}`}</p><p>{` Date/Time: ${moment(new Date(zoneStatus.date)).format("llll")}`}</p></div>} >
                        <p className={classes.statusBar} key={zoneStatus.date} style={{ background: statusBarColors[zoneStatus.status] }}></p>
                      </Tooltip>))}
                  </div>
                </ListItem>
                )}
              </List>
            </div>
        }
      </ServiceNavigationBar >

    </>
  );
}
