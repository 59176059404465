import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100%",
    "& >div": {
      overflow: "unset",
      backgound: "red"
    }
  },
  contentArea: {
    width: "100%",
    height: "100vh"
  },

  loadingContainer: {
    height: "70%",
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },

  headerContainer: {
    height: "60px",
    display: "flex",
    background: "#efeff2",
    alignItems: "center",
    justifyContent: "space-between"
  },

  wrapper: {
    height: "calc(100vh - 160px)",
    background: "rgb(236, 235, 239)",
    display: "flex",
    flexWrap: "nowrap"
  },

  // ---- Audits filters
  filterContainer: {
    height: "100%",
    width: "20rem",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    flexShrink: 0
  },

  filterGridItem: {
    height: "100%",
    background: theme.palette.background.paper,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    "&:first-child": {
      borderBottom: "1rem solid #efeff2"
    }
  },

  filter: {
    height: "100%",
    overflow: "auto",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none"
  },

  // ---- Audits table
  tableWrapper: {
    height: "100%",
    marginLeft: "1rem",
    flexGrow: 1
  },

  tableContainer: {
    height: "100%",
    width: "100%"
  },
  tableCell__date: {
    width: "24ch",
    paddingLeft: 0,
    whiteSpace: "nowrap"
  },
  tableCell__actionOnSubject: {
    width: "25ch"
  },
  iconWrapper: { display: "flex" },
  homeIcon: {
    fontSize: 25
  },
  noIcon: { width: 25, textAlign: "center" },
  userIcon: { fontSize: 23, marginLeft: "1px" },
  hvacIcon: { fontSize: 21, marginLeft: "2px" },
  scheduleIcon: {
    fontSize: 18,
    marginLeft: "3.5px",
    "&>g": {
      fill: "grey",
      width: "10px"
    }
  },

  "[class^=\"NavigationBar\"]": {
    padding: 0
  },
  body: {
    background: "#fafafa"
  },

  itemButton: {
    display: "flex",
    alignItems: "center"
  },

  container: {
    border: "1px solid #d5d2d5",
    borderRadius: "4px",
    marginBottom: "1.5rem",
    marginTop: "1rem"
  },

  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.primary.dark,
    height: "60px",
    minHeight: "60px",
    paddingLeft: "20px",
    borderBottom: "1px solid #d5d2d5",
    paddingRight: "13px",
    backgroundColor: "#f6f6f7",
  },
  rowTitle: {
    fontWeight: "bold",
    textTransform: "capitalize",
    width: "310px"
  },
  rowData: {
    width: "80%"
  },
  mainRow: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    padding: "5px 10px"
  },
  grayRow: { backgroundColor: "#efeff2" },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"

  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto"
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  noContentContainer: {
    display: "flex",
    flex: 1,
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px"
  },
  arrowIcon: {
    fontSize: "91px",
    transform: "rotate(90deg)"
  },
}));

export default useStyles;
