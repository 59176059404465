import {  makeStyles } from "@mui/styles";

const styles: any = makeStyles((theme: any) =>
  ({

    buttonStyle: {
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
      textTransform: "none",
      color: theme.menu.nav.color,
      opacity: 0.6,
      minHeight: "48px",
      padding: "6px 15px",
      borderRadius: "0",
      "&:hover": {
        backgroundColor: theme.menu.listItem.backgroundColor
      }
    },
    disabledButton: {
      opacity: 0.3,
      "&:hover": {
        cursor: "not-allowed"
      }
    },
    selectedButton: {
      opacity: "1 !important",
      borderLeft: `${theme.menu.listItem.borderLeft.borderThickness} solid ${theme.menu.listItem.borderLeft.color}`,
      backgroundColor: theme.menu.listItem.backgroundColor
    },

    buttonText: {
      color: theme.menu.nav.color,
      fontSize: "17px",
      fontWeight: "normal",
      textAlign: "left"
    }
  }));

export default styles;
