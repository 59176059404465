import React from "react";

const SortUp = (props) => (
  <svg width="24" height="24" transform={props.flip ? "scale(1,-1)" : "scale(1,1)"} strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 14L2 14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 10H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 18H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 14V4M19 4L22 7M19 4L16 7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default SortUp;
