import {  makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  contentArea: {
    display: "flex",
    flexDirection: "column"
  },
  headerContainer: {
    display: "flex",
    color: theme.header.color,
    justifyContent: "space-between",
    flex: "1",
    width: "100%",
    backgroundColor: theme.header.backgroundColor,
    maxHeight: "90px",
    minHeight: "90px",
    alignItems: "center",
    boxSizing: "border-box",
    borderLeft: `1px solid ${theme.header.borderLeft}`,
    padding: "0px 20px"
  },
  headerTitle: {
    textAlign: "right",
    color: theme.header.title.color,
    fontSize: "20px",
    marginTop: "4px",
    marginLeft: "10px",
    marginBottom: "5px"
  },
  header: {
    padding: "1.2rem 0rem 0rem 1rem",
    textAlign: "right",
    backgroundColor: theme.header.backgroundColor,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  logoContainer: {
    justifyContent: "start",
    marginLeft: "-15px"
  },
  leftSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  }

}));

export default useStyles;
