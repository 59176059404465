import React from "react";
import clsx from "clsx";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import _ from "lodash";
import {  makeStyles } from "@mui/styles";
import {Theme} from  "@mui/material/styles";

const useStyles: any = makeStyles((theme: any) => ({
  root: {
    //   '&&:hover': {
    //     backgroundColor: 'transparent'
    //   }
    //   // padding: 0,
    //   // margin: '0 10px'
    // },
    // colorSecondary: {
    //   '&:hover': {
    //     backgroundColor: '#ffff04'
    //   }
    // padding: 0
  },
  checked: {},
  icon: {
    width: 18,
    height: 18,
    borderRadius: 3
  },
  icon_filled: {
    // border: 'solid 1px #d5d2d5',
    backgroundColor: "#d5d2d5",
    // boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    // backgroundColor: '#ffffff',
    // backgroundImage:
    //   'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // "$root.Mui-focusVisible &": {
    //   outline: "2px auto rgba(19,124,189,.6)",
    //   outlineOffset: 20
    // },
    "input:hover ~ &": {
      backgroundColor: "#736973"
    }
    // 'input:disabled ~ &': {
    //   boxShadow: 'none',
    //   background: 'rgba(213,210,213,.5)'
    // }
  },
  checkedIcon_filled: {
    backgroundColor: "#736973",
    // backgroundImage:
    //   'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    "&:before": {
      width: 18,
      height: 18,
      display: "block",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: "\"\""
    },
    "input:hover ~ &": {
      backgroundColor: "#433943"
    }
  },
  icon_outlined: {
    border: "solid 1px #d5d2d5",
    // backgroundColor: '#d5d2d5',
    // boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: "transparent",
    // backgroundImage:
    //   'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // "$root.Mui-focusVisible &": {
    //   outline: "2px auto rgba(19,124,189,.6)",
    //   outlineOffset: 20
    // },
    "input:hover ~ &": {
      border: "solid 1px #736973"
      // backgroundColor: '#736973'
    }
    // 'input:disabled ~ &': {
    //   boxShadow: 'none',
    //   background: 'rgba(213,210,213,.5)'
    // }
  },
  checkedIcon_outlined: {
    border: "solid 1px #736973",
    backgroundColor: "transparent",
    // backgroundImage:
    //   'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    "&:before": {
      width: 18,
      height: 18,
      display: "block",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M 6.744 13.5 a 1.193 1.193 0 0 1 -0.848 -0.377 l -2.204 -2.356 a 1.176 1.176 0 0 1 0.061 -1.668 a 1.191 1.191 0 0 1 1.677 0.06 l 1.366 1.459 l 5.819 -5.787 a 1.19 1.19 0 0 1 1.662 0.015 c 0.458 0.455 0.465 1.192 0.015 1.654 l -6.688 6.654 a 1.2 1.2 0 0 1 -0.839 0.346 h -0.021 Z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: "\"\""
    },
    "input:hover ~ &": {
      color: "red",
      border: "solid 1px #433943"
    }
  },
  icon_lite: {
    width: 18,
    height: 18,
    borderRadius: 3,
    // border: 'solid 1px #d5d2d5',
    backgroundColor: "#d5d2d5",
    // boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    // backgroundColor: '#ffffff',
    // backgroundImage:
    //   'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // "$root.Mui-focusVisible &": {
    //   outline: "2px auto rgba(19,124,189,.6)",
    //   outlineOffset: 20
    // },
    "input:hover ~ &": {
      backgroundColor: "#736973"
    }
    // 'input:disabled ~ &': {
    //   boxShadow: 'none',
    //   background: 'rgba(213,210,213,.5)'
    // }
  },
  checkedIcon_lite: {
    backgroundColor: "#736973",
    // backgroundImage:
    //   'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    "&:before": {
      width: 18,
      height: 18,
      display: "block",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: "\"\""
    },
    "input:hover ~ &": {
      backgroundColor: "#433943"
    }
  }
}));

interface CoolCheckboxProps extends CheckboxProps {
  variant?: "filled" | "outlined" | "light";
}

const CoolCheckbox = (props: CoolCheckboxProps) => {
  const classes = useStyles();
  const { variant = "filled" } = props;

  // Dirty, but TS doesn't allow us to use classes[`icon_${variant}`]
  const iconClass =
    variant === "filled"
      ? clsx(classes.icon, classes.icon_filled)
      : variant === "outlined"
      ? clsx(classes.icon, classes.icon_outlined)
      : clsx(classes.icon, classes.icon_lite);
  const checkedIconClass =
    variant === "filled"
      ? clsx(classes.icon, classes.icon_filled, classes.checkedIcon_filled)
      : variant === "outlined"
      ? clsx(classes.icon, classes.icon_outlined, classes.checkedIcon_outlined)
      : clsx(classes.icon, classes.icon_lite, classes.checkedIcon_lite);

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      indeterminate={false}
      color="primary"
      checkedIcon={<span className={checkedIconClass} />}
      icon={<span className={iconClass} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
};

export default CoolCheckbox;
