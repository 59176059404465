import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { Lookup } from "../../components/Lookup";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./Subscriptions.style";

export default function SubscriptionsCustomerSites(props: any) {
  const styles: any = useStyles();
  const getSubscriptionsCustomerSitesStatus = useStoreActions((state) => state.subscriptions.getSubscriptionsCustomerSitesStatus);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const selections = useStoreState((state) => state.selections.selections);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [customerSitesData, setCustomerSitesData] = useState<any>([]);
  const [lookupAnchor, setAnchor] = useState(null);
  const [filters, setFilters] = useState<any>({
    names: [],
    statuses: []
  });
  const [appliedFilters, setAppliedFilters] = useState<any>({
    names: [],
    statuses: []
  });
  const [clickedColumnName, setClickedColumnName] = useState<any>("");

  useEffect(() => {
    const filteredCustomerSites = _((Object.values(customerSitesData)))
      .filter((customerSite: any) => {

        const name = customerSite?.name;
        return appliedFilters.names.length
          ? appliedFilters.names.includes(name)
          : true;
      })
      .filter((customerSite: any) => {
        const status = customerSite?.isOk ? "OK" : "NOT OK";
        return appliedFilters.statuses.length
          ? appliedFilters.statuses.includes(status)
          : true;
      })

      .value();
    setFilteredData(filteredCustomerSites);

  }, [appliedFilters, customerSitesData]);

  useEffect(() => {

    const namesList: any[] = [];
    const statusesList: any = ["OK", "NOT OK"];

    Object.values(customerSitesData).map((customerSite: any) => {
      const name = customerSite?.name || "";

      if (namesList.indexOf(name) === -1) {
        namesList.push(name);
      }
      return true;
    });
    setFilters({ names: namesList, statuses: statusesList });

  }, [filteredData, appliedFilters]);

  const onApply = (selectedFilters: any) => {
    setAppliedFilters({
      ...appliedFilters,
      [clickedColumnName]: selectedFilters
    });
    setClickedColumnName("");
  };
  const handleFilterPopup = (event: any, clickedColumn: string) => {
    setClickedColumnName(clickedColumn);
    setAnchor(event.currentTarget);
  };
  const hasFilters = !!Object.values(appliedFilters).flat().length;

  useEffect(() => {
    selections?.customerId && getSubscriptionsCustomerSitesStatus(selections.customerId).then((res: any) => {
      setCustomerSitesData(res);
    }
    ).catch(({ message }: any) => {
      addMessage({ message });
    });
  }, [selections]);

  return (
    <>
      <ServiceNavigationBar
        title={t`Subscriptions customer sites status`}
        filters={<SelectionsMenu hideSiteSelection hideUnitSelection hideSystemSelection />}
        {...props}
      >

        {!selections.customerId ?
          (<Grid container direction={"column"} className={styles.noContentContainer}>
            <div className={styles.grayOval}>
              <ArrowIcon className={styles.arrowIcon} />
            </div>
            <Typography>
              {t`Please select a customer using the above filter.`}
            </Typography>
          </Grid>) : <>
            <Paper elevation={0} className={styles.paperPadding}>
              <TableContainer>
                <Table stickyHeader className="" aria-label="customized table">
                  <TableHead className={styles.tableHead}>
                    <TableRow>
                      <TableCell
                        className={styles.headCells}
                        onClick={(e: any) => handleFilterPopup(e, "names")}>
                        <div className={styles.headContainer}>
                          {t` Site Name`}
                          <FilterList
                            className={clsx(styles.filterStyle, {
                              [styles.blueFilter]: appliedFilters.names.length
                            })}
                          />
                        </div>
                      </TableCell>

                      <TableCell
                        className={styles.headCells}>
                        <div className={styles.headContainer}>
                          {t`Actual Units`}
                        </div>
                      </TableCell>

                      <TableCell
                        className={styles.headCells}>
                        <div className={styles.headContainer}>
                          {t`Subscription Units`}
                        </div>
                      </TableCell>

                      <TableCell
                        className={styles.headCells}
                        onClick={(e: any) => handleFilterPopup(e, "statuses")}
                      >
                        <div className={styles.headContainer}>
                          {t`Status`}
                          <FilterList
                            className={clsx(styles.filterStyle, {
                              [styles.blueFilter]: appliedFilters.statuses.length
                            })}
                          />
                        </div>
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.orderBy(filteredData, [(customerSite) => customerSite.name.toLowerCase()], ["asc"]).map((customerSite: any) => {
                      return (
                        <TableRow hover tabIndex={-1} key={customerSite.siteId} onDoubleClick={() => {
                          setSelections({ customerId: selections.customerId, siteId: customerSite.siteId, systemId: null, unitId: null });
                          props.history.push(`/subscriptions-site`);
                        }} >

                          <TableCell className={styles.rowCell} >
                            {customerSite.name}
                          </TableCell>

                          <TableCell className={styles.rowCell} >
                            {customerSite?.actualUnitCount}
                          </TableCell>

                          <TableCell className={styles.rowCell} >
                            {Object.keys(customerSite?.subscriptionUnitCount).reduce((acc: any, key: any) => Math.max(acc, customerSite?.subscriptionUnitCount[key]), 0)}
                          </TableCell>

                          <TableCell className={styles.rowCell} >
                            {customerSite?.isOk ? "OK" : "NOT OK"}
                          </TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            {clickedColumnName && (
              <Lookup
                filtersList={filters[clickedColumnName]}
                appliedFilters={appliedFilters[clickedColumnName]}
                onApply={onApply}
                lookupAnchor={lookupAnchor}
                onClose={() => setClickedColumnName("")}
                clearAllFilters={() => setAppliedFilters({
                  names: [],
                  statuses: []
                })}
                hasFilters={hasFilters}
              />
            )}
          </>
        }
      </ServiceNavigationBar>
    </>
  );
}
