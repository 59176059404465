import {
  Admin as AdminSdk,
  GlobalAdmin as sdkGlobalAdmin,
  Device as DeviceSdk,
} from "coolremote-sdk";
import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { localXhr } from "../services/localXhr";

export interface IDeviceModel {
  id: string;
  serial: string;
  pin?: string;
  name: string;
  isConnected: boolean;
  site: string;
  siteName?: string;
  units: string[];
  systems: string[];
  role: any;
  connected?: boolean;
  isRegistered: boolean;
  firmwareVersion?: any;
  customer?: any;
  customerId?: any;
  hvacLines?: any;
  deviceType?: number;
  disconnectedTimestamp?: number;
}

export interface IDeviceMap {
  [key: string]: IDeviceModel;
}

export interface IDevicesModel {
  allDevices: IDeviceMap;
  devices: any;
  connectedDevices: string[];
  setDevices: Action<IDevicesModel, { devices: any }>;
  setConnectedDevices: Action<IDevicesModel, string[]>;
  getDevices: Thunk<IDevicesModel>;
  getDevicesPackages: Thunk<IDevicesModel>;
  getDevicesBySerial: Thunk<IDevicesModel, { serial: string }>;
  getDevicesAssociations: Thunk<IDevicesModel>;
  deleteDevice: Thunk<IDevicesModel, string>;
  deletePackage: Thunk<IDevicesModel, string>;
  createPackage: Thunk<IDevicesModel, { data: any }>;
  updatePackage: Thunk<IDevicesModel, { id: string; data: any }>;
  removeDeletedDevice: Action<IDevicesModel, string>;
  getConnectedDevices: Thunk<IDevicesModel>;
  getDeviceWithState: Computed<IDevicesModel, any>;
  getDeviceMetaDate: Thunk<IDevicesModel, string>;
  chosenDeviceMetaData: any;
  setChosenDeviceMetaData: Action<IDevicesModel, any>;
  bootDevice: Thunk<IDevicesModel, string>;
  updateDevice: Thunk<IDevicesModel, unknown>;
  getDeviceById: Thunk<IDevicesModel, string>;
  controlDevice: Thunk<IDevicesModel, { deviceId: string; command: any }>;
  upgradeFirmware: Thunk<IDevicesModel, { deviceId: string; command: any }>;
  firmwareStatus: Thunk<IDevicesModel, { deviceId: string }>;
  getDeviceName: Computed<
    IDevicesModel,
    (id: string | undefined) => string | undefined
  >;
  convertUnitNames: Thunk<IDevicesModel, { siteId: string }>;
  convertZonesToGroups: Thunk<IDevicesModel, { siteId: string }>;
}

export const DevicesModel: IDevicesModel = {
  allDevices: {},
  devices: {},
  connectedDevices: [],
  chosenDeviceMetaData: {},
  setConnectedDevices: action((state, payload) => {
    state.connectedDevices = payload;
  }),

  setDevices: action((state, payload) => {
    state.devices = payload;
    const fetchedDevices: IDeviceMap = _(Object.values(payload.devices))
      .map((device: any) => {
        const addedDevice: IDeviceModel = { ...device };

        return addedDevice;
      })
      .keyBy("id")
      .value();

    state.allDevices = fetchedDevices;
  }),

  setChosenDeviceMetaData: action((state, payload) => {
    state.chosenDeviceMetaData = payload.data;
  }),

  getDevices: thunk(async actions => {
    return AdminSdk.getDevicesTableData()
      .then((data: any) => {
        actions.setDevices({ devices: data });
        return true;
      })
      .catch(() => false);
  }),

  getDevicesPackages: thunk(async actions => {
    return DeviceSdk.getDevicesPackages();
  }),

  getDevicesBySerial: thunk(async (actions, payload) => {
    return sdkGlobalAdmin.getDevicesBySerial(payload.serial);
  }),

  getDevicesAssociations: thunk(async actions => {
    return DeviceSdk.getDevicesAssociations();
  }),

  getConnectedDevices: thunk(async actions => {
    return localXhr
      .get(`${process.env.REACT_APP_ADMIN_SERVER_URL}/devices`)
      .then((data: any) => data.json())
      .then((data: any) => {
        actions.setConnectedDevices(data.data);
        return true;
      })
      .catch(e => {
        if (_.includes(e.message, "Failed to fetch")) {
          throw new Error("Failed to fetch from metaData server");
        }
        throw new Error(e);
      });
  }),

  getDeviceMetaDate: thunk(async (actions, payload) => {
    return localXhr
      .get(`${process.env.REACT_APP_ADMIN_SERVER_URL}/deviceMeta/${payload}`)
      .then((data: any) => data.json())
      .then((data: any) => {
        if (!data.success) {
          throw new Error(data.data);
        }

        actions.setChosenDeviceMetaData(data);

        return true;
      })
      .catch(e => {
        if (_.includes(e.message, "Failed to fetch")) {
          throw new Error("Failed to fetch from metaData server");
        }
        throw new Error(e);
      });
  }),

  getDeviceWithState: computed(state => {
    if (_.isEmpty(state.allDevices)) {
      return {};
    }

    const allDevicesWithState = _.reduce(
      state.allDevices,
      (obj: any, device: IDeviceModel) => {
        obj[device.serial] = { ...device, ...{ connected: false } };
        return obj;
      },
      {}
    );

    _.forEach(state.connectedDevices, deviceId => {
      if (!allDevicesWithState[deviceId]) {
        return;
      }

      allDevicesWithState[deviceId].connected = true;
    });
    return allDevicesWithState;
  }),

  bootDevice: thunk(async (actions, payload) => {
    return localXhr
      .get(
        `${process.env.REACT_APP_ADMIN_SERVER_URL}/deviceMeta/boot/${payload}`
      )
      .then((data: any) => data.json())
      .then((data: any) => {
        if (!data.success) {
          throw new Error(data.data);
        }
        return true;
      })
      .catch(e => {
        if (_.includes(e.message, "Failed to fetch")) {
          return new Error("Failed to fetch from metaData server");
        }
        return new Error(e);
      });
  }),

  updateDevice: thunk(async (actions, payload) => {
    return localXhr
      .post(payload, `${process.env.REACT_APP_ADMIN_SERVER_URL}/deviceMeta`)
      .then((data: any) => data.json())
      .then((data: any) => {
        if (!data.success) {
          throw new Error(data.data);
        }
        return true;
      })
      .catch(e => {
        if (_.includes(e.message, "Failed to fetch")) {
          throw new Error("Failed to fetch from metaData server");
        }
        throw new Error(e);
      });
  }),
  getDeviceById: thunk(async (actions, payload) => {
    return sdkGlobalAdmin.getDeviceById(payload);
  }),
  controlDevice: thunk(async (actions, payload) => {
    const { deviceId: id, command } = payload;
    return sdkGlobalAdmin.controlDevice(id, command);
  }),

  upgradeFirmware: thunk(async (actions, payload) => {
    const { deviceId: id, command } = payload;
    return sdkGlobalAdmin.upgradeFirmware(id, command);
  }),

  firmwareStatus: thunk(async (actions, payload) => {
    const id = payload;
    return sdkGlobalAdmin.firmwareStatus(id);
  }),

  getDeviceName: computed(state => id => {
    const noName = "-";

    if (!id) {
      return noName;
    }

    if (!state.allDevices[id]) {
      return noName;
    }

    return state.allDevices[id].name ? state.allDevices[id].name : noName;
  }),
  deleteDevice: thunk((actions, payload) => {
    DeviceSdk.delete(payload).then(() => {
      actions.removeDeletedDevice(payload);
    });
  }),
  deletePackage: thunk((actions, payload) => {
    return DeviceSdk.deletePackage(payload);
  }),
  createPackage: thunk((actions, payload) => {
    return DeviceSdk.createPackage(payload.data);
  }),
  updatePackage: thunk((actions, payload) => {
    return DeviceSdk.updatePackage(payload.id, payload.data);
  }),
  removeDeletedDevice: action((state, payload) => {
    const devicesMap = { ...state.allDevices };
    delete devicesMap[payload];
    state.allDevices = { ...devicesMap };
  }),
  convertUnitNames: thunk(async (actions, payload) => {
    return sdkGlobalAdmin.convertUnitNames(payload);
  }),
  convertZonesToGroups: thunk(async (actions, payload) => {
    return sdkGlobalAdmin.convertZonesToGroups(payload);
  }),
};
