import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%",
  },
  noContentContainer: {
    display: "flex",
    flex: 1,
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px",
  },
  arrowIcon: {
    fontSize: "91px",
    transform: "rotate(90deg)",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    height: 60
  },
  zoneName: {
    width: 130,
    minWidth: 130
  },
  statusesBarContainer: {
    display: "flex",
    minWidth: "calc(100% - 120px);"
  },
  statusBar: {
    width: 20,
    height: 20,
    marginRight: 2,
    "&:hover": {
      transform: "scale(1.5)"
    }
  },
  loader: {
    width: 100,
    height: 100,
    margin: "20vh 40%"
  },
  loaderContainer: {
    width: "100%",
    zIndex: 9,
    position: "absolute"
  }
}));
export default useStyles;