import { Typography } from "@mui/material";
import React from "react";
import { useStoreState } from "../../models/RootStore";
import { UserMenu } from "../../widgets/";
import useStyles from "./Header.style";

const Header: React.FC<any> = ({ history, title, filters, searchComponent }) => {
  const styles: any = useStyles();
  const userName = useStoreState((state) => state.users.me.firstName);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.leftSide}>
        {title && <Typography className={styles.headerTitle}>{title}</Typography>}
        {filters && filters}
      </div>

      {searchComponent ? searchComponent : <span />}
      <UserMenu
        userName={userName}
        history={history}
      />
    </div>
  );
};

export default Header;
