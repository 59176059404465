import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  buttonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "4px",
    background: theme.gradiants.button,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#fdfdfe",
    margin: 0,
    marginBottom: "15px",
    padding: 0,
  },
  impersonateButton: {
    margin: "10px 0",
    fontWeight: 400,
    width: "150px",
    height: "35px",
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tableHead: {
    height: "45px",
  },
  headCells: {
    border: "none",
    borderBottom: "none",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    fontFamily: theme.tables.headerCell.fontFamily,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    height: 45,
    fontSize: 13,
  },
  rowCell: {
    fontFamily: theme.tables.tableCell.fontFamily,
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    padding: theme.tables.tableCell.padding,
  },
  tooltipHeadCells: {
    border: "none",
    borderBottom: "none",
    color: theme.tables.headerCell.color,
    fontFamily: theme.tables.headerCell.fontFamily,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    height: 45,
    fontSize: 13,
    maxWidth: "100px",
  },
  tooltipRowCell: {
    fontFamily: theme.tables.tableCell.fontFamily,
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    padding: "3px 10px",
    textAlign: "center",
    maxWidth: "100px",
  },
  adormentsStyle: {
    fontsize: "20px",
  },
  searchBox: {
    marginBottom: "15px",
    width: "25%",
    "&>input": {
      fontSize: "16px",
    },
  },
  textFieldDialogStyle: {
    width: "70%",
    "&>input": {
      fontSize: "16px",
    },
    "&>label": {
      fontSize: "16px",
    },
    "&>textarea": {
      fontSize: "16px",
    },
  },
  createClinetModalContainer: {
    width: "500px",
  },
  paperPadding: {
    padding: "20px 21px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px",
  },
  pageContent: {
    display: "flex",
    maxHeight: "calc(100% - 100px)",
    flexFlow: "column nowrap",
    width: "100%",
    padding: "0px 30px",
  },

  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  blueFilter: {
    fill: "#1976d2",
  },
  filterStyle: {
    marginLeft: "10px",
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%",
  },
  noContentContainer: {
    display: "flex",
    flex: 1,
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px",
  },

  arrowIcon: {
    fontSize: "91px",
    transform: "rotate(90deg)",
  },
  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px",
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px",
  },
  searchIcon: {
    transform: "scale(0.7)",
    color: "#fff",
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px",
    },
  },
  dialogHeader: {
    borderBottom: theme.dialogs.header.borderBottom,
    display: "flex",
    justifyContent: "space-between",
    padding: "0 10px 0 20px",
    height: "60px",
    backgroundColor: theme.dialogs.header.backgroundColor,
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.dialogs.title.color,
  },
  closeIconBtn: {
    padding: "6px",
    marginRight: "-6px",
  },
  dialog: {
    width: "300px",
    height: "240px",
  },
  btnMargin: {
    margin: "auto",
  },
  iconBtnStyle: {
    position: "absolute",
    right: "24px",
    top: "16px",
  },
}));
export default useStyles;
