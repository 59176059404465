import {
    Button,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import useStyles from "./Device.style";
import { GlobalAdmin as sdkGlobalAdmin } from "coolremote-sdk";
import { useStoreActions } from "../../models/RootStore";

interface IDevice {
    startSerial: string;
    numOfDevices: number;
}

const DeviceSchema = Yup.object().shape({
    numOfDevices: Yup.number().required(t`Required`),
    startSerial: Yup.string().required(t`Required`),
})

export default function CreateRecord(props: any) {
    const { close } = props;
    const styles: any = useStyles();
    const [error, setError] = useState<any>(null);
    const { addMessage } = useStoreActions((action) => action.errorMessage);

    const handleExport = (records: any) => {
        const headers = "serial,pin,registrationLink";
        let rows = "";
        Object.values(records).forEach((record: any) => {
            const { serial, pin, registrationLink } = record;
            const row: any = [
                serial,
                pin,
                registrationLink
            ];
            rows += row.join(",") + "\r\n";
        });

        let link = window.document.createElement("a");
        link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(headers + "\r\n" + rows));
        link.setAttribute("download", "Records.csv");
        link.click();
    };

    const onSubmit = (values: any) => {
        const { startSerial, numOfDevices } = values;
        sdkGlobalAdmin.getDeviceRecordsByStartSerialAndCount(startSerial, numOfDevices)
            .then((res: any) => {
                !_.isEmpty(res) ? handleExport(res) : addMessage({ message: t`No Data Found!` })
            })
            .catch((err: any) => addMessage({ message: err.message }))
            .finally(close)
    }

    return (
        <div>
            <SimpleModal
                showModal={!!error}
                title={t`Error had happened`}
                contentText={error ? error : ""}
                actionName={t`OK`}
                onAction={() => {
                    setError(null);
                }}
                closeModal={() => {
                    setError(null);
                }}
            />

            <Formik
                initialValues={{
                    numOfDevices: 1,
                }}
                enableReinitialize={true}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={DeviceSchema}
                autoComplete="off"
            >
                {(props) => {
                    const { values, errors, handleChange, handleSubmit }: any = props;
                    return (
                        <form className={styles.fieldsContainer} onSubmit={handleSubmit}>
                            <div className={styles.twoFieldsRow}>
                                <TextField
                                    label={t`start Serial`}
                                    id="startSerial"
                                    helperText={errors.startSerial}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.startSerial || ""}
                                    error={errors.startSerial}
                                    {...props}
                                />
                                <TextField
                                    label={t`num Of Devices`}
                                    id="numOfDevices"
                                    helperText={errors.numOfDevices}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.numOfDevices || ""}
                                    error={errors.numOfDevices}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    {...props}
                                />
                            </div>
                            <div className={styles.actionsContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={close}
                                    className={styles.cancelBtn}
                                >
                                    {t`Cancel`}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={styles.submitButton}
                                >
                                    {t`Stickers`}
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
}
