
import React, { useState } from 'react';
import {
  Button,
  InputLabel,
  Autocomplete,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { t } from "ttag";
import useStyles from "./DeviceMigration.style";
import clsx from "clsx";
import _ from "lodash";
import Site from "../SiteManagement/Site"

import { useHistory } from "react-router-dom";

const SiteDetection = (props: any) => {
  const { sites, nextStep, goBack, customerId } = props;
  const styles: any = useStyles();
  const [siteId, setSiteId] = useState<any>(null);
  const [siteMethod, setSiteMethod] = useState<string>("select");
  const [error, setError] = useState<string>("");
  const history = useHistory();


  const submit = (id: string) => {
    if (!id) {
      setError("Please select Site or create one")
      return;
    }
    nextStep(id)
  }

  return (
    <div className={styles.customerPageContainer}>
      <Typography className={styles.errorMsg}>{error || ""}</Typography>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="monthSubUnit"
          name="siteMethod"
          value={siteMethod}
          onChange={(event: any) => setSiteMethod(event.target.value)}
        >
          <FormControlLabel value="select" control={<Radio className={styles.radio} />} label="Select Existing Site" />
          <FormControlLabel value="create" control={<Radio className={styles.radio} />} label="Create New Site" />
        </RadioGroup>
      </FormControl>
      {siteMethod === "select" ? <div className={styles.existingCustomerContainer}>
        <InputLabel className={clsx(styles.fieldLabel, styles.smallField)} >
          <Autocomplete
            options={sites}
            id="customer-list"
            value={siteId}
            defaultValue={""}
            onChange={(e: any, value: any) => setSiteId(value)}
            getOptionLabel={(option: any) => option.label || ""}
            className={styles.autocomplete}
            renderInput={(params: any) => (
              <TextField
                variant="filled"
                {...params}
                name={"test-random-name-detection"}
                inputProps={{
                  ...params.inputProps,
                  disableUnderline: true,
                  autoComplete: "new-password",
                  form: {
                    autocomplete: 'off',
                  }
                }}
              />
            )}
          />

        </InputLabel>
        <div className={styles.actionsContainer}>
          <Button
            variant="contained"
            color="primary"
            className={styles.backBtn}
            onClick={() => goBack()}
          >
            {t`Back`}
          </Button>
          <Button
            title={t`save site`}
            variant="contained"
            color="primary"
            type="submit"
            className={styles.nextBtn}
            onClick={() => submit(siteId?.value)}
          >
            {t`Next`}
          </Button>
        </div>

      </div> : (
        <Site
          handleCloseDialog={() => { }}
          history={history}
          match={{ params: { customerId: customerId } }}
          submit={submit}
          goBack={goBack}
          {...props}
        />
      )}
    </div>

  );
};

export default SiteDetection;