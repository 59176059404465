import { GlobalAdmin } from "coolremote-sdk";
import { Thunk, thunk, } from "easy-peasy";

export interface IServicesModel {
  getAvailabilities: Thunk<IServicesModel, { startTimeUTC: number, endTimeUTC: number }>;
}

export const servicesModel: IServicesModel = {
  getAvailabilities: thunk((actions, params) => {
    return GlobalAdmin.getAvailabilities(params);
  }),

};
