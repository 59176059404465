import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  IconButton
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
// @ts-ignore
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./DirectUsers.style";
import { IUserModel } from "../../models/UsersModel";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import AddEditUser from "../UserManagementNew/AddEditUser";
import { GlobalAdmin as SdkGlobalAdmin } from "coolremote-sdk"
import ErrorBox from "../../widgets/ErrorBox/ErrorBox";
import { Delete } from "../../icons";

// The main screen
export default function DirectUsers(props: any) {
  const styles: any = useStyles();
  const [filteredUsers, setfilteredUsers] = useState<any>([]);
  const [searchVal, setSearchVal] = useState<string>("");
  const [submit, setSubmit] = useState<boolean>(false);
  const getUserByUsername = useStoreActions((actions) => actions.users.getUserByUsername);
  const getUserFullPolicies = useStoreActions((actions) => actions.users.getUserFullPolicies);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const [editModal, setEditModal] = useState(false);
  const [chosenUser, setChosenUser] = useState<IUserModel | null>(null);
  const onUpdate = useStoreActions((actions) => actions.users.updateUser);
  const [errorOnUpdate, seterrorOnUpdate] = useState<string>("");
  const [users, setUsers] = useState<any>({});
  const selections = useStoreState((state) => state.selections.selections);
  const customersBasicList = useStoreState((state) => state.customers.customersBasicList);
  const [showErr, setShowErr] = useState<string>("");
  const [deleteId, setDeleteId] = useState<any>(null);

  useEffect(() => {
    (async () => {
      if (submit && searchVal) {
        getUserByUsername({ username: searchVal })
          .then((res: any) => {
            setfilteredUsers(Object.values(res));
          })
          .catch((err: any) => addMessage({ message: err.message }))
          .finally(() => {
            setSubmit(false);
          })
      }
    })();
  }, [submit]);


  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setSubmit(true)
    }
  };

  const onUpdateUser = async (id: string, data: any) => {
    SdkGlobalAdmin.updateUser(id, data)
      .then(() => {
        setChosenUser(null);
        setEditModal(false);
        seterrorOnUpdate("");
        setShowErr("");
        setUsers({ ...users, [id]: { ...users[id], ...data, customer: selections?.customerId } });
      })
      .catch((err: any) => {
        if (err.message === "username already exists" || err.message === "\"email\" must be a valid email") {
          seterrorOnUpdate(err.message);
          setShowErr(err.message);
          return;
        }
      })
  };

  const onErrorModal = () => {
    if (!showErr.length) {
      return <div />;
    }

    return (
      <ErrorBox
        error={showErr}
        onClose={() => {
          updateUserClose();
          setShowErr("");
        }}
      />
    );
  };

  const updateUserClose = () => {
    setEditModal(false);
    setChosenUser(null);
    seterrorOnUpdate("");
    setShowErr("");
  }

  const onCloseDeleteDialog = () => {
    setDeleteId(null);
    setChosenUser(null);
  };

  const handleDelete = () => {
    onCloseDeleteDialog()
    if (!chosenUser) {
      return;
    }
    SdkGlobalAdmin.deleteUser(deleteId)
      .then(() => {
        setfilteredUsers(filteredUsers.filter((user: { id: string; }) => user.id !== deleteId))
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      })
  };

  return (
    <ServiceNavigationBar
      title={t`Users Management`}
      searchComponent={
        <Input
          placeholder={t`User Name`}
          value={searchVal}
          onChange={(event: any) => setSearchVal(event.target.value)}
          disableUnderline={true}
          onKeyDown={handleKeyDown}
          classes={{ root: styles.inputRoot }}
          endAdornment={
            searchVal && (
              <IconButton
                onClick={() => setSubmit(true)}
                className={styles.searchIcon}
              >
                <Search />
              </IconButton>
            )
          }
        />
      }
      {...props}
    >
      {onErrorModal()}
      <Paper elevation={0} className={styles.paperPadding}>
        <TableContainer>
          <Table className="" aria-label="customized table">
            <TableHead className={styles.tableHead}>
              <TableRow>
                <TableCell className={styles.headCells} align="left" >
                  <div className={styles.headContainer}>
                    {t`USER NAME`}
                  </div>
                </TableCell>
                <TableCell className={styles.headCells} align="left" >
                  <div className={styles.headContainer}>
                    {t`FIRST NAME`}
                  </div>
                </TableCell>
                <TableCell className={styles.headCells} align="left" >
                  <div className={styles.headContainer}>
                    {t`LAST NAME`}
                  </div>
                </TableCell>
                <TableCell align="center" className={styles.headCells} >
                  {t`DELETE`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user: IUserModel) => {
                return (
                  <TableRow hover tabIndex={-1} key={user.id} onDoubleClick={async () => {
                    const u = await getUserFullPolicies({ userId: user.id });
                    setChosenUser(u);
                    setEditModal(true);
                  }} >
                    <TableCell className={styles.rowCellHyperLink} component="th" scope="row" align="left" >
                      {user.username}
                    </TableCell>
                    <TableCell className={styles.rowCell} component="th" scope="row" align="left" >
                      {user.firstName}
                    </TableCell>
                    <TableCell className={styles.rowCell} component="th" scope="row" align="left" >
                      {user.lastName}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center" className={styles.rowCell} >
                      <IconButton onClick={() => { setDeleteId(user.id); setChosenUser(user); }}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {deleteId && chosenUser && <SimpleModal
        showModal={deleteId && chosenUser}
        title={t`Delete User`}
        contentText={t`Are you sure you want to delete ${chosenUser?.username}`}
        actionName={t`Delete`}
        onAction={handleDelete}
        closeModal={onCloseDeleteDialog}
      >
      </SimpleModal>}

      {
        editModal && chosenUser && (
          <SimpleModal showModal={true} title={t`Update User info`} hideBtns closeModal={updateUserClose} fullWidth={true}>
            <IconButton disableRipple className={styles.iconBtnStyle} onClick={updateUserClose}>
              <Close />
            </IconButton>
            <AddEditUser
              user={chosenUser}
              onUpdate={onUpdateUser}
              onClose={updateUserClose}
              mainButtonLabel={t`Update`}
              customers={customersBasicList}
              errorText={errorOnUpdate}
              setChoseUser={setChosenUser}
              selectedCustomer={selections?.customerId || ""}
            />
          </SimpleModal>
        )
      }
    </ServiceNavigationBar>
  );
}
