import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  buttonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "4px",
    backgroundImage: theme.gradiants.button,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#fdfdfe",
    margin: 0,
    marginBottom: "15px",
    padding: 0,
  },
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
  },

  section: {
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "92%",
    boxSizing: "border-box",
  },

  sectionTitle: {
    fontWeight: "bold",
    marginBottom: "10px",
    borderBottom: "1px rgb(87, 69, 92) solid",
  },
  fieldGridContainer: {
    "&>button": {
      width: "144px",
    },
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 0",
    marginBottom: "30px",
  },
  siteDetailsHeader: {
    padding: "4px 20px",
    boxShadow: "0px 5px 5px 0px rgba(214, 206, 214, 1)",
    boxSizing: "border-box",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  saveButtonStyle: {
    width: "24%",
    color: "white",
    backgroundColor: "#331e38",
    marginLeft: "20px",
  },
  editSiteTitle: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  fieldTitleGrid: {
    alignSelf: "center",
  },

  fieldBtnGrid: {
    display: "flex",
    justifyContent: "space-evenly",
  },

  filedTitle: {
    fontWeight: "bold",
    fontSize: "16px",
  },

  textField: {
    width: "100%",
    height: "4rem",
    lineHeight: "0",
    "&>input": {
      border: "1px solid gray",
      borderRadius: "3px",
      fontSize: "20px",
    },
  },

  fieldInputGrid: {
    flexDirection: "unset",
  },

  textFieldValue: {
    width: "6rem",
  },

  selector: {
    width: "100%",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "10px 1rem",
  },

  bootTitle: {
    fontWeight: "bold",
    fontSize: "16px",
  },

  editDeviceWrapper: {
    height: "74vh",
    width: "100%",
  },

  bootWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },

  paperPadding: {
    padding: "20px 21px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "200px",
  },
  adormentsStyle: {
    fontSize: "20px",
  },
  searchBox: {
    marginBottom: "15px",
    width: "25%",
    "&>input": {
      fontSize: "16px",
    },
  },
  tableHead: {
    height: "45px",
    backgroundColor: "rgb(239, 239, 242, 0.48)",
  },
  headCells: {
    border: "none",
    padding: "0 15px",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    fontFamily: theme.tables.headerCell.fontFamily,
    height: 45,
    fontSize: 13,
  },
  rowCell: {
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    fontFamily: theme.tables.tableCell.fontFamily,
    padding: theme.tables.tableCell.padding,
  },
  rowCellHyperLink: {
    color: "blue",
    fontSize: theme.tables.tableCell.fontSize,
    fontFamily: theme.tables.tableCell.fontFamily,
    padding: theme.tables.tableCell.padding,
    textDecoration: "underline",
    cursor: "pointer",
  },

  consoleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "65%",
    height: "100%",
    "&>div": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    "&:first-child": {
      height: "auto",
      maxWidth: "100%",
      minHeight: "auto",
    },
  },

  siteDetailsForm: {
    width: "30%",
    height: "90%",
    "&>label": {
      minWidth: "125px",
      fontSize: "12px",
      fontWeight: "bold",
    },
    "&>div": {
      padding: "0 6px",
      boxSizing: "border-box",
      fontSize: "12px",
    },
  },
  deviceInfoTitle: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    padding: "8px 6px",
    boxShadow: "0px 6px 7px 0px rgba(214, 206, 214, 1)",
    marginBottom: "10px",
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  blueFilter: {
    fill: "#1976d2",
  },
  filterStyle: {
    marginLeft: "10px",
  },
  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px",
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px",
  },
  searchIcon: {
    transform: "scale(0.7)",
  },
  iconBtnStyle: {
    position: "absolute",
    right: "24px",
    top: "16px",
  },
}));
export default useStyles;
