import { GlobalAdmin } from "coolremote-sdk";
import { action, Action, Computed, computed, thunk, Thunk } from "easy-peasy";
import _ from "lodash";

export interface ICustomer {
  id: string;
  name?: string;
  description?: string;
  enableTriggers?: boolean;
  sites: string[];
  triggers: string[];
  role: any;
  type?: any;
  smsCounter?: any;
  contactPerson?: any;
  email?: string;
  status?: number;
  billingModel?: number;
  prePaidSessionLengthMinutes?: number;
  prePaidAllocatedSessions?: number;
  prePaidUsedSessions?: number;
  createdAt?: any;
  licensedUnits?: any;
  monthSmsCount?: any;
}

export interface ICustomerModel {
  numOfDevices?: number;
  region: any;
  id: string;
  name: string;
  description: string;
  admin: string;
  enableTriggers: boolean;
  sites: string[];
  users: string[];
  groups: string[];
  alertGroups: string[];
  triggers: string[];
  invites: string[];
  type?: any;
  smsCounter?: any;
  contactPerson?: any;
  email?: string;
  status?: number;
  billingModel?: number;
  prePaidSessionLengthMinutes?: number;
  prePaidAllocatedSessions?: number;
  prePaidUsedSessions?: number;
  createdAt?: any;
  enablePowerDistribution?: boolean;
  enableMgmtAppAccess?: boolean;
  enableServiceAppAccess?: boolean;
  enableCommercialAppAccess?: boolean;
  category: number;
}

export interface ICustomerMap {
  [key: string]: ICustomerModel;
}
export interface IBasicCustomerMap {
  [id: string]: { name: string; id: string };
}

export interface ICustomersModel {
  reducedCustomers: ICustomerMap;
  customersBasicList: IBasicCustomerMap;
  setCustomersBasicList: Action<ICustomersModel, IBasicCustomerMap>;
  setReducedCustomers: Action<ICustomersModel, any>;
  getReducedCustomers: Thunk<ICustomersModel>;
  getBasicCustomers: Thunk<ICustomersModel>;
  createCustomer: Thunk<ICustomersModel, { data: any }>;
  addCustomer: Action<ICustomersModel, ICustomerModel>;
  removeCustomer: Action<ICustomersModel, string>;
  deleteCustomer: Thunk<ICustomersModel, string>;
  updateCustomer: Thunk<ICustomersModel, { data: any; id: string }>;
  _updateCustomer: Action<ICustomersModel, ICustomerModel>;
  getCustomerById: Thunk<ICustomerModel, any>;
  getCustomerName: Computed<
    ICustomersModel,
    (id: string | undefined) => string | undefined
  >;

  getCustomerSites: Thunk<ICustomersModel, string>;
  getCustomerDevices: Thunk<ICustomersModel, string>;
  _updateReducedCustomers: Action<ICustomersModel, any>;
  getCustomersByCustomerName: Thunk<ICustomersModel, string>;
}

export const CustomersModel: ICustomersModel = {
  customersBasicList: {},
  reducedCustomers: {},
  setCustomersBasicList: action((state, payload) => {
    state.customersBasicList = payload;
  }),

  setReducedCustomers: action((state, payload) => {
    state.reducedCustomers = payload;
  }),
  _updateReducedCustomers: action((state, payload) => {
    if (state.reducedCustomers[payload.id])
      state.reducedCustomers[payload.id] = {
        ...state.reducedCustomers[payload.id],
        ...payload.data,
      };
  }),

  addCustomer: action((state, payload) => {
    state.customersBasicList[payload.id] = payload;
    state.reducedCustomers[payload.id] = payload;
  }),

  removeCustomer: action((state, payload) => {
    delete state.customersBasicList[payload];
    delete state.reducedCustomers[payload];
  }),

  _updateCustomer: action((state, payload) => {
    state.customersBasicList[payload.id] = {
      id: payload.id,
      name: payload.name,
    };
  }),

  getReducedCustomers: thunk(async actions => {
    return GlobalAdmin.getReducedCustomers().then((customers: any) => {
      actions.setReducedCustomers(customers);
      return customers;
    });
  }),

  getBasicCustomers: thunk(async actions => {
    return GlobalAdmin.getBasicCustomers().then(
      (customers: IBasicCustomerMap) => {
        Object.keys(customers).forEach(id => (customers[id].id = id));
        actions.setCustomersBasicList(customers);
        return customers;
      }
    );
  }),
  createCustomer: thunk(async (actions, payload) => {
    return GlobalAdmin.createCustomer(payload.data)
      .then((data: any) => {
        actions.addCustomer(data);
        return data;
      })
      .catch((e: any) => {
        throw e;
      });
  }),
  deleteCustomer: thunk(async (actions, payload) => {
    return GlobalAdmin.deleteCustomer(payload)
      .then(() => {
        actions.removeCustomer(payload);
        return true;
      })
      .catch(() => false);
  }),

  updateCustomer: thunk(async (actions, payload) => {
    return GlobalAdmin.updateCustomer(payload.id, payload.data)
      .then((data: any) => {
        actions._updateCustomer(data);
        actions._updateReducedCustomers(payload);
        return true;
      })
      .catch(() => false);
  }),
  getCustomerById: thunk(async (actions, payload) => {
    return GlobalAdmin.getCustomerById(payload);
  }),
  getCustomerName: computed(state => id => {
    const noName = "-";
    if (
      !id ||
      !state.customersBasicList[id] ||
      !state.customersBasicList[id].name
    ) {
      return noName;
    }
    return state.customersBasicList[id].name;
  }),

  getCustomerSites: thunk((actions, payload) => {
    return GlobalAdmin.getCustomerSites(payload);
  }),

  getCustomerDevices: thunk(async (actions, payload) => {
    return GlobalAdmin.getCustomerDevices(payload);
  }),

  getCustomersByCustomerName: thunk((actions, payload) => {
    return GlobalAdmin.getCustomersByCustomerName(payload);
  }),
};
