import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  fieldsContainer: {
    display: "flex",
    width: "100%",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "10px",
  },
  numField: {
    width: "100px",
    margin: "5px",
  },
  smallField: {
    width: "100%",
    margin: "5px",
  },
  twoFieldsRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  actionsContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
  },
  submitButton: {
    marginTop: "20px",
    display: "flex",
    alignSelf: "flex-end",
  },
  cancelBtn: {
    marginTop: "20px",
    color: "#4b1c46",
    width: "7rem",
    display: "flex",
    alignSelf: "flex-end",
    backgroundColor: "#ffffff",
    marginRight: "1rem",
  },
}));

export default useStyles;
