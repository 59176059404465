import { Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import { tz } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { EditIcon } from "../../iconImages";
import { ArrowDownO, CheckboxChecked } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import { countries } from "./countryList";
import useStyles from "./SiteManagement.style";
import { stateList } from "./stateList";

const timeZones = tz.names().map((timezone) => ({
  value: timezone,
  label: `${timezone}  ${tz(timezone).format("Z")}`
}));
const stateOptions = stateList.map((state: any) => ({
  value: state.value,
  label: state.label
}));
const countryOptions = countries.map((country: any) => ({
  value: country.code,
  label: country.name
}));

const packageTypes: any = {
  "HVAC Control": "normal",
  "Professional Packages": "nested"
};

const initMainPackageFlags: any = {
  "HVAC Control": false,
  "Professional Packages": false
};

const SiteDetails = (props: any) => {
  const styles: any = useStyles();
  const [site, setSite] = useState<any>({});
  const [editable, setEditable] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [mainPackageFlags, setMainPackageFlags] = useState<any>(initMainPackageFlags);
  const { sitePackagesNamesEnums = {}, sitePackagesFlagsEnums = {} } = useStoreState((state) => state.types);
  const commercialPackageEnumMapped: any = Object.keys(sitePackagesFlagsEnums).reduce((acc: any, cur: string) => {
    if (sitePackagesFlagsEnums[+cur] === "hvacControl" || +cur === 1) { return acc }
    acc[sitePackagesFlagsEnums[+cur]] = sitePackagesNamesEnums[+cur];
    return acc;
  }, {});

  const initCommercialPackage: any = {
    basicHvacOperations: {
      "value": false,
      "name": commercialPackageEnumMapped["basicHvacOperations"]
    },
    hvacAdvancedOperations: {
      "value": false,
      "name": commercialPackageEnumMapped["hvacAdvancedOperations"]
    },
    basicRemoteDiagnostics: {
      "value": false,
      "name": commercialPackageEnumMapped["basicRemoteDiagnostics"]
    },
    advancedRemoteDiagnostics: {
      "value": false,
      "name": commercialPackageEnumMapped["advancedRemoteDiagnostics"]
    },
    predictiveMaintenance: {
      "value": false,
      "name": commercialPackageEnumMapped["predictiveMaintenance"]
    },
  }
  const [commercialPackageFlags, setCommercialPackageFlags] = useState<any>({ ...initCommercialPackage });
  const getSite = useStoreActions((actions) => actions.sites.getSite);
  const updateSite = useStoreActions((actions) => actions.sites.updateSite);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const { mySupportedPages = "all" } = useStoreState((s) => s.users);

  const {
    invoicingPeriods = {},
    siteCategories = {},
    currencyTypes = {},
    sitePhases = {},
    siteOnboardingStatus = {}
  } = useStoreState((s) => s.types);

  const showAllPages = mySupportedPages === "all";

  const invoicingPeriodOptions = Object.entries(invoicingPeriods).map(([label, value]) => ({ value, label }));
  const onboardingStatuses = Object.entries(siteOnboardingStatus).map(([label, value]) => ({ value, label }));
  const currencies = Object.entries(currencyTypes).map(([label, value]) => ({ value, label }));
  const sitePhasesOptions = Object.entries(sitePhases).map(([label, value]) => ({ value, label }));
  const categories = Object.entries(siteCategories).map(([label, value]) => ({ value, label }));

  useEffect(() => {
    if (_.isEmpty(site)) {
      return;
    }
    setInitialflags(site);
  }, [site]);

  const setInitialflags = (site: any) => {
    mainPackageFlags["HVAC Control"] = site?.hvacControl;
    mainPackageFlags["Professional Packages"] = Object.keys(commercialPackageFlags).reduce((acc, cur) => {
      return acc && (cur === "hvacControl" || site[cur])
    }, true)

    Object.keys(commercialPackageFlags || {}).forEach(key => {
      commercialPackageFlags[key].value = !!site[key]
    })
    setMainPackageFlags(mainPackageFlags);
    setCommercialPackageFlags(commercialPackageFlags);
  };

  const { match, history } = props;
  const { params } = match;
  const { siteId } = params;
  const {
    name,
    description,
    country,
    city,
    address,
    postalCode,
    state,
    timezone,
    customer,
    category,
    onBoardingStatus,
    currency,
    invoicingPeriod,
    ////////////////////////
    phase,
    renewalDate,
    connectedIDUs,
    contractedIDUs,
    createdAt,
    devices,
    // numberOfDevices,
    contractTimeframe,
    financeContact,
    notes,
    customerSummary,
    PO,
    invoice,
    priorityID,
    discountPercent,
    pricePerIDU
  } = site;
  const numberOfDevices = devices?.length || 0;
  const validationSchema = Yup.object({
    name: Yup.string().required(t`required field`),
    country: Yup.string().required(t`required field`),
    city: Yup.string().required(t`required field`),
    address: Yup.string().required(t`required field`),
    timezone: Yup.string().required(t`required field`)
  });

  useEffect(() => {
    (async () => {
      if (!_.isEmpty(site) || !siteId) {
        return;
      }

      getSite(siteId).then(async (data: any) => {
        setSite(data);
      });
    })();

  }, [getSite, site, siteId]);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    setError("");
    let commercialPackageValues: any = [];
    Object.keys(commercialPackageFlags).forEach((option: any) => commercialPackageValues[option] = commercialPackageFlags[option].value);

    let atLeastOneCommercialEnabled = Object.values(commercialPackageValues).some((enabled: any) => enabled);

    if (!atLeastOneCommercialEnabled && !mainPackageFlags["HVAC Control"]) {
      setError("at least one package needs to be enabled")
      setSubmitting(false);
      return;
    }
    const packagesObj = {
      hvacControl: mainPackageFlags["HVAC Control"],
      ...commercialPackageValues
    };
    const renewalDate = values?.renewalDate ? new Date(values.renewalDate)?.getTime() : null;
    const siteData = {
      ...values,
      renewalDate,
      postalCode: values.zip,
      ...packagesObj
    };
    delete siteData.zip;
    delete siteData.createdAt;
    delete siteData.numberOfDevices;
    delete siteData.PO;
    delete siteData.invoice;
    !renewalDate && delete siteData.renewalDate;

    updateSite({
      id: siteId,
      data: siteData
    })
      .then(() => {
        setSite({ ...site, ...siteData });
        setEditable(false);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      })
      .finally(() => setSubmitting(false));

  };

  const StyledTreeItem = (props: any) => {
    const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, ...other } = props;
    return (
      <TreeItem
        onClick={(event: any) => { if (!editable) { event.preventDefault(); event.stopPropagation(); } }}
        disabled={!editable}
        label={
          <div className={clsx(styles.labelRoot, { [className]: className })}>
            {LabelIcon && <LabelIcon color="inherit" className={styles.labelIcon} />}
            {node && node}
            <Typography variant="body2" className={styles.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor
        }}
        classes={{
          root: styles.root,
          content: !editable ? styles.disabledContent : styles.content,
          expanded: styles.expanded,
          selected: styles.selected,
          group: styles.group,
          label: !editable ? styles.disabledLabel : styles.label,
          iconContainer: styles.iconContainer
        }}
        {...other}
      />
    );
  };

  const Checkbox1 = (props: any) => {
    return <Checkbox
      color="default"
      edge="end"
      variant="outlined"
      disabled={!editable}
      onChange={() => { }}
      onClick={(event: any) => event.stopPropagation()}
      checkedIcon={<CheckboxChecked />}
      className={clsx(styles.smallCheckbox, { [props.className]: props.className })}
      {...props}
    />;
  };

  const StyledItem = ({ option, onChange, subItem = false }: any) => {
    const mainFlag = packageTypes[option] === "nested" ? Object.values(commercialPackageFlags).every((option: any) => option.value) : mainPackageFlags[option];
    const checked = (subItem ? commercialPackageFlags[option].value : mainFlag) || false;
    const label = subItem ? commercialPackageFlags[option].name : option;
    const indeterminate = packageTypes[option] === "nested" ? Object.values(commercialPackageFlags).some((option: any) => option.value) : false;

    return (<StyledTreeItem
      key={option}
      nodeId={option}
      labelText={label}
      disabled={!editable}
      styles={{ iconContainer: styles.customerIconContainer, group: styles.customerGroup, label: styles.label }}
      color="#545964"
      bgColor="#fff"
      node={<Checkbox1
        indeterminate={!checked && indeterminate}
        checked={checked}
        onChange={onChange}
      />}
    >
      {packageTypes[option] === "nested" && (
        Object.keys(commercialPackageFlags).map((subItem: any) => {
          return <StyledItem key={subItem} option={subItem} subItem={true} onChange={(event: any) => {
            setCommercialPackageFlags({ ...commercialPackageFlags, [subItem]: { ...commercialPackageFlags[subItem], value: event.target.checked } });
            setMainPackageFlags({ ...mainPackageFlags, "HVAC Control": false });
          }} />;
        })
      )}
    </StyledTreeItem>);
  };

  return (
    <ServiceNavigationBar title={name} {...props}>
      <div className={styles.pageContainer}>

        <div className={styles.siteDetailsContainer}>
          <Formik
            initialValues={{
              name,
              description,
              country,
              city,
              address,
              zip: postalCode,
              timezone,
              state,
              category,
              onBoardingStatus,
              currency,
              invoicingPeriod,
              phase,
              renewalDate,
              connectedIDUs,
              contractedIDUs,
              createdAt,
              numberOfDevices,
              contractTimeframe,
              financeContact,
              notes,
              customerSummary,
              PO,
              invoice,
              priorityID,
              discountPercent,
              pricePerIDU
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                handleReset,
                dirty,
                isSubmitting
              } = props;
              return (
                <form onSubmit={handleSubmit} className={styles.siteDetailsForm}>
                  <div className={styles.siteDetailsHeader}>
                    <Typography className={styles.editSiteTitle}>
                      {t`Site Details`}
                    </Typography>
                    <Typography className={styles.errorMessage}>{error}</Typography>
                    {isSubmitting && (
                      <CircularProgress className={styles.loader} />
                    )}
                    {!editable && (
                      <IconButton onClick={() => setEditable(true)}>
                        <img src={EditIcon} alt="edit" />
                      </IconButton>
                    )}
                    {editable && (
                      <div style={{ display: "flex" }}>
                        <IconButton type="submit" disabled={isSubmitting}>
                          <Check className={styles.siteDetailsIcons} />
                        </IconButton>
                        <IconButton
                          disabled={isSubmitting}
                          onClick={() => {
                            handleReset();
                            setInitialflags(site);
                            setEditable(false);
                          }}
                        >
                          <Close className={styles.siteDetailsIcons} />
                        </IconButton>
                      </div>
                    )}
                  </div>
                  <div className={styles.siteDetailsFieldsContainer}>
                    <AdvancedInputField
                      name="name"
                      label={t`Name`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="description"
                      label={t`Description`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="address"
                      label={t`Address`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="city"
                      label={t`City`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      value={values.state || ""}
                      name="state"
                      options={stateOptions}
                      label={t`State`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="zip"
                      label={t`Zip`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      value={values.country || ""}
                      name="country"
                      label={t`Country`}
                      options={countryOptions}
                      editable={editable}
                      {...props}
                    />

                    <AdvancedInputField
                      select
                      value={values.timezone}
                      name="timezone"
                      label={t`Timezone`}
                      options={timeZones}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      value={values.category}
                      name="category"
                      label={t`Category`}
                      options={categories}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      value={values.onBoardingStatus}
                      name="onBoardingStatus"
                      label={t`onboarding status`}
                      options={onboardingStatuses}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      value={values.phase}
                      name="phase"
                      label={t`Phase`}
                      options={sitePhasesOptions}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      datePicker
                      name="renewalDate"
                      label={t`Renewal Date`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="connectedIDUs"
                      label={t`IDU connected`}
                      editable={false}
                      type={"number"}
                      min={0}
                      {...props}
                    />
                    <AdvancedInputField
                      name="contractedIDUs"
                      label={t`IDU contracted`}
                      editable={editable}
                      type={"number"}
                      min={0}
                      {...props}
                    />
                    <AdvancedInputField
                      name="createdAt"
                      label={t`Service start date`}
                      editable={false}
                      {...props}
                    />
                    <AdvancedInputField
                      name="numberOfDevices"
                      label={t`#Devices`}
                      type={"number"}
                      min={0}
                      editable={false}
                      {...props}
                    />
                    <AdvancedInputField
                      name="contractTimeframe"
                      label={t`Contract timeframe`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="financeContact"
                      label={t`Finance contact`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="notes"
                      label={t`Notes`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="customerSummary"
                      label={t`Customer Summary`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="PO"
                      label={t`PO#`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="invoice"
                      label={t`Invoice #`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="priorityID"
                      label={t`Priority ID#`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      value={values.currency}
                      name="currency"
                      label={t`Currency`}
                      options={currencies}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="pricePerIDU"
                      label={t`Price Per IDU`}
                      editable={editable}
                      type={"number"}
                      min={0}
                      {...props}
                    />
                    <AdvancedInputField
                      name="discountPercent"
                      label={t`Discount %`}
                      editable={editable}
                      type={"number"}
                      min={0}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      value={values.invoicingPeriod}
                      name="invoicingPeriod"
                      label={t`Invoicing period`}
                      options={invoicingPeriodOptions}
                      editable={editable}
                      {...props}
                    />

                    <div className={styles.flagsTreeContainer}>
                      <TreeView
                        className={styles.root}
                        disableSelection={!editable}
                        defaultExpanded={["Professional Packages"]}
                        defaultCollapseIcon={<><div className={styles.arrowContainer} /> <ArrowDownO className={styles.arrow} /> </>}
                        defaultExpandIcon={<><div className={styles.arrowContainer} /> <ArrowDownO className={clsx(styles.arrow, styles.rotateArrow)} /></>}
                        defaultEndIcon={<div className={styles.arrowContainer} />}
                      >
                        {mainPackageFlags && Object.keys(mainPackageFlags).map((option: string) =>
                          <StyledItem key={option} option={option} onChange={(event: any) => {
                            const { target: { checked } } = event;
                            mainPackageFlags[option] = checked;
                            if (checked) {
                              const otherOption = option === "Professional Packages" ? "HVAC Control" : "Professional Packages";
                              mainPackageFlags[otherOption] = !checked;
                            }
                            const value = option === "Professional Packages" ? checked : false;

                            Object.keys(commercialPackageFlags).forEach((option: any) => commercialPackageFlags[option].value = value);
                            setCommercialPackageFlags({ ...commercialPackageFlags });
                            setMainPackageFlags({ ...mainPackageFlags });
                          }} />
                        )}
                      </TreeView>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        {(showAllPages || mySupportedPages["devices"]) && <div className={styles.siteButtonsContainer}>
          <Button
            className={styles.siteButtonsStyle}
            onClick={() => {
              history.push(`/devices`)
              setSelections({ customerId: customer, siteId: siteId, systemId: null, unitId: null });
            }}
          >
            {t`Site Devices`}
          </Button>
        </div>}
      </div>
    </ServiceNavigationBar>
  );
};

export default SiteDetails;
